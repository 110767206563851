export const deleteKeys = (obj, selectKeys, inputKeys) => {
    const modifyKey = (o, keys, action) => {
        const [key, ...rest] = keys;
        if (!o || !(key in o)) return; // Check if 'o' is undefined or key doesn't exist

        if (rest.length === 0) {
            if (action === 'delete') delete o[key];
            else if (action === 'update') o[key] = '';
        } else {
            modifyKey(o[key], rest, action);
        }
    };

    const traverseAndModify = (o, keyPath, action) => {
        const keys = keyPath.split(/[\.\[\]]/).filter(k => k);
        modifyKey(o, keys, action);
    };

    selectKeys?.forEach(key => traverseAndModify(obj, key, 'delete'));
    inputKeys?.forEach(key => traverseAndModify(obj, key, 'update'));

    return obj;
};