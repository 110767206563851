import React from "react";
import PortalPopup from '../../PortalPopup'
import { CrossButton } from "../../../mui/button/Button";

const PreviewChangesPopup = ({ onClose, data }) => {
    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.5)" placement="Centered" >
            <div className="flex flex-col justify-between w-full h-full min-w-full min-h-full bg-white p-6 gap-5 rounded-lg">
                <div className="flex justify-between">
                    <p className="h4-p">Preview Changes</p>
                    <CrossButton onClose={onClose} />
                </div>
                <p>No changes yet.</p>
            </div>
        </PortalPopup>
    )
}

export default PreviewChangesPopup;