import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const BlackToolTip = ({ className, title = '', color = 'black', ...props }) => {
    return (
        <Tooltip
            title={title}
            {...props}
            arrow
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        color: "white",
                        backgroundColor: color,
                        paddingX: 1.5,
                        paddingY:1.7,
                        borderRadius:4,
                        fontSize: 12,
                        fontFamily: 'poppins'
                    },
                    "& .MuiTooltip-arrow": {
                        color: color,
                    }
                }
            }}
        />
    );
};

export default BlackToolTip;