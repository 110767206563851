import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Alert, CircularProgress, Slide, Snackbar } from '@mui/material';
import { loadingState } from './recoil/state';

import usePopup from './hooks/usePopup';
import useViewPopup from './hooks/useViewPopup';
import useSnackbar from './hooks/useSnackbar';
import RequireAuth from './routes/RequireAuth';

import PopupRegular from './components/popups/globalPopup/PopupRegular';
import ViewOnlyPopup from './components/popups/globalPopup/ViewOnlyPopup';

import DefaultPage from './routes/DefaultPage';
import AdmissionRoutes from './routes/AdmissionRoutes';
import EmployeeRoutes from './routes/EmployeeRoutes';

import AdmissionLogin from './pages/admissions/login/AdmissionLogin';
import Login from './pages/login/Login';

import PortalPopup from './components/popups/PortalPopup';
import Navbar from './components/navbars/Navbar';
import LandingPage from './routes/LandingPage';
import ForgotPassword from './pages/login/ForgotPassword';
import UpdatePassword from './pages/login/UpdatePassword';


const App = () => {
  const { isSnackbarOpen, snackbarMessage, snackbarType, snackbarIcon, handleSnackbarClose, horizontal, vertical } = useSnackbar();
  const [isLoading] = useRecoilState(loadingState);
  const { open: isPopup, heading: popupHeading, message: popupMessage, onConfirm: onConfirmPopup, onClose: onClosePopup, icon: popupIcon } = usePopup();
  const { open: isViewPopup, heading: viewPopupHeading, message: viewPopupMessage, onClose: onCloseViewPopup, icon: viewPopupIcon } = useViewPopup();

  const TransitionSnackbar = (props) => {
    const directionMap = { top: 'down', bottom: 'up', left: 'right', right: 'left' };
    const direction = directionMap[vertical] || directionMap[horizontal] || 'down';
    return <Slide {...props} direction={direction} />;
  };

  return (
    <div className='flex flex-col w-full'>
      <Navbar />
      {/* <div className={`w-full ${isRouteDisabled(location.pathname) ? '' : 'max-w-8xl mx-auto p-5 md:p-8'}`}> */}
      <Routes>
        <Route path="*" element={<DefaultPage />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admissions/login" element={<AdmissionLogin />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/forgotpassword/updatepassword/:id" element={<UpdatePassword />} />

        <Route path="/admissions/*" element={
          <RequireAuth allowedLoginType={['Student Admission Login', 'Employee Admission Login']} redirect="/admissions/login?type=Student">
            <AdmissionRoutes />
          </RequireAuth>}
        />

        <Route path="/admin/*" element={
          <RequireAuth allowedLoginType={['Admin Login']} redirect="/login">
            <EmployeeRoutes />
          </RequireAuth>}
        />
      </Routes>

      {/* </div> */}

      {isSnackbarOpen && <Snackbar variant='soft' open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: horizontal, vertical: vertical }}
        TransitionComponent={(props) => <TransitionSnackbar {...props} />} >
        <Alert onClose={handleSnackbarClose} severity={snackbarType} icon={snackbarIcon}>
          {snackbarMessage}
        </Alert>
      </Snackbar>}

      {isLoading && <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered">
        <CircularProgress color="secondary" />
      </PortalPopup>}

      {isPopup && <PopupRegular heading={popupHeading} icon={popupIcon} onClose={onClosePopup} onConfirm={() => { onConfirmPopup(); onClosePopup(); }} subText={popupMessage} />}
      {isViewPopup && <ViewOnlyPopup heading={viewPopupHeading} icon={viewPopupIcon} onClose={onCloseViewPopup} message={viewPopupMessage} />}
    </div>
  );
};

export default App;