import { useState } from "react";

const HOST_URL = process.env.REACT_APP_HOST;
// const HOST_URL = 'http://localhost:4000';

const usePutData = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const putData = async (url, body, options = {}) => {
        setIsLoading(true);
        setError(null);

        const token = sessionStorage.getItem('auth_token');

        try {
            const response = await fetch(HOST_URL + url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
                credentials: 'include',
                ...options,
            });
            const result = await response.json();

            setError(result?.error);
            setData(result?.data);
            return { message: result?.message, success: result?.success, data: result?.data, error: result?.error };

        } catch (error) { setError(error?.message); }
        finally { setIsLoading(false); }
    }

    return { data, isLoading, error, putData };
};

export default usePutData;