import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useOutsideClick from '../../hooks/useOutsideClick';

const SideMenu = ({ options, activeTab }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleMenuToggle = useCallback(() => setIsMenuOpen(prevState => !prevState), []);
    const handleCloseMenu = useCallback(() => setIsMenuOpen(false), []);
    useOutsideClick(menuRef, handleCloseMenu);

    return (
        <div ref={menuRef} className='tab:hidden z-50'>
            <Tooltip title={!isMenuOpen && "Open Main Menu"}>
                <IconButton onClick={handleMenuToggle}
                    sx={{ border: 2, borderRadius: 3, backgroundColor: "#3F3F46", ":hover": { backgroundColor: "#3F3F46" } }} >
                    <MenuIcon sx={{ color: "white", fontSize: 28 }} />
                </IconButton>
            </Tooltip>

            {isMenuOpen &&
                <div className='absolute flex flex-col w-full xs:min-w-32 max-w-48 left-4 top-[72px] bg-white rounded-md drop-shadow py-3'>
                    {options?.map((option, index) =>
                        <>
                            <Link key={index} to={option.path} onClick={handleCloseMenu}
                                className={`relative flex flex-col hover:bg-tertiary py-3 px-6 transition-all duration-300 ${activeTab === option.label ? 'h5-s text-purple ' : 'h5-s font-medium'}`}>
                                {option?.label}
                            </Link>
                            {index < options.length - 1 && <span className='px-6'><hr className='bg-slate-400 border-0.5' /></span>}
                        </>
                    )}
                </div>
            }
        </div>
    );
};

export default SideMenu;
