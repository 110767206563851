import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DemoteIcon from '../../../../assets/icons/DemoteIcon'
import TransferIcon from '../../../../assets/icons/TransferIcon'
import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import CustomTable from "../../../../components/mui/Table";
import HomeTile from "../../../../components/tiles/AdmissionTile";
import PromotePopup from "../../../../components/popups/localPopup/promotion/PromotePopup";

const StudentGrid = ({ studentInfo, statsLoading }) => {

    return (
        <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(studentInfo ? Object.keys(studentInfo) : Array.from({ length: 6 })).map((student, index) => {
                return (
                    <HomeTile
                        key={student}
                        icon={StudentIcon}
                        color="#D6D0F7"
                        heading={student}
                        count={studentInfo?.[student]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
    );
};

const EnrolledStudents = () => {
    const { data: enrollData, isLoading: enrollDataLoading, updateData } = useGetData('/api/employee/admission/enrolleds/getEnrolleds')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/enrolleds/stats/getStudentsProgramsCount');

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { putData } = usePutData();

    const [promotePopup, setPromotePopup] = useState({ isOpen: false, _id: '' });

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/enrolleds/profile/" + value + '?type=Personal'));

    const handleDemote = (event, id) => setPromotePopup({ isOpen: true, _id: id });

    const onDemote = async (id, remark) => {
        setPromotePopup({ isOpen: false, _id: '' });
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/applicants/updateAdmissionTransfer/${id}`, { statusMessage: remark });
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Student successfully Demoted!`, 'success');
    }

    const handleCompletion = (event, value) => openPopup('Transfer Student', 'Are you sure you want to make the student Alumni?', 'edit', () => onCompletion(value));

    const onCompletion = async (id) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledTransfer/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Student Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 180, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        {
            id: 'demote', label: 'Transfer to Applicant', type: 'iconButton',
            align: 'center', onClick: handleDemote,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <DemoteIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'completion', label: 'Transfer to Alumni', type: 'iconButton',
            align: 'center', onClick: handleCompletion,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-8 w-8 fill-purple-700" />
                </div>
            )
        },
    ];

    const sortOptions = [
        { label: "Total", value: "none" },
        // { label: "Professors", value: "professors" },
        // { label: "Associate Professor", value: "associate" },
        // { label: "Assistant Professor", value: "assistant" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 rounded-lg drop-shadow-md min-h-svh'>
            <StudentGrid studentInfo={statsData} statsLoading={statsLoading} />
            <CustomTable
                heading="All Enrolled Students"
                text="Enrolled Students data"
                isLoading={enrollDataLoading}
                columns={columns} rows={enrollData}
                sortParameters={['name', 'designation', 'emailId', 'experience']}
                const sortOptions={sortOptions}
                handleRowClick={handleRow}
                activeOperations
            />

            {promotePopup?.isOpen && <PromotePopup id={promotePopup._id} heading='Demote Student' subtext='Are you sure you want to demote the Student?' onClose={() => setPromotePopup({ isOpen: false, _id: '' })} onConfirm={onDemote} />}
        </div>
    )
}

export default EnrolledStudents;