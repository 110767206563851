import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";

import useFormOperations from "../../../../hooks/useFormOperations";
import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";
import useSteppers from "../../../../hooks/useSteppers";
import useGetData from "../../../../hooks/useGetData";

import Stepper from "../../../../components/mui/Steppers";
import UploadDocuments from "./UploadDocuments";
import PersonalDetails from "./PersonalDetails";
import AcademicsDetails from "./AcademicsDetails";

const Form = () => {
    const { id } = useParams();
    const { data: defaultValues, isLoading:isDataLoading } = useGetData(id ? `/api/employee/hr/recruits/getRecruit/${id}` : '/api/admission/employee/getRecruit');
    const { data: statusData, isLoading:statusDataLoading } = useGetData(id ? `/api/employee/hr/recruits/getStatus/${id}` : '/api/admission/employee/getStatus');
    const { data: verifiedValues, isLoading:isVerifiedLoading } = useGetData(id ? `/api/employee/hr/recruits/getVerificationFields/${id}` : "/api/admission/employee/getVerificationFields", null, null, (!!statusData?.submitted && statusData?.onlineVerificationStatus === 'Rejected'));
    const { putData } = usePutData();

    const [searchParams] = useSearchParams();
    const [, setLoading] = useRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();

    const activeStep = searchParams.get('step');
    const steps = ['Personal Details', 'Academics Details', 'Documents Details'];
    const { handleForward } = useSteppers(steps);

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, message } = await putData("/api/admission/employee/updateAdmission", formData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning')
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        handleSnackbarOpen('Data updated Successfully', 'success')
        handleForward();
    }

    const { formData, handleNestedChange, handleNestedSelectImmediateChange, handleChange, handleSubmit, onDiscard, clearForm, updateData } = useFormOperations(defaultValues, onSubmit, "", defaultValues);
    const formOperations = { handleNestedChange, handleNestedSelectImmediateChange, handleChange, handleSubmit, onDiscard, clearForm, updateData };

    return (
        <div className="flex flex-col w-full bg-secondary">
            <div className="flex w-full">
                <Stepper className="w-full overflow-hidden py-8 bg-white border-x-2 border-t-2" steps={steps} />
            </div>

            {activeStep === 'Personal Details' && <PersonalDetails formData={formData} formOperations={formOperations} verifiedValues={verifiedValues} id={id} isLoading={isDataLoading || statusDataLoading || isVerifiedLoading} steps={steps} />}
            {activeStep === 'Academics Details' && <AcademicsDetails formData={formData} formOperations={formOperations} verifiedValues={verifiedValues} id={id} isLoading={isDataLoading || statusDataLoading || isVerifiedLoading} steps={steps} />}
            {activeStep === 'Documents Details' && <UploadDocuments steps={steps} submitted={statusData?.submitted} id={id} formData={formData}/>}
        </div>
    )
}

export default Form;