import { Link, useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";

import useFormOperations from "../../../../hooks/useFormOperations";
import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";
import useSteppers from "../../../../hooks/useSteppers";
import useGetData from "../../../../hooks/useGetData";

import Stepper from "../../../../components/mui/Steppers";
import UploadDocuments from "./UploadDocuments";
import PersonalDetails from "./PersonalDetails";
import AcademicsDetails from "./AcademicsDetails";

const Form = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [, setLoading] = useRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();
    const { data: defaultValues, isLoading: applicantDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getApplicant/${id}` : "/api/admission/student/getApplicant");
    const { data: statusData, isLoading: statusDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getStatus/${id}` : "/api/admission/student/getStatus");
    const { data: verifiedValues, isLoading: isVerifiedLoading } = useGetData(id ? `/api/employee/admission/applicants/getVerificationFields/${id}` : "/api/admission/student/getVerificationFields", null, null, (!!statusData?.submitted && statusData?.onlineVerificationStatus === 'Rejected'));
    const { putData } = usePutData();

    const activeStep = searchParams.get('step');
    const steps = ['Personal Details', 'Academics Details', 'Documents Details'];
    const { handleForward } = useSteppers(steps);

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(id ? `/api/employee/admission/applicants/updateAdmission/${id}` : "/api/admission/student/updateAdmission", formData);
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning')
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error')

        handleSnackbarOpen('Data updated Successfully', 'success')
        handleForward();
    }

    const CustomLink = ({ to, className, children }) => (
        <Link to={to} className={`text-blue-700 opacity-70 hover:underline ${className}`} target="_blank" rel="noopener noreferrer">
            {children}
        </Link>
    );

    const { formData, handleNestedChange, handleNestedSelectImmediateChange, handleChange, handleSubmit, onDiscard, clearForm, updateData } = useFormOperations(defaultValues, onSubmit, "", defaultValues);
    const formOperations = { handleNestedChange, handleNestedSelectImmediateChange, handleChange, handleSubmit, onDiscard, clearForm, updateData };

    const notes = [
        { label: 'Lateral Entry Programs', value: 'Choose this option if you have completed a diploma.' },
        { label: 'Accuracy', value: 'Fill in all details carefully to avoid any kind of rejection. Double-check your information before submission to ensure its correctness.' },
        { label: 'Contact Information', value: 'Provide both primary and alternate phone numbers. Ensure the primary and alternate phone numbers are different.' },
        { label: 'Separate Contact Details', value: 'Ensure the phone numbers and email addresses for parents and students are different.' },
        { label: 'Financial Details', value: 'Ensure your bank account number and IFSC code are accurate. Provide your Aadhar Card and PAN Card numbers correctly.' }
    ];

    return (
        <div className="flex flex-col w-full bg-secondary">
            <div className="flex w-full">
                <Stepper className="w-full overflow-hidden py-8 bg-white border-x-2 border-t-2" steps={steps} />
            </div>

            {activeStep === 'Personal Details' && <PersonalDetails formData={formData} id={id} verifiedValues={verifiedValues} formOperations={formOperations} steps={steps} isLoading={applicantDataLoading || statusDataLoading || isVerifiedLoading} />}
            {activeStep === 'Academics Details' && <AcademicsDetails formData={formData} id={id} verifiedValues={verifiedValues} formOperations={formOperations} steps={steps} isLoading={applicantDataLoading || statusDataLoading || isVerifiedLoading} />}
            {activeStep === 'Documents Details' && <UploadDocuments id={id} submitted={statusData?.submitted} steps={steps} formData={formData} />}

            {(notes && !id) && <div className="flex flex-col gap-2 px-5 lg:px-14 sm:px-8 py-10 w-full border-2 bg-tertiary">
                <p className="h5-p text-signal">Additional Notes</p>
                <ol className="flex flex-col gap-2 text-s">
                    {notes.map((note, index) => (
                        <li key={index}>
                            <span className="text-p text-zinc-500">{index + 1}. {note.label} : </span>
                            <span className="text-s">{note.value}</span>
                        </li>
                    ))}
                    <li>
                        <span className="text-p text-zinc-500">{notes?.length + 1}. Document Format : </span>
                        <span className="text-s">Ensure all the documents are in PDF format only except Passport Photo and Signature. Use online tools like <CustomLink to='https://www.adobe.com/in/acrobat/online/compress-pdf.html'>Adobe</CustomLink>, <CustomLink to='https://www.ilovepdf.com/compress_pdf'>IlovePdf</CustomLink>, <CustomLink to='https://smallpdf.com/compress-pdf'>Smallpdf</CustomLink>,  <CustomLink to='https://pdfcompressor.com/'>Pdf Compressor</CustomLink>, etc. to convert your documents.</span>
                    </li>
                    <li>
                        <span className="text-p text-zinc-500">{notes?.length + 2}. Document Size : </span>
                        <span className="text-s">Each file should be less than 500KB in size. Use online tools like <CustomLink to='https://www.adobe.com/in/acrobat/online/jpg-to-pdf.html'>Adobe</CustomLink>, <CustomLink to='https://www.ilovepdf.com/jpg_to_pdf'>IlovePdf</CustomLink>, <CustomLink to='https://smallpdf.com/jpg-to-pdf'>Smallpdf</CustomLink>,  <CustomLink to='http://freepdfconvert.com/jpg-to-pdf'>Free PdfConvert</CustomLink>, etc. to compress your documents.</span>
                    </li>
                </ol>
            </div>}
        </div>
    )
}

export default Form;