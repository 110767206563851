import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { loadingState } from '../../recoil/state';
import usePopup from '../../hooks/usePopup';
import useGetData from '../../hooks/useGetData';
import useFetchData from '../../hooks/useFetchData';
import useSnackbar from '../../hooks/useSnackbar';

import UploadPopup from '../upload/UploadPopup';
import { GhostButton, GradientButton, PingWrapper } from "../../components/mui/button/Button"
import collegeLogos from '../../CollegeConfig';

const AdmissionNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openPopup } = usePopup();
  const { handleSnackbarOpen } = useSnackbar();
  const isStudent = location.pathname.includes('student');
  const { data: statusData, updateData } = useGetData(isStudent ? '/api/admission/student/getStatus' : '/api/admission/employee/getStatus')
  const { fetchData: fetchUploadKey, error: keyError } = useFetchData()
  const { fetchData: fetchUploadUrl, error: urlError } = useFetchData()
  const isSmallScreen = useMediaQuery('(max-width: 500px)');

  const [, setGlobalLoading] = useRecoilState(loadingState);

  const [popup, setPopup] = useState(false);
  const [signedURL, setSignedURL] = useState('');
  const [file, setFile] = useState();
  const [key, setKey] = useState();

  const setterFile = (file) => setFile(file);
  const closePopup = () => setPopup(false);
  const onLogout = () => navigate('/admissions/login?type=Student');
  const onEditForm = () => navigate(location.pathname.split('/').slice(0, -1).concat('form?step=Personal+Details').join('/'));
  const onOpenProfile = () => navigate(location.pathname.split('/').slice(0, -1).concat('profile?type=Personal').join('/'));

  const submitted = statusData?.submitted;
  const isProfilePage = location.pathname.includes('profile');
  const isFormPage = location.pathname.includes('form');
  const editable = statusData?.onlineVerificationStatus === 'Rejected' ? true : false;
  const onlineVerified = statusData?.onlineVerificationStatus === 'Verified';
  const offlineVerified = statusData?.offlineVerificationStatus === 'Verified';

  const isEditFormAvailable = isProfilePage && editable;
  const isRetentionAvailable = statusData?.collegeRetention && submitted && isProfilePage && isStudent && (onlineVerified || offlineVerified) && !statusData?.studentRetention;
  const isProfileAvailable = submitted && isFormPage;

  const handleRetention = async () => {
    const data = await fetchUploadKey('/api/admission/student/getRetentionDocumentUploadURL')
    if (keyError) return handleSnackbarOpen('Opps... Some error Occurred!', 'error');
    setKey(data);
    const signedURL = await fetchUploadUrl(`/api/admission/student/getUploadURL?key=${data}`);
    if (urlError) return handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
    setSignedURL(signedURL);
    setPopup(true)
  }

  const onConfirmRetention = async () => {
    setGlobalLoading(true);
    const uploadResponse = await fetch(signedURL, { method: 'PUT', body: file });
    setGlobalLoading(false);

    if (!uploadResponse.ok)
      return handleSnackbarOpen(`Failed to upload file ${file?.name}`, "error")
    updateData(prevState => {
      return { ...prevState, studentRetention: true };
    });
    handleSnackbarOpen("Your Retention has been successfully Approved. Thankyou for choosing our college.", "success");
    closePopup();
  }

  const handleLogout = () => {
    openPopup('Confirm Logout', 'It looks like you’re ready to sign out. Proceed?', 'logout', onLogout)
  }
  const fileExtension = key?.endsWith('.pdf') ? 'pdf' : 'image';
  const allowedTypes = { image: ['png', 'jpeg', 'jpg'], pdf: ['pdf'] };

  return (
    <div className='flex items-center justify-between px-3 sm:px-8 sticky top-0 z-10 nav-bg h-20'>
      <Link to='/admissions/profile?type=Personal' className='flex w-fit h-fit'>
        <img className='h-10 lg:h-12 min-w-fit' src={isSmallScreen ? collegeLogos?.shortDark : collegeLogos?.longDark} alt="" />
      </Link>

      <div className='flex gap-5'>
        {isEditFormAvailable && <PingWrapper><GhostButton capitalize={false} text='Edit Form' onClick={onEditForm} /></PingWrapper>}
        {isRetentionAvailable && <PingWrapper><GhostButton text='Retain' capitalize={false} onClick={handleRetention} /></PingWrapper>}
        {isProfileAvailable && <GhostButton text='View Profile' capitalize={false} onClick={onOpenProfile} />}
        <GradientButton className='px-3 xs:px-6' text='Logout' onClick={handleLogout} />
      </div>

      {popup &&
        <UploadPopup file={file} setterFile={setterFile} allowedTypes={allowedTypes[fileExtension]}
          onClose={closePopup} onSubmit={onConfirmRetention}
        />
      }
    </div>
  );
};

export default AdmissionNavbar;