import React from "react";
import { useLocation } from "react-router-dom";

import useGetData from '../../hooks/useGetData';

import TopNavbar from "./TopNavbar";
import AdmissionNavbar from "./AdmissionNavbar";
import MiniNavbar from "./MiniNavbar";

const Navbar = () => {
    const location = useLocation();
    const admissionsPaths = ["/admissions/student", "/admissions/employee"];
    const isAdmissionPortal = admissionsPaths.some(path => location.pathname.startsWith(path));

    const erpPaths = ["/admin"];
    const isERP = erpPaths.some(path => location.pathname.startsWith(path));

    const { data: employeeData, isLoading } = useGetData('/api/employee/personal/getCurrentDetails', null, null, !isAdmissionPortal && isERP)

    return (
        <div className="flex flex-col sticky top-0 z-10 w-full">
            {isAdmissionPortal && <AdmissionNavbar />}

            {isERP && <>
                <TopNavbar employeeData={employeeData} isLoading={isLoading} />
                <div className={`tab:hidden flex items-center justify-center w-full drop-shadow h-12 bg-white`}>
                    <MiniNavbar />
                </div>
            </>}
        </div>
    );
};

export default Navbar;