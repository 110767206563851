import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loggedInState } from '../recoil/state';
import useFetchData from '../hooks/useFetchData';

const VALID_LOGIN_TYPES = ['Admin Login', 'Student Admission Login', 'Employee Admission Login'];

const RequireAuth = ({ allowedLoginType = [], children, redirect = '/login' }) => {
  const [loginType, setLoginType] = useRecoilState(loggedInState);
  const { fetchData } = useFetchData();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (!loginType) {
        try {
          const data = await fetchData('/api/cookies/refreshToken');
          if (VALID_LOGIN_TYPES.includes(data?.loginType)) {
            setLoginType(data?.loginType);
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginType]);

  if (isLoading) return <div>Loading...</div>;

  const isAuthenticated = loginType && VALID_LOGIN_TYPES.includes(loginType);
  const isAllowed = allowedLoginType.includes(loginType);

  if (!isAuthenticated || !isAllowed) return <Navigate to={redirect} />;

  return children;
};

export default RequireAuth;
