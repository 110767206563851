import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IconButton, useMediaQuery } from '@mui/material';
import { Settings, Notifications } from '@mui/icons-material';
import ProfileMenu from './ProfileMenu';
import SideMenu from "./SideMenu";
import BlackToolTip from "../mui/BlackToolTip";
import SkeletonText from '../mui/text/SkeltonText';
import collegeLogos from '../../CollegeConfig';

const TopNavbar = ({ employeeData, isLoading }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isSmallScreen = useMediaQuery('(max-width:900px)');

    const [popupOpenIndex, setPopupOpenIndex] = useState(null);

    const handleOpenPopup = (index) => setPopupOpenIndex(index);
    const handleClosePopup = () => setPopupOpenIndex(null);

    const studentNavLinks = [
        { path: '/admin/student/applicants', label: "Applicants" },
        { path: '/admin/student/enrolleds', label: "Enrolled" },
        { path: '/admin/student/alumnis', label: "Alumni" },
    ]

    const employeeNavLinks = [
        { path: '/admin/employee/recruits', label: "Recruits" },
        { path: '/admin/employee/currents', label: "Current" },
        { path: '/admin/employee/formers', label: "Former" }
    ]

    const navLinks = [
        { path: '/admin/profile?type=Personal', label: "Profile" },
        { path: '/admin/student/applicants', label: "Student", links: studentNavLinks },
        { path: '/admin/employee/recruits', label: "Employee", links: employeeNavLinks }
    ]

    const path = location.pathname;
    const activeTab = path.startsWith('/admin/profile') ? 'Profile' : path.startsWith('/admin/student') ? 'Student' : path.startsWith('/admin/employee') ? 'Employee' : '';
    return (
        <div className="flex items-center justify-between gap-5 px-3 sm:px-5 md:px-8 w-full h-20 nav-bg">
            <div className="flex items-center gap-3">
                <SideMenu options={navLinks} activeTab={activeTab} />
                <img className="h-12 w-12" src={collegeLogos?.shortDark} alt="" />
            </div>

            {!isSmallScreen &&
                <div className='absolute left-0 right-0 mx-auto w-fit flex items-center overflow-visible'>
                    {navLinks?.map((tab, index) =>
                        <div className="relative flex w-full overflow-visible"
                            onMouseEnter={() => handleOpenPopup(index)}
                            onMouseLeave={handleClosePopup}
                            onClick={() => handleOpenPopup(index)}
                        >
                            <Link key={index} to={tab.path && tab.path} className={`relative py-2 transition-all duration-200 mx-5 font-work-sans tracking-wide text-base ${activeTab === tab.label ? 'text-white font-semibold tracking-widest' : 'text-light'}`}>
                                {tab.label}
                            </Link>
                            {tab?.links && popupOpenIndex === index &&
                                <div className='absolute top-10 flex flex-col bg-white rounded-md drop-shadow w-full xs:min-w-48 py-3 z-50'>
                                    {tab?.links?.map((option, index) => (
                                        <>
                                            {index !== 0 && <span className='px-6'><hr className='bg-slate-400 border-0.5' /></span>}
                                            <SkeletonText key={index} width={140} loading={isLoading}>
                                                <Link to={option?.path} className='flex flex-shrink-0 h5-s w-full font-normal px-6 py-3 hover:bg-tertiary'>{option?.label}</Link>
                                            </SkeletonText>
                                        </>
                                    ))}
                                </div>
                            }
                        </div>
                    )}
                </div>}

            <div className="flex space-x-3 items-center">
                <BlackToolTip arrow title="Notifications">
                    <IconButton sx={{ ":hover": { backgroundColor: "#3F3F46" } }}
                        onClick={() => navigate('/admin/notifications')}>
                        <Notifications sx={{ color: "white", fontSize: 28 }} />
                    </IconButton>
                </BlackToolTip>

                <BlackToolTip arrow title="Settings">
                    <IconButton sx={{ ":hover": { backgroundColor: "#3F3F46" } }}
                        onClick={() => navigate('/admin/settings')}>
                        <Settings sx={{ color: "white", fontSize: 28 }} />
                    </IconButton>
                </BlackToolTip>

                <ProfileMenu employeeData={employeeData} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default TopNavbar;