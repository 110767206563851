import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { ArrowBack, DeleteOutline, EditOutlined } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton'

import { loadingState } from "../../../../../recoil/state";
import usePutData from "../../../../../hooks/usePutData";
import usePopup from "../../../../../hooks/usePopup";
import useSnackbar from "../../../../../hooks/useSnackbar";

import TextBox from "../../../../../components/mui/text/TextBox";
import { CustomCheckBox, CustomTextField } from "../../../../../components/mui/Input";
import { CloseButton, ConfirmButton, PurpleButton } from "../../../../../components/mui/button/Button";
import { dateFormat } from "../../../../../utils/dateFormat";
import BlackToolTip from "../../../../../components/mui/BlackToolTip";

const VerifyData = ({ id, documentOpen, formData, isLoading, formOperations, onlineStatus }) => {
    const { masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit } = formOperations;
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const handleBackward = () => navigate('/admin/employee/recruits');
    const [remark, setRemark] = useState('');

    const generalInfoFields = [
        { label: "College Name", value: formData?.collegeName?.status, data: formData?.collegeName?.data, name: 'formData.collegeName.status' },
        { label: "Email ID", value: formData?.emailId?.status, data: formData?.emailId?.data, name: 'formData.emailId.status' },
        { label: "Designation", value: formData?.designation?.status, data: formData?.designation?.data, name: 'formData.designation.status' },
    ]

    const personalInfoFields = [
        { label: "First Name", value: formData?.firstName?.status, data: formData?.firstName?.data, name: 'formData.firstName.status' },
        { label: "Middle Name", value: formData?.middleName?.status, data: formData?.middleName?.data, name: 'formData.middleName.status' },
        { label: "Last Name", value: formData?.lastName?.status, data: formData?.lastName?.data, name: 'formData.lastName.status' },
        { label: "Gender", value: formData?.personalInfo?.gender?.status, data: formData?.personalInfo?.gender?.data, name: 'formData.personalInfo.gender.status' },
        { label: "Date of Birth", value: formData?.personalInfo?.dob?.status, data: dateFormat(formData?.personalInfo?.dob?.data), name: 'formData.personalInfo.dob.status' },
        { label: "Blood Group", value: formData?.personalInfo?.bloodGroup?.status, data: formData?.personalInfo?.bloodGroup?.data, name: 'formData.personalInfo.bloodGroup.status' },
        { label: "Phone Number", value: formData?.phNo?.status, data: formData?.phNo?.data, name: 'formData.phNo.status' },
    ];

    const residentialAddressInfoFields = [
        { label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address?.status, data: formData?.personalInfo?.residentialAddress?.address?.data, name: 'formData.personalInfo.residentialAddress.address.status' },
        { label: "Town", value: formData?.personalInfo?.residentialAddress?.town?.status, data: formData?.personalInfo?.residentialAddress?.town?.data, name: 'formData.personalInfo.residentialAddress.town.status' },
        { label: "City", value: formData?.personalInfo?.residentialAddress?.city?.status, data: formData?.personalInfo?.residentialAddress?.city?.data, name: 'formData.personalInfo.residentialAddress.city.status' },
        { label: "State", value: formData?.personalInfo?.residentialAddress?.state?.status, data: formData?.personalInfo?.residentialAddress?.state?.data, name: 'formData.personalInfo.residentialAddress.state.status' },
        { label: "Country", value: formData?.personalInfo?.residentialAddress?.country?.status, data: formData?.personalInfo?.residentialAddress?.country?.data, name: 'formData.personalInfo.residentialAddress.country.status' },
        { label: "Pin Code", value: formData?.personalInfo?.residentialAddress?.pincode?.status, data: formData?.personalInfo?.residentialAddress?.pincode?.data, name: 'formData.personalInfo.residentialAddress.pincode.status' },
    ];

    const permanentAddressInfoFields = [
        { label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address?.status, data: formData?.personalInfo?.permanentAddress?.address?.data, name: 'formData.personalInfo.permanentAddress.address.status' },
        { label: "Town", value: formData?.personalInfo?.permanentAddress?.town?.status, data: formData?.personalInfo?.permanentAddress?.town?.data, name: 'formData.personalInfo.permanentAddress.town.status' },
        { label: "City", value: formData?.personalInfo?.permanentAddress?.city?.status, data: formData?.personalInfo?.permanentAddress?.city?.data, name: 'formData.personalInfo.permanentAddress.city.status' },
        { label: "State", value: formData?.personalInfo?.permanentAddress?.state?.status, data: formData?.personalInfo?.permanentAddress?.state?.data, name: 'formData.personalInfo.permanentAddress.state.status' },
        { label: "Country", value: formData?.personalInfo?.permanentAddress?.country?.status, data: formData?.personalInfo?.permanentAddress?.country?.data, name: 'formData.personalInfo.permanentAddress.country.status' },
        { label: "Pin Code", value: formData?.personalInfo?.permanentAddress?.pincode?.status, data: formData?.personalInfo?.permanentAddress?.pincode?.data, name: 'formData.personalInfo.permanentAddress.pincode.status' }
    ];

    const identityInfoFields = [
        { label: "Nationality", value: formData?.identityInfo?.nationality?.status, data: formData?.identityInfo?.nationality?.data, name: 'formData.identityInfo.nationality.status' },
        { label: "Category", value: formData?.identityInfo?.category?.status, data: formData?.identityInfo?.category?.data, name: 'formData.identityInfo.category.status' },
        { label: "Religion", value: formData?.identityInfo?.religion?.status, data: formData?.identityInfo?.religion?.data, name: 'formData.identityInfo.religion.status' },
        { label: "Caste", value: formData?.identityInfo?.caste?.status, data: formData?.identityInfo?.caste?.data, name: 'formData.identityInfo.caste.status' },
        { label: "Sub Caste", value: formData?.identityInfo?.subCaste?.status, data: formData?.identityInfo?.subCaste?.data, name: 'formData.identityInfo.subCaste.status' },
    ];

    const financialAndLegalInfoFields = [
        { label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName?.status, data: formData?.financialAndLegalInfo?.bankName?.data, name: 'formData.financialAndLegalInfo.bankName.status' },
        { label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo?.status, data: formData?.financialAndLegalInfo?.accountNo?.data, name: 'formData.financialAndLegalInfo.accountNo.status' },
        { label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc?.status, data: formData?.financialAndLegalInfo?.ifsc?.data, name: 'formData.financialAndLegalInfo.ifsc.status' },
        { label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo?.status, data: formData?.financialAndLegalInfo?.aadharCardNo?.data, name: 'formData.financialAndLegalInfo.aadharCardNo.status' },
        { label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo?.status, data: formData?.financialAndLegalInfo?.panCardNo?.data, name: 'formData.financialAndLegalInfo.panCardNo.status' },
    ];

    const additionalInfoFields = [
        { label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue?.status, data: formData?.additionalInfo?.motherTongue?.data, name: 'formData.additionalInfo.motherTongue.status' },
        { label: "Disability", value: formData?.additionalInfo?.disability?.status, data: formData?.additionalInfo?.disability?.data, name: 'formData.additionalInfo.disability.status' },
        { label: "Marital Status", value: formData?.additionalInfo?.maritalStatus?.status, data: formData?.additionalInfo?.maritalStatus?.data, name: 'formData.additionalInfo.maritalStatus.status' },
    ];

    const fatherInfoFields = [
        { label: "Father Alive", value: formData?.familyInfo?.fatherDetail?.isDeceased?.status, data: formData?.familyInfo?.fatherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.fatherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.fatherDetail?.firstName?.status, data: formData?.familyInfo?.fatherDetail?.firstName?.data, name: 'formData.familyInfo.fatherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.fatherDetail?.middleName?.status, data: formData?.familyInfo?.fatherDetail?.middleName?.data, name: 'formData.familyInfo.fatherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.fatherDetail?.lastName?.status, data: formData?.familyInfo?.fatherDetail?.lastName?.data, name: 'formData.familyInfo.fatherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.fatherDetail?.phNo?.status, data: formData?.familyInfo?.fatherDetail?.phNo?.data, name: 'formData.familyInfo.fatherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.fatherDetail?.emailId?.status, data: formData?.familyInfo?.fatherDetail?.emailId?.data, name: 'formData.familyInfo.fatherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.fatherDetail?.income?.status, data: formData?.familyInfo?.fatherDetail?.income?.data, name: 'formData.familyInfo.fatherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.fatherDetail?.education?.status, data: formData?.familyInfo?.fatherDetail?.education?.data, name: 'formData.familyInfo.fatherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.fatherDetail?.profession?.status, data: formData?.familyInfo?.fatherDetail?.profession?.data, name: 'formData.familyInfo.fatherDetail.profession.status' },
    ];

    const motherInfoFields = [
        { label: "Mother Alive", value: formData?.familyInfo?.motherDetail?.isDeceased?.status, data: formData?.familyInfo?.motherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.motherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.motherDetail?.firstName?.status, data: formData?.familyInfo?.motherDetail?.firstName?.data, name: 'formData.familyInfo.motherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.motherDetail?.middleName?.status, data: formData?.familyInfo?.motherDetail?.middleName?.data, name: 'formData.familyInfo.motherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.motherDetail?.lastName?.status, data: formData?.familyInfo?.motherDetail?.lastName?.data, name: 'formData.familyInfo.motherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.motherDetail?.phNo?.status, data: formData?.familyInfo?.motherDetail?.phNo?.data, name: 'formData.familyInfo.motherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.motherDetail?.emailId?.status, data: formData?.familyInfo?.motherDetail?.emailId?.data, name: 'formData.familyInfo.motherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.motherDetail?.income?.status, data: formData?.familyInfo?.motherDetail?.income?.data, name: 'formData.familyInfo.motherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.motherDetail?.education?.status, data: formData?.familyInfo?.motherDetail?.education?.data, name: 'formData.familyInfo.motherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.motherDetail?.profession?.status, data: formData?.familyInfo?.motherDetail?.profession?.data, name: 'formData.familyInfo.motherDetail.profession.status' },
    ];

    const partnerInfoFields = [
        { label: "First Name", value: formData?.familyInfo?.partnerDetail?.firstName?.status, data: formData?.familyInfo?.partnerDetail?.firstName?.data, name: 'formData.familyInfo.partnerDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.partnerDetail?.middleName?.status, data: formData?.familyInfo?.partnerDetail?.middleName?.data, name: 'formData.familyInfo.partnerDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.partnerDetail?.lastName?.status, data: formData?.familyInfo?.partnerDetail?.lastName?.data, name: 'formData.familyInfo.partnerDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.partnerDetail?.phNo?.status, data: formData?.familyInfo?.partnerDetail?.phNo?.data, name: 'formData.familyInfo.partnerDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.partnerDetail?.emailId?.status, data: formData?.familyInfo?.partnerDetail?.emailId?.data, name: 'formData.familyInfo.partnerDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.partnerDetail?.income?.status, data: formData?.familyInfo?.partnerDetail?.income?.data, name: 'formData.familyInfo.partnerDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.partnerDetail?.education?.status, data: formData?.familyInfo?.partnerDetail?.education?.data, name: 'formData.familyInfo.partnerDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.partnerDetail?.profession?.status, data: formData?.familyInfo?.partnerDetail?.profession?.data, name: 'formData.familyInfo.partnerDetail.profession.status' },
    ];

    const childrenInfoFields = formData?.familyInfo?.children?.map((child, index) => [
        { label: "Name", value: formData?.familyInfo?.children?.[index]?.name?.status, data: child?.name?.data, name: `formData.familyInfo.children.${index}.name.status` },
        { label: "Education", value: formData?.familyInfo?.children?.[index]?.education?.status, data: child?.education?.data, name: `formData.familyInfo.children.${index}.education.status` },
        { label: "Date of Birth", value: formData?.familyInfo?.children?.[index]?.dob?.status, data: dateFormat(child?.dob?.data), name: `formData.familyInfo.children.${index}.dob.status` },
    ]);

    const elementaryDetailFields = [
        { label: "Board Name", value: formData?.education?.elementaryDetail?.boardName?.status, data: formData?.education?.elementaryDetail?.boardName?.data, name: 'formData.education.elementaryDetail.boardName.status' },
        { label: "Roll No", value: formData?.education?.elementaryDetail?.rollNo?.status, data: formData?.education?.elementaryDetail?.rollNo?.data, name: 'formData.education.elementaryDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.education?.elementaryDetail?.examYear?.status, data: formData?.education?.elementaryDetail?.examYear?.data, name: 'formData.education.elementaryDetail.examYear.status' },
        { label: "School Name", value: formData?.education?.elementaryDetail?.schoolName?.status, data: formData?.education?.elementaryDetail?.schoolName?.data, name: 'formData.education.elementaryDetail.schoolName.status' },
        { label: "School City", value: formData?.education?.elementaryDetail?.city?.status, data: formData?.education?.elementaryDetail?.city?.data, name: 'formData.education.elementaryDetail.city.status' },
        { label: "School State", value: formData?.education?.elementaryDetail?.state?.status, data: formData?.education?.elementaryDetail?.state?.data, name: 'formData.education.elementaryDetail.state.status' },
        { label: "School Country", value: formData?.education?.elementaryDetail?.country?.status, data: formData?.education?.elementaryDetail?.country?.data, name: 'formData.education.elementaryDetail.country.status' },
        { label: "Obtained Marks", value: formData?.education?.elementaryDetail?.obtainedMarks?.status, data: formData?.education?.elementaryDetail?.obtainedMarks?.data, name: 'formData.education.elementaryDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.education?.elementaryDetail?.totalMarks?.status, data: formData?.education?.elementaryDetail?.totalMarks?.data, name: 'formData.education.elementaryDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.education?.elementaryDetail?.percentage?.status, data: formData?.education?.elementaryDetail?.percentage?.data, name: 'formData.education.elementaryDetail.percentage.status' },
        { label: "Grade", value: formData?.education?.elementaryDetail?.grade?.status, data: formData?.education?.elementaryDetail?.grade?.data, name: 'formData.education.elementaryDetail.grade.status' },
        { label: "CGPA", value: formData?.education?.elementaryDetail?.cgpa?.status, data: formData?.education?.elementaryDetail?.cgpa?.data, name: 'formData.education.elementaryDetail.cgpa.status' },
    ];

    const intermediateDetailFields = [
        { label: "Board Name", value: formData?.education?.intermediateDetail?.boardName?.status, data: formData?.education?.intermediateDetail?.boardName?.data, name: 'formData.education.intermediateDetail.boardName.status' },
        { label: "Roll No", value: formData?.education?.intermediateDetail?.rollNo?.status, data: formData?.education?.intermediateDetail?.rollNo?.data, name: 'formData.education.intermediateDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.education?.intermediateDetail?.examYear?.status, data: formData?.education?.intermediateDetail?.examYear?.data, name: 'formData.education.intermediateDetail.examYear.status' },
        { label: "School Name", value: formData?.education?.intermediateDetail?.schoolName?.status, data: formData?.education?.intermediateDetail?.schoolName?.data, name: 'formData.education.intermediateDetail.schoolName.status' },
        { label: "School City", value: formData?.education?.intermediateDetail?.city?.status, data: formData?.education?.intermediateDetail?.city?.data, name: 'formData.education.intermediateDetail.city.status' },
        { label: "School State", value: formData?.education?.intermediateDetail?.state?.status, data: formData?.education?.intermediateDetail?.state?.data, name: 'formData.education.intermediateDetail.state.status' },
        { label: "School Country", value: formData?.education?.intermediateDetail?.country?.status, data: formData?.education?.intermediateDetail?.country?.data, name: 'formData.education.intermediateDetail.country.status' },
        { label: "Obtained Marks", value: formData?.education?.intermediateDetail?.obtainedMarks?.status, data: formData?.education?.intermediateDetail?.obtainedMarks?.data, name: 'formData.education.intermediateDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.education?.intermediateDetail?.totalMarks?.status, data: formData?.education?.intermediateDetail?.totalMarks?.data, name: 'formData.education.intermediateDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.education?.intermediateDetail?.percentage?.status, data: formData?.education?.intermediateDetail?.percentage?.data, name: 'formData.education.intermediateDetail.percentage.status' },
        { label: "Grade", value: formData?.education?.intermediateDetail?.grade?.status, data: formData?.education?.intermediateDetail?.grade?.data, name: 'formData.education.intermediateDetail.grade.status' },
        { label: "CGPA", value: formData?.education?.intermediateDetail?.cgpa?.status, data: formData?.education?.intermediateDetail?.cgpa?.data, name: 'formData.education.intermediateDetail.cgpa.status' },
    ];

    const diplomaDetailFields = formData?.education?.diplomaDetails?.map((diploma, index) => [
        { label: "Degree", value: formData?.education?.diplomaDetails?.[index]?.degree?.status, data: diploma?.degree?.data, name: `formData.education.diplomaDetails.${index}.degree.status` },
        { label: "Major", value: formData?.education?.diplomaDetails?.[index]?.major?.status, data: diploma?.major?.data, name: `formData.education.diplomaDetails.${index}.major.status` },
        { label: "Completion Year", value: formData?.education?.diplomaDetails?.[index]?.passingYear?.status, data: diploma?.passingYear?.data, name: `formData.education.diplomaDetails.${index}.passingYear.status` },
        { label: "Degree Type", value: formData?.education?.diplomaDetails?.[index]?.degreeType?.status, data: diploma?.degreeType?.data, name: `formData.education.diplomaDetails.${index}.degreeType.status` },
        { label: "College Name", value: formData?.education?.diplomaDetails?.[index]?.collegeName?.status, data: diploma?.collegeName?.data, name: `formData.education.diplomaDetails.${index}.collegeName.status` },
        { label: "College City", value: formData?.education?.diplomaDetails?.[index]?.city?.status, data: diploma?.city?.data, name: `formData.education.diplomaDetails.${index}.city.status` },
        { label: "College State", value: formData?.education?.diplomaDetails?.[index]?.state?.status, data: diploma?.state?.data, name: `formData.education.diplomaDetails.${index}.state.status` },
        { label: "College Country", value: formData?.education?.diplomaDetails?.[index]?.country?.status, data: diploma?.country?.data, name: `formData.education.diplomaDetails.${index}.country.status` },
        { label: "Percentage", value: formData?.education?.diplomaDetails?.[index]?.percentage?.status, data: diploma?.percentage?.data, name: `formData.education.diplomaDetails.${index}.percentage.status` },
        { label: "Grade", value: formData?.education?.diplomaDetails?.[index]?.grade?.status, data: diploma?.grade?.data, name: `formData.education.diplomaDetails.${index}.grade.status` },
        { label: "CGPA", value: formData?.education?.diplomaDetails?.[index]?.cgpa?.status, data: diploma?.cgpa?.data, name: `formData.education.diplomaDetails.${index}.cgpa.status` },
    ]);

    const ugDetailFields = formData?.education?.ugDetails?.map((ug, index) => [
        { label: 'Degree', value: formData?.education?.ugDetails?.[index]?.degree?.status, data: ug?.degree?.data, name: `formData.education.ugDetails.${index}.degree.status` },
        { label: 'Major', value: formData?.education?.ugDetails?.[index]?.major?.status, data: ug?.major?.data, name: `formData.education.ugDetails.${index}.major.status` },
        { label: 'Completion Year', value: formData?.education?.ugDetails?.[index]?.passingYear?.status, data: ug?.passingYear?.data, name: `formData.education.ugDetails.${index}.passingYear.status` },
        { label: 'Degree Type', value: formData?.education?.ugDetails?.[index]?.degreeType?.status, data: ug?.degreeType?.data, name: `formData.education.ugDetails.${index}.degreeType.status` },
        { label: 'College Name', value: formData?.education?.ugDetails?.[index]?.collegeName?.status, data: ug?.collegeName?.data, name: `formData.education.ugDetails.${index}.collegeName.status` },
        { label: 'College City', value: formData?.education?.ugDetails?.[index]?.city?.status, data: ug?.city?.data, name: `formData.education.ugDetails.${index}.city.status` },
        { label: 'College State', value: formData?.education?.ugDetails?.[index]?.state?.status, data: ug?.state?.data, name: `formData.education.ugDetails.${index}.state.status` },
        { label: 'College Country', value: formData?.education?.ugDetails?.[index]?.country?.status, data: ug?.country?.data, name: `formData.education.ugDetails.${index}.country.status` },
        { label: 'Percentage', value: formData?.education?.ugDetails?.[index]?.percentage?.status, data: ug?.percentage?.data, name: `formData.education.ugDetails.${index}.percentage.status` },
        { label: 'Grade', value: formData?.education?.ugDetails?.[index]?.grade?.status, data: ug?.grade?.data, name: `formData.education.ugDetails.${index}.grade.status` },
        { label: 'CGPA', value: formData?.education?.ugDetails?.[index]?.cgpa?.status, data: ug?.cgpa?.data, name: `formData.education.ugDetails.${index}.cgpa.status` }
    ]);

    const pgDetailFields = formData?.education?.pgDetails?.map((pg, index) => [
        { label: 'Degree', value: formData?.education?.pgDetails?.[index]?.degree?.status, data: pg?.degree?.data, name: `formData.education.pgDetails.${index}.degree.status` },
        { label: 'Major', value: formData?.education?.pgDetails?.[index]?.major?.status, data: pg?.major?.data, name: `formData.education.pgDetails.${index}.major.status` },
        { label: 'Completion Year', value: formData?.education?.pgDetails?.[index]?.passingYear?.status, data: pg?.passingYear?.data, name: `formData.education.pgDetails.${index}.passingYear.status` },
        { label: 'Degree Type', value: formData?.education?.pgDetails?.[index]?.degreeType?.status, data: pg?.degreeType?.data, name: `formData.education.pgDetails.${index}.degreeType.status` },
        { label: 'College Name', value: formData?.education?.pgDetails?.[index]?.collegeName?.status, data: pg?.collegeName?.data, name: `formData.education.pgDetails.${index}.collegeName.status` },
        { label: 'College City', value: formData?.education?.pgDetails?.[index]?.city?.status, data: pg?.city?.data, name: `formData.education.pgDetails.${index}.city.status` },
        { label: 'College State', value: formData?.education?.pgDetails?.[index]?.state?.status, data: pg?.state?.data, name: `formData.education.pgDetails.${index}.state.status` },
        { label: 'College Country', value: formData?.education?.pgDetails?.[index]?.country?.status, data: pg?.country?.data, name: `formData.education.pgDetails.${index}.country.status` },
        { label: 'Percentage', value: formData?.education?.pgDetails?.[index]?.percentage?.status, data: pg?.percentage?.data, name: `formData.education.pgDetails.${index}.percentage.status` },
        { label: 'Grade', value: formData?.education?.pgDetails?.[index]?.grade?.status, data: pg?.grade?.data, name: `formData.education.pgDetails.${index}.grade.status` },
        { label: 'CGPA', value: formData?.education?.pgDetails?.[index]?.cgpa?.status, data: pg?.cgpa?.data, name: `formData.education.pgDetails.${index}.cgpa.status` }
    ]);

    const doctorateDetailFields = formData?.education?.doctorateDetails?.map((phd, index) => [
        { label: 'Degree', value: formData?.education?.doctorateDetails?.[index]?.degree?.status, data: phd?.degree?.data, name: `formData.education.doctorateDetails.${index}.degree.status` },
        { label: 'Dissertation Title', value: formData?.education?.doctorateDetails?.[index]?.dissertationTitle?.status, data: phd?.dissertationTitle?.data, name: `formData.education.doctorateDetails.${index}.dissertationTitle.status` },
        { label: 'Supervisor', value: formData?.education?.doctorateDetails?.[index]?.supervisor?.status, data: phd?.supervisor?.data, name: `formData.education.doctorateDetails.${index}.supervisor.status` },
        { label: 'Completion Year', value: formData?.education?.doctorateDetails?.[index]?.completionYear?.status, data: phd?.completionYear?.data, name: `formData.education.doctorateDetails.${index}.completionYear.status` },
        { label: 'University Name', value: formData?.education?.doctorateDetails?.[index]?.universityName?.status, data: phd?.universityName?.data, name: `formData.education.doctorateDetails.${index}.universityName.status` },
        { label: 'University City', value: formData?.education?.doctorateDetails?.[index]?.city?.status, data: phd?.city?.data, name: `formData.education.doctorateDetails.${index}.city.status` },
        { label: 'University State', value: formData?.education?.doctorateDetails?.[index]?.state?.status, data: phd?.state?.data, name: `formData.education.doctorateDetails.${index}.state.status` },
        { label: 'University Country', value: formData?.education?.doctorateDetails?.[index]?.country?.status, data: phd?.country?.data, name: `formData.education.doctorateDetails.${index}.country.status` },
        { label: 'CGPA', value: formData?.education?.doctorateDetails?.[index]?.cgpa?.status, data: phd?.cgpa?.data, name: `formData.education.doctorateDetails.${index}.cgpa.status` }
    ]);

    const certificationDetailFields = formData?.education?.certificationDetails?.map((certificate, index) => [
        { label: 'Certification', name: `formData.education.certificationDetails.${index}.certification.status`, value: formData?.education?.certificationDetails?.[index]?.certification?.status, data: certificate?.certification?.data },
        { label: 'Issuing Organization', name: `formData.education.certificationDetails.${index}.issuingOrganization.status`, value: formData?.education?.certificationDetails?.[index]?.issuingOrganization?.status, data: certificate?.issuingOrganization?.data },
        { label: 'Issue Date', name: `formData.education.certificationDetails.${index}.issueDate.status`, value: formData?.education?.certificationDetails?.[index]?.issueDate?.status, data: dateFormat(certificate?.issueDate?.data) },
        { label: 'Expiry Date', name: `formData.education.certificationDetails.${index}.expiryDate.status`, value: formData?.education?.certificationDetails?.[index]?.expiryDate?.status, data: dateFormat(certificate?.expiryDate?.data) },
        { label: 'Certificate ID', name: `formData.education.certificationDetails.${index}.certificateID.status`, value: formData?.education?.certificationDetails?.[index]?.certificateID?.status, data: certificate?.certificateID?.data },
        { label: 'Score', name: `formData.education.certificationDetails.${index}.score.status`, value: formData?.education?.certificationDetails?.[index]?.score?.status, data: certificate?.score?.data },
        { label: 'Certification URL', name: `formData.education.certificationDetails.${index}.certificateUrl.status`, value: formData?.education?.certificationDetails?.[index]?.certificateUrl?.status, data: certificate?.certificateUrl?.data },
        { label: 'License Number', name: `formData.education.certificationDetails.${index}.license.status`, value: formData?.education?.certificationDetails?.[index]?.license?.status, data: certificate?.license?.data }
    ]);

    const experienceInfoFields = formData?.experience?.map((exp, index) => [
        { label: "Organization Name", value: formData?.experience?.[index]?.organizationName?.status, data: exp?.organizationName?.data, name: `formData.experience.${index}.organizationName.status` },
        { label: "Position", value: formData?.experience?.[index]?.position?.status, data: exp?.position?.data, name: `formData.experience.${index}.position.status` },
        { label: "Start Date", value: formData?.experience?.[index]?.startDate?.status, data: dateFormat(exp?.startDate?.data), name: `formData.experience.${index}.startDate.status` },
        { label: "End Date", value: formData?.experience?.[index]?.endDate?.status, data: dateFormat(exp?.endDate?.data), name: `formData.experience.${index}.endDate.status` },
    ]);

    const handleEditDetails = () => navigate(`/admin/employee/recruits/editDetails/${id}?step=Personal+Details`)

    const onConfirmDelete = async () => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/recruits/deleteRecruit/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen(`Recruit deleted successfully!`, 'success');
        navigate('/admin/employee/recruits');
    }

    const handleDelete = () => openPopup('Delete Recruit', "Are you sure you want to delete the recruit?This action can't be reverted back!", 'delete', onConfirmDelete);

    const checkBoxOptions = {
        'Verified': true,
        'Rejected': false,
        'Pending': undefined,
        'true': 'Verified',
        'false': 'Rejected',
        'undefined': 'Pending'
    };

    return (
        <div className={`flex flex-col h-[calc(100svh-13rem)] tab:h-[calc(100svh-10rem)] lg:h-[calc(100svh-5rem)] transition-transform delay-500 duration-500 col-span-full ${documentOpen ? "lg:col-span-8" : "lg:col-span-15"}`}>
            <div className="sticky top-0 flex justify-between gap-5 w-full p-3 sm:px-5 md:px-8 drop-shadow-sm border-b h-[68px]">
                <div className="flex items-center gap-2 xs:gap-5">
                    <IconButton size="small" className="w-fit" aria-label="back" onClick={handleBackward}>
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    <h4 className="h3-p">Review Changes</h4>
                </div>

                <div className="flex gap-3">
                    <BlackToolTip title={onlineStatus !== 'Pending' ? `${onlineStatus} Application can't be edited!` : 'Edit Recruit Application'}>
                        <p>
                            <IconButton aria-label="Edit Details" disableElevation
                                className='rounded-sm' onClick={handleEditDetails} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }} disabled={onlineStatus !== 'Pending'}>
                                <EditOutlined className="text-amber-500" fontSize="small" />
                            </IconButton>
                        </p>
                    </BlackToolTip>

                    <BlackToolTip title='Discard Recruit Application Permanently'>
                    <p>
                        <IconButton aria-label="Discard Application" disableElevation
                            className='rounded-sm' onClick={handleDelete} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }}>
                            <DeleteOutline className="text-signal" fontSize="small" />
                        </IconButton>
                        </p>
                    </BlackToolTip>
                </div>
            </div>

            <form onSubmit={(e) => handleSubmit(e, formData, remark)} className="flex flex-col gap-8 xs:gap-12 p-5 xs:p-8 overflow-y-scroll no-scrollbar">
                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full w-fit" onChange={(e) => handleMasterCheckBox(e, 'General Details')} value={masterCheckBoxValue(generalInfoFields, checkBoxOptions)} name={generalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">General Details</h4>
                    </CustomCheckBox>

                    {generalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>
                    )}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Personal Details')} value={masterCheckBoxValue(personalInfoFields, checkBoxOptions)} name={personalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Personal Details</h4>
                    </CustomCheckBox>
                    {personalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Residential Address Details')} value={masterCheckBoxValue(residentialAddressInfoFields, checkBoxOptions)} name={residentialAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Residential Address Details</h4>
                    </CustomCheckBox>
                    {residentialAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Permanent Address Details')} value={masterCheckBoxValue(permanentAddressInfoFields, checkBoxOptions)} name={permanentAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Permanent Address Details</h4>
                    </CustomCheckBox>
                    {permanentAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Identity Details')} value={masterCheckBoxValue(identityInfoFields, checkBoxOptions)} name={identityInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Identity Details</h4>
                    </CustomCheckBox>
                    {identityInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Financial Details')} value={masterCheckBoxValue(financialAndLegalInfoFields, checkBoxOptions)} name={financialAndLegalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Financial Details</h4>
                    </CustomCheckBox>
                    {financialAndLegalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Additional Details')} value={masterCheckBoxValue(additionalInfoFields, checkBoxOptions)} name={additionalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Additional Details</h4>
                    </CustomCheckBox>
                    {additionalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                {formData?.familyInfo?.fatherDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Father Details')} value={masterCheckBoxValue(fatherInfoFields, checkBoxOptions)} name={fatherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Father Details</h4>
                        </CustomCheckBox>
                        {fatherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>)}
                    </div>}

                {formData?.familyInfo?.motherDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Mother Details')} value={masterCheckBoxValue(motherInfoFields, checkBoxOptions)} name={motherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Mother Details</h4>
                        </CustomCheckBox>
                        {motherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>)}
                    </div>}

                {formData?.familyInfo?.partnerDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Partner Details')} value={masterCheckBoxValue(partnerInfoFields, checkBoxOptions)} name={partnerInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Partner Details</h4>
                        </CustomCheckBox>
                        {partnerInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>
                        )}
                    </div>
                }

                {formData?.familyInfo?.children && childrenInfoFields?.map((fields, childIndex) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={childIndex}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Children ${formData?.familyInfo?.children?.length > 1 ? childIndex + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Children {formData?.familyInfo?.children?.length > 1 ? childIndex + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${childIndex}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.education?.elementaryDetail && <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Elementary Details')} value={masterCheckBoxValue(elementaryDetailFields, checkBoxOptions)} name={elementaryDetailFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Elementary Details</h4>
                    </CustomCheckBox>
                    {elementaryDetailFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                        <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>
                    )}
                </div>}

                {formData?.education?.intermediateDetail && (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Intermediate Details')} value={masterCheckBoxValue(intermediateDetailFields, checkBoxOptions)} name={intermediateDetailFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Intermediate Details</h4>
                        </CustomCheckBox>
                        {intermediateDetailFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                            </CustomCheckBox>
                        )}
                    </div>
                )}

                {formData?.education?.diplomaDetails && diplomaDetailFields?.map((fields, i) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Diploma ${diplomaDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Diploma {diplomaDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${i}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.education?.ugDetails && ugDetailFields?.map((fields, i) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Undergraduate ${ugDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Undergraduate {ugDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${i}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.education?.pgDetails && pgDetailFields?.map((fields, i) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Postgraduate ${pgDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Postgraduate {pgDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${i}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.education?.doctorateDetails && doctorateDetailFields?.map((fields, i) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Doctorate ${doctorateDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Doctorate {doctorateDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${i}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.education?.certificationDetails && certificationDetailFields?.map((fields, i) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Certification ${certificationDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Certification {certificationDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${i}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.experience && experienceInfoFields?.map((fields, expIndex) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={expIndex}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Organization ${experienceInfoFields?.length > 1 ? expIndex + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Organization {experienceInfoFields?.length > 1 ? expIndex + 1 : 'Details'}</h4>
                        </CustomCheckBox>
                        {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <div key={`${expIndex}-${index}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                <div className="flex flex-col xs:mt-4 gap-3 xs:gap-5">
                    <CustomTextField multiline rows={4} label='Add Remark' placeholder='Your Remark will be displayed to respective applicant for justification' value={remark} onChange={(e) => setRemark(e.target.value)} required={false} disabled={onlineStatus === 'Verified'} />

                    <div className="flex flex-col xs:flex-row gap-3 xs:gap-5 w-full">
                        <CloseButton fullWidth type='button' text='Review Later' onClick={handleBackward} />
                        {onlineStatus === 'Pending' ?
                            <ConfirmButton fullWidth type='submit' text={"Verify Online Verification"} /> :
                            onlineStatus === 'Rejected' ?
                                <ConfirmButton fullWidth type='submit' text="ReVerify Online Verification" /> :
                                onlineStatus === 'Verified' ?
                                    <PurpleButton fullWidth type='submit' text="Revert Online Verification" /> : <PurpleButton fullWidth disabled={true} text={isLoading ? 'Loading' : 'Status Not Found'} />}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default VerifyData;