import countryStateData from '../constants/countryStateData.json'

export const generateGeoOptions = (selectedCountry, selectedState) => {

    const countryOptions = Object.keys(countryStateData).map(country => ({
        value: country,
        label: country
    }));

    const stateOptions = selectedCountry && countryStateData[selectedCountry]
        ? Object.keys(countryStateData[selectedCountry]).map(state => ({
            value: state,
            label: state
        }))
        : [];

    const cityOptions = selectedCountry && selectedState && countryStateData[selectedCountry]?.[selectedState]
        ? countryStateData[selectedCountry][selectedState].map(city => ({
            value: city,
            label: city
        }))
        : [];

    return { countryOptions, stateOptions, cityOptions };
};
