import LgCollegeLongDark from './assets/logo/collegeLogo/LgCollegeLongDark.webp'
import LgCollegeLongLight from './assets/logo/collegeLogo/LgCollegeLongLight.webp'
import LgCollegeShortDark from './assets/logo/collegeLogo/LgCollegeShortDark.webp'
import RscnCollegeShortDark from './assets/logo/collegeLogo/RscnCollegeShortDark.webp'
import AspireCollegeShort from './assets/logo/collegeLogo/AspireCollegeShort.svg'

const collegeName = process.env.REACT_APP_COLLEGE_NAME;

const imageMap = {
  LgCollege: {
    longDark: LgCollegeLongDark,
    shortDark: LgCollegeShortDark,
    longLight: LgCollegeLongLight,
  },
  RscnCollege: {
    shortDark: RscnCollegeShortDark,
  },
  Staging: {
    shortDark: AspireCollegeShort,
  },
};


const getCollegeImages = (collegeName) => {
  const images = imageMap[collegeName] || {};

  const {
    longDark,
    shortDark,
    longLight,
    shortLight
  } = images;

  // Fallback logic
  const finalImages = {
    longDark: longDark || longLight || 'default_image',
    shortDark: shortDark || shortLight || 'default_image',
    longLight: longLight || longDark || 'default_image',
    shortLight: shortLight || shortDark || 'default_image',
  };

  // If only one image is available, return it for all four types
  const availableImages = Object.values(images);
  if (availableImages.length === 1) {
    const singleImage = availableImages[0];
    return {
      longDark: singleImage,
      shortDark: singleImage,
      longLight: singleImage,
      shortLight: singleImage,
    };
  }

  return finalImages;
};

const collegeLogos = getCollegeImages(collegeName);

export default collegeLogos;