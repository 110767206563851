import React from "react";
import Pending from '../../../assets/icons/Pending.svg'
import Rejected from '../../../assets/icons/Rejected.svg'
import Submitted from '../../../assets/icons/Submitted.svg'
import Success from '../../../assets/icons/Success.svg'
import SkeletonText from "../../mui/text/SkeltonText";
import BlackToolTip from "../../mui/BlackToolTip";

const DocumentBox = ({ label, value, status, handleClick, loading }) => {

    const icon = {
        Pending: Pending,
        Rejected: Rejected,
        Submitted: Submitted,
        Verified: Success
    }

    return (
        <div className="flex items-center gap-3 w-full">
            {!loading ? <BlackToolTip title={status}>
                <img className="flex flex-shrink-0 h-12 w-12 cursor-pointer" src={icon[status]} onClick={handleClick} alt="Document Status" />
            </BlackToolTip>
                : <div className="flex flex-shrink-0 h-12 w-12 rounded-full bg-muiLight animate-pulse" />}
            <div className="flex flex-col w-full">
                <SkeletonText width={'60%'} loading={loading}><BlackToolTip title="View uploaded Document"><p className="text-p cursor-pointer w-fit" onClick={handleClick}>{label}</p></BlackToolTip></SkeletonText>
                <SkeletonText width={'80%'} loading={loading}><span className="text-s">{value}</span></SkeletonText>
            </div>
        </div>
    )
}

export default DocumentBox;