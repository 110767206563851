import React from 'react'
import { Avatar, useMediaQuery } from '@mui/material';
import { CloudUpload, Forward } from '@mui/icons-material';
import { CustomTextField, CustomAsyncSelect } from '../../../../../components/mui/Input'
import { CloseButton, ConfirmButton } from '../../../../../components/mui/button/Button';
import { bloodGroups, disabilities, genders } from "../../../../../constants/personal"
import { departments } from "../../../../../constants/institution"
import renderFormField from '../../../../../utils/renderFormField';

const PersonalInfo = ({ handleNestedChange, formData, isLoading }) => {

  const md = useMediaQuery('(max-width:900px)');
  const xs = useMediaQuery('(max-width:500px)');
  const departmentOptions = departments.map(gender => ({ value: gender, label: gender }));
  const genderOptions = genders.map(gender => ({ value: gender, label: gender }));
  const bloodOptions = bloodGroups.map(blood => ({ value: blood, label: blood }));
  const disabilityOptions = disabilities.map(option => ({ value: option, label: option }))

  const institutionalDetailsFields = [
    { type: 'input', size: 1, label: "Official Email", value: formData?.officialEmailId, name: 'formData.officialEmailId' },
    { type: 'input', size: 1, label: "Employee ID", value: formData?.employeeId, name: 'formData.employeeId' },
    { type: 'input', size: 1, label: "Designation", value: formData?.designation, name: 'formData.designation' },
    { type: 'select', size: 'full', label: "Department", value: formData?.department, name: 'formData.department', options: departmentOptions, skeletonClassName: 'col-span-full' }
  ]

  const personalDetailsFields = [
    { type: 'input', size: 1, label: "Middle Name", value: formData?.middleName, name: 'formData.middleName' },
    { type: 'input', size: 1, label: "Last Name", value: formData?.lastName, name: 'formData.lastName' },
    { type: 'select', size: 1, label: "Gender", value: formData?.personalInfo?.gender, name: "formData.personalInfo.gender", options: genderOptions },
    { type: 'date', size: 1, label: "Date of Birth", value: formData?.personalInfo?.dob, name: "formData.personalInfo.dob" },
    { type: 'select', size: 1, label: "Blood Group", value: formData?.personalInfo?.bloodGroup, name: "formData.personalInfo.bloodGroup", options: bloodOptions },
    { type: 'input', size: 1, label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue, name: "formData.additionalInfo.motherTongue" },
    { type: 'select', size: 1, label: "Disability", value: formData?.additionalInfo?.disability, name: "formData.additionalInfo.disability", options: disabilityOptions }
  ];

  return (
    <div class="flex flex-col w-full gap-8">
      <div class="flex flex-col gap-3">
        <h5 className="col-span-full h4-p">Photo</h5>
        <div className='flex flex-col md:flex-row items-start md:items-center gap-5'>
          <div className='flex flex-col xs:flex-row items-center w-full xs:w-fit gap-5 xs:gap-8'>
            {!isLoading ? <Avatar alt='IMG' src={formData?.documents?.photoURL} style={{ borderRadius: 5, height: 192, width: (xs ? '100%' : 192) }} /> : <div className='h-48 w-48 bg-muiLight animate-pulse rounded-md'></div>}
            {!md && <Forward color='success' sx={{ fontSize: 50 }} />}
            <button className='flex flex-col items-center justify-center text-center p-5 border-dashed border-2 border-slate-300 bg-slate-100 rounded-md h-48 w-full xs:w-48'>
              <CloudUpload sx={{ fontSize: 48, color: "#A1A1AA" }} />
              <p className='text-s'>Click to Change Photo</p>
            </button>
          </div>
          <div className='flex flex-col xs:flex-row md:flex-col gap-5 w-full md:w-fit'>
            <ConfirmButton />
            <CloseButton />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <h5 className="col-span-full h4-p">Institutional Details</h5>
        {institutionalDetailsFields.map((field, index) => (field?.value || isLoading) && renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <h5 className="col-span-full h4-p">Personal Details</h5>
        {!isLoading ? <div className="flex w-full col-span-full md:col-span-1">
          <CustomAsyncSelect
            className='bg-white w-24' label="Title"
            options={[
              { value: 'Dr', label: 'Dr' },
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
            ]} onChange={handleNestedChange}
            name='formData.title' value={formData?.title}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />
          <CustomTextField
            label="First Name" name='formData.firstName' value={formData?.firstName} onChange={handleNestedChange}
            fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
          />
        </div> : <div className="animate-pulse h-14 w-full rounded-md bg-muiLight" />}
        {personalDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
      </div>
    </div>
  )
}

export default PersonalInfo;