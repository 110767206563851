import React from 'react'
import { useSetRecoilState } from 'recoil';
import Lottie from 'lottie-react';

import usePutData from '../../../../../hooks/usePutData';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useFormOperations from '../../../../../hooks/useFormOperations';
import { loadingState } from "../../../../../recoil/state";

import NoData from '../../../../../assets/lotties/NoData.json'
import renderFormField from '../../../../../utils/renderFormField';
import { CloseButton, ConfirmButton } from '../../../../../components/mui/button/Button';
import { useNavigate } from 'react-router-dom';

const EmploymentInfo = ({ formData, isLoading }) => {
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const data = { experience: formData?.experience };
    const onSubmit = async () => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/personal/updateCurrentDetails`, employeeData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen('Information Updated Successfully!', 'success');
        navigate('/admin/settings/updateInfo?type=Personal')
    }
    const { formData: employeeData, handleNestedChange, handleSubmit, clearForm } = useFormOperations(data, onSubmit)

    const employmentProperties = [
        { type: 'space' },
        { type: 'input', size: 1, label: "Organization Name", name: "organizationName" },
        { type: 'input', size: 1, label: "Designation", name: "position" },
        { type: 'date', size: 1, label: "Joining Date", name: "startDate" },
        { type: 'date', size: 1, label: "Leaving Date", name: "endDate" }
    ]

    return (
        employeeData?.experience ? <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {
                employeeData?.experience?.map((_, i) => (
                    employmentProperties?.map((property, index) => ((property?.type !== 'space' || i !== 0) && renderFormField({
                        type: property?.type,
                        size: property?.size,
                        label: property?.label,
                        name: `employeeData.experience.${i}.${property?.name}`,
                        value: employeeData?.experience?.[i]?.[property?.name],
                        onChange: handleNestedChange,
                        customType: property?.customType
                    }, index, isLoading)))))
            }
            <div className='col-span-full flex flex-col-reverse xs:flex-row justify-end gap-3 xs:gap-5 w-full'>
                <CloseButton text="Discard" onClick={clearForm} />
                <ConfirmButton text='Submit' onClick={handleSubmit} />
            </div>
        </div> : <div className='flex flex-col justify-center items-center h-full'>
            <Lottie className='h-28' animationData={NoData} loop={true} />
            <p className='text-s'>You have not updated any previous work experience.</p>
        </div>
    )
}

export default EmploymentInfo;