import React from 'react';
import Badge from '@mui/material/Badge';
import { keyframes } from '@mui/system';

// Keyframes for blinking animation
const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;

const BlinkingBadge = ({ active, badgeColor, children, ...props }) => {
  return (
    <Badge overlap="circular" variant="dot" sx={{ '& .MuiBadge-dot': { backgroundColor: badgeColor, animation: `${active ? blinkAnimation : 'none'} 1.5s ease-in-out infinite`, }, }} {...props}>
      {children}
    </Badge>
  );
};

export default BlinkingBadge;
