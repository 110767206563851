import Lottie from "lottie-react";
import NoData from '../../../../assets/lotties/NoData.json'
import TextBox from "../../../../components/mui/text/TextBox";
import { dateFormat } from "../../../../utils/dateFormat";

const ExperienceDetails = ({ experienceData, loading }) => {
    return (
        <div className='flex flex-col w-full space-y-8'>
            {!loading && !(!!experienceData?.length) ? <div className='flex flex-col justify-center items-center min-h-[calc(100svh-400px)] gap-2'>
                <Lottie className='h-52' animationData={NoData} loop={true} />
                <p className='lb-s text-base'>You have not updated any previous work experience.</p>
            </div> :
                (!loading ? experienceData : Array.from({ length: 2 }))?.map((experience, i) => <>
                    {i !== 0 && <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />}
                    <div className="flex flex-col gap-y-5">
                        <div className='flex items-center gap-4'>
                            <p className='lb-s whitespace-nowrap'>Experience Details</p>
                            <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                            <TextBox label='Organization Name' value={experience?.organizationName} loading={loading} />
                            <TextBox label='Position' value={experience?.position} loading={loading} />
                            <TextBox label='Start Date' value={dateFormat(experience?.startDate)} loading={loading} />
                            <TextBox label='End Date' value={dateFormat(experience?.endDate)} loading={loading} />
                        </div>
                    </div>
                </>)}
        </div>
    )
}

export default ExperienceDetails;