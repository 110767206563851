import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { TypeAnimation } from 'react-type-animation';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import { IconButton } from '@mui/material'
import { AccountCircle, Lock, Visibility, VisibilityOff, Email, ChangeCircle } from '@mui/icons-material'

import { loadingState, loggedInState } from '../../recoil/state'
import useFormOperations from '../../hooks/useFormOperations'
import usePostData from '../../hooks/usePostData'
import useSnackbar from '../../hooks/useSnackbar'
import useGetData from '../../hooks/useGetData'

import { ConfirmButton, PurpleButton } from '../../components/mui/button/Button'
import { CustomTextField } from '../../components/mui/Input'
import BlackToolTip from '../../components/mui/BlackToolTip'
import { deleteKeys } from '../../utils/formFunctions'
import google from "../../assets/logo/google.svg"
import mail from "../../assets/icons/loginMail.svg"
import password from "../../assets/icons/loginPassword.svg"
import collegeLogos from '../../CollegeConfig';

const Login = () => {
    useGetData('/api/cookies/flushCookies');

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const activeMode = searchParams.get('type')

    const { handleSnackbarOpen } = useSnackbar();
    const [, setLoading] = useRecoilState(loadingState);
    const setLoggedInState = useSetRecoilState(loggedInState);
    const { postData } = usePostData();

    const [otp, setOtp] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const toggleEmailSubmitted = () => setEmailSubmitted(!emailSubmitted);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const otpVerifyButton = document.getElementById('otpVerify');
            if (otpVerifyButton) otpVerifyButton.click();
        }
    };

    const onSubmit = async (formData) => {
        setLoading(true)
        const { data, success, message } = await postData('/api/login', formData)
        setLoading(false)
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Invalid Credentials', 'error', null, 'bottom', 'left');

        const { loginType } = data;
        setLoggedInState(loginType);
        handleSnackbarOpen('Login successful', 'success', null, 'bottom', 'left');
        navigate('/admin/profile?type=Personal');
    };

    const { formData, updateData, handleSubmit, handleChange } = useFormOperations({}, onSubmit);

    const handleSignInModeChange = async () => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', activeMode === 'otp' ? 'password' : 'otp');
            return Object.fromEntries(newParams.entries());
        });

        if (activeMode === 'otp') {
            const updatedFormData = { ...formData };
            deleteKeys(updatedFormData, ['email'])
            updateData((formData) => ({ ...updatedFormData }));
            setOtp('')
        } else {
            const updatedFormData = { ...formData };
            deleteKeys(updatedFormData, ['username', 'password'])
            updateData((formData) => ({ ...updatedFormData }));
        };
    }

    const onGenerateOtp = async () => {
        const OTPFormData = { email: formData?.email }
        setLoading(true);
        const { success, message } = await postData('/api/login/generateOTP', OTPFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP to your Email ID!", 'error')

        handleSnackbarOpen('OTP sent to your Email ID!', 'success');
        setEmailSubmitted(true);
    }

    const onOTPVerify = async () => {
        const OTPFormData = { email: formData?.email, otp }
        setLoading(true);
        const { success, message, data } = await postData('/api/login/verifyOTP', OTPFormData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        const { loginType } = data;
        setLoggedInState(loginType);
        handleSnackbarOpen("OTP Verified successfully!", 'success');

        if (loginType === 'Admin Login') return navigate('/admin/profile?type=Personal');
        navigate('/login');
    }

    const SignGoogle = async (data) => {
        console.log(data)
        const { success, message } = await postData('/api/login/googleSuccess', { code: data?.code })
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some error occurred from our end, pls try again!", 'error');
        navigate('/admin/profile?type=Personal');
    };

    const showErrorPopup = () => handleSnackbarOpen("Opps.. Some error occurred!", 'error');

    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: SignGoogle,
        onNonOAuthError: (error) => { if (error.type !== 'popup_closed') showErrorPopup(); },
        onError: showErrorPopup,
        ux_mode: 'popup',
        hint: 'Select your Official Account only'
    });

    useGoogleOneTapLogin({
        onError: (error) => handleSnackbarOpen("Opps.. Some error occurred!", 'error'),
        onSuccess: SignGoogle,
        googleAccountConfigs: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
        }
    });

    return (
        <div className="relative flex justify-center items-center min-h-screen h-full md:p-10 md:px-20">

            {/* Wrapper for background split */}
            <div className="absolute inset-0 flex">
                <div className="w-1/2 bg-regal-purple-10 h-full" />
                <div className="w-1/2 bg-regal-purple-800 h-full" />
            </div>

            {/* Main content container */}
            <div className="relative z-10 grid grid-cols-12 min-h-[670px] h-full w-full max-w-8xl drop-shadow-md md:rounded-3xl bg-white">

                <div className="lg:col-span-6 hidden lg:flex flex-col px-16 py-10 lg:py-20 gap-20">
                    <img draggable={false} className='hidden tab:block h-20 w-20 xl:h-28 xl:w-28' src={collegeLogos?.shortLight} alt="College Logo" />
                    <p className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-700 to-slate-500">Seamlessly manage and verify student admission forms with precision, ensuring fast and accurate processing. Access essential details and documents instantly to streamline validation and enhance the admissions workflow.</p>
                    <div className='flex flex-col gap-10'>
                        <TypeAnimation
                            sequence={[
                                `Welcome to ${process.env.REACT_APP_COLLEGE_NAME}`,
                                2000,
                                `Welcome to ${process.env.REACT_APP_COLLEGE_NAME}`,
                                2000
                            ]}
                            wrapper="span"
                            speed={10}
                            style={{ fontSize: '1.5em', display: 'inline-block', fontWeight: 500, color: '#2C0A63' }}
                            repeat={Infinity}
                        />
                        <span className='text-base text-slate-700'>Built with ❤️ by the developers at Byteswrite</span>
                    </div>
                </div>

                <div className="col-span-full flex flex-col lg:col-span-6 items-center text-center w-full px-8 sm:px-10 py-10 lg:py-14 gap-10">
                    <header className='flex flex-col items-center select-none gap-2'>
                        <img draggable={false} className='block lg:hidden h-20 w-20' src={collegeLogos?.shortLight} alt="College Logo" />
                        <img draggable={false} className='w-48 sm:w-52 h-20' src="/logo/campus.svg" alt="CAMPUS_Logo" />
                        <p className='text-light text-xs sm:text-sm font-medium text-center max-w-xl w-full'>Achieve Academic Excellence with Us</p>
                    </header>

                    <div className='flex flex-col gap-5 max-w-md w-full' >
                        <div className='flex flex-col items-center gap-3'>
                            <form className='flex flex-col gap-6 w-full justify-center items-center' onSubmit={handleSubmit}>
                                {activeMode === 'otp' ? <CustomTextField
                                    name="email" label="Email Address" color="secondary" type="email" placeholder="abc@example.com"
                                    value={formData?.email ?? ''} onChange={handleChange} disabled={emailSubmitted} fullWidth
                                    startIcon={<Email className="text-regal-purple-600" />}
                                    endIcon={emailSubmitted &&
                                        <BlackToolTip arrow message='Change email address?'>
                                            <ChangeCircle className="text-light hover:text-regal-purple-600 cursor-pointer" onClick={toggleEmailSubmitted} />
                                        </BlackToolTip>
                                    }
                                /> : <CustomTextField
                                    label="User ID"
                                    name="username"
                                    value={formData?.username ?? ''}
                                    placeholder='Enter your official User ID'
                                    fullWidth
                                    startIcon={<AccountCircle className="text-regal-purple-600" />}
                                    onChange={handleChange}
                                />}
                                {activeMode === 'otp' ? (emailSubmitted ? <div className='flex flex-col gap-[7px] w-full'>
                                    <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 18 } }} onKeyDown={handleKeyDown} />
                                    <p className="flex gap-x-2 text-sm">
                                        <span className="text-light">Didn't receive the code?</span>
                                        <button type="submit" className="text-regal-purple-500 hover:underline ">Resend it.</button>
                                    </p>
                                </div> : <p className="h-[81px] grid place-items-center border-dashed border-2 w-full p-3">
                                    <span className="text-center text-s leading-6">
                                        Sign in seamlessly with OTP. <br />
                                        Verify your Email ID.
                                    </span>
                                </p>) : <div className='flex flex-col gap-[7px] w-full'>
                                    <CustomTextField
                                        label="Password"
                                        name="password"
                                        placeholder="Enter your password"
                                        value={formData.password}
                                        type={showPassword ? 'text' : 'password'}
                                        fullWidth
                                        startIcon={<Lock className="text-regal-purple-600" />}
                                        endIcon={
                                            <IconButton aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword} edge="end"
                                            >
                                                {showPassword ? <VisibilityOff className="text-regal-purple-600" /> : <Visibility className="text-regal-purple-600" />}
                                            </IconButton>
                                        } onChange={handleChange}
                                    />

                                    <Link to='/forgotPassword' className='text-s hover:text-regal-purple-500 hover:underline w-fit'>
                                        Forgot Password?
                                    </Link>
                                </div>}
                                {activeMode === 'otp' ?
                                    (emailSubmitted ?
                                        <PurpleButton id='otpVerify' disabled={otp.length < 6} size='large' fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} sx={{ paddingX: 3, paddingY: 1.3 }} />
                                        : <ConfirmButton fullWidth size='large' type="button" text="Generate OTP" onClick={onGenerateOtp} sx={{ paddingX: 3, paddingY: 1.3 }} />) :
                                    <PurpleButton size='large' text='login' type='submit' fullWidth sx={{ paddingX: 3, paddingY: 1.3 }} />}
                            </form>
                        </div>

                        <div className='flex justify-center items-center w-full gap-5'>
                            <hr className='border w-full' />
                            <p className='text-s font-semibold text-center min-w-fit'>Reconnect with a touch</p>
                            <hr className='border w-full' />
                        </div>

                        <div className='grid grid-cols-2 gap-5'>
                            <button className='flex p-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-md w-full select-none'
                                onClick={handleGoogleLogin}
                            >
                                <div className='h-fit w-fit flex p-1 bg-white rounded-full'>
                                    <img draggable={false} src={google} className='h-5 w-5' alt="googleImage" />
                                </div>
                                <h5 className='text-p font-semibold'>Sign in with Google</h5>
                            </button>

                            <button className='flex p-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-md w-full select-none' onClick={handleSignInModeChange}>
                                <img draggable={false} src={activeMode === 'otp' ? password : mail} className='h-6 w-6' alt="googleImage" />
                                <h5 className='text-p font-semibold'>{activeMode === 'otp' ? 'Sign in  with Password' : 'Sign in with OTP'}</h5>
                            </button>
                        </div>

                        <p className='text-s text-end'>Not registered yet? <Link to='/admissions/login?type=Student' className='hover:text-regal-purple-500 hover:underline'>Join with Us.</Link></p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Login;