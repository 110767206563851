import { useState } from 'react';
import Lottie from 'lottie-react';
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForward, ArrowBack, CloudDownload } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

import { loadingState } from "../../../../recoil/state";
import useSteppers from '../../../../hooks/useSteppers';
import useSnackbar from '../../../../hooks/useSnackbar';
import usePutData from "../../../../hooks/usePutData";
import useGetData from '../../../../hooks/useGetData';
import useViewPopup from '../../../../hooks/useViewPopup';

import UploadDocumentTile from '../../../../components/upload/UploadDocumentTile'
import { ConfirmButton, PurpleButton } from '../../../../components/mui/button/Button'
import { SquareIconButton } from '../../../../components/mui/button/SquareIconButton';
import selfDeclaration from '../../../../assets/documents/selfDeclaration.pdf'
import Pending from "../../../../assets/lotties/Pending.json";

const UploadDocuments = ({ id, formData, submitted, steps }) => {
  const { isBackwardDisabled, isForwardDisabled, handleBackward, handleForward } = useSteppers(steps);

  const location = useLocation()
  const navigate = useNavigate();
  const { openPopup } = useViewPopup();
  const { handleSnackbarOpen } = useSnackbar();
  const [, setLoading] = useRecoilState(loadingState);
  const { data: documentsList, success, isLoading: documentsListLoading, updateData } = useGetData(id ? `/api/employee/admission/applicants/getDocuments/${id}` : "/api/admission/student/getDocuments");
  const { data: gapYearData, isLoading: gapYearLoading } = useGetData(id ? `/api/employee/admission/applicants/getGapYear/${id}` : "/api/admission/student/getGapYear");
  const { putData } = usePutData();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const routeMap = {
    add: `/api/employee/admission/applicants/submitAdmission/${id}`,
    edit: `/api/employee/admission/applicants/submitAdminChanges/${id}`
  };

  const type = location?.pathname?.includes('addApplicant') ? 'add' : 'edit';

  const generateUploadUrl = (key) => id ? `/api/employee/admission/applicants/getUploadURL/${id}?key=${key}` : `/api/admission/student/getUploadURL?key=${key}`;
  const generateViewUrl = (key) => id ? `/api/employee/admission/applicants/getViewURL/${id}?key=${key}` : `/api/admission/student/getViewURL?key=${key}`;
  const generateDeleteUrl = (key) => id ? `/api/employee/admission/applicants/deleteUploadedObject/${id}?key=${key}` : `/api/admission/student/deleteUploadedObject?key=${key}`;

  const updateDocumentStatus = (documentKey, newStatus) => {
    updateData(prevState => {
      const updatedDocuments = { ...prevState };
      Object.keys(updatedDocuments).forEach(category => {
        updatedDocuments[category] = updatedDocuments[category].map(doc =>
          doc.key === documentKey ? { ...doc, onlineStatus: newStatus } : doc
        );
      });
      return updatedDocuments;
    });
  };
  const allDocumentsSubmitted = () => {
    for (const category in documentsList) {
      if (documentsList.hasOwnProperty(category)) {
        for (const document of documentsList[category]) {
          if (document?.onlineStatus === "Pending" || document?.onlineStatus === "Rejected") return false;
        }
      }
    }
    return true;
  }

  const handleFormSubmit = async () => {
    setLoading(true);
    const { data, message, success } = await putData(id ? routeMap[type] : '/api/admission/student/submitAdmission', id && formData);
    setLoading(false);
    if (message) return handleSnackbarOpen(message, 'warning');
    if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
    if (data?.reSubmitted) {
      handleSnackbarOpen('Your changes are successfully saved. We will update you soon.', 'success');
      return navigate('/admissions/student/profile?type=Personal');
    }
    if (id) {
      handleSnackbarOpen(type === 'add' ? 'Applicant added successfully' : 'Applicant changes are successfully saved', 'success');
      return navigate(type === 'add' ? '/admin/student/applicants' : `/admin/student/applicants/onlineVerify/${id}`)
    } else {
      openPopup('Form Submitted Successfully', `Your admission form has been submitted successfully and your Admission Id is ${data?.admissionId}. Save this Id for further reference, though we have already mailed you. Thank you for choosing our college.`, 'success', () => navigate('/admissions/student/profile?type=Personal'))
    }
  }

  const renderLoadingTiles = (count = 5) =>
    Array.from({ length: count }, (_, index) => <UploadDocumentTile key={`loading-${index}`} loading />);

  return (
    <div className='flex flex-col'>
      {!documentsListLoading && !gapYearLoading && !success ?
        <div className='flex flex-col p-5 xs:p-10 text-center border-2 gap-5 h-fit'>
          <Lottie className='h-40' animationData={Pending} loop={true} />
          <p className='flex flex-col text-s font-normal'>
            <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
            <span> Please select your <span className='font-semibold text-slate-500'>Program name, Program Quota, Round Type, and Category</span>.
              Before proceeding to the Document Section. </span>
          </p>
        </div>

        :

        <div className='flex flex-col'>
          <div className='flex flex-col gap-5 w-full px-5 sm:px-8 lg:px-14 py-10 bg-tertiary border-2'>
            <div className='flex items-center justify-between'>
              <h5 className="h4-p">General Proofs</h5>
              <SquareIconButton title='Download Self Declaration' sx={{ backgroundColor: 'white', border: 2, borderColor: '#cbd5e1' }} icon={<CloudDownload sx={{ color: "green", fontSize: 20 }} />} href={selfDeclaration} download='Self Declaration'>
              </SquareIconButton>
            </div>
            {(documentsListLoading || gapYearLoading)
              ? renderLoadingTiles() : !documentsList?.commonDocuments ?
                <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                  <Lottie className='h-40' animationData={Pending} loop={true} />
                  <p className='flex flex-col text-s font-normal'>
                    <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                    <span> Please select your <span className='font-semibold text-slate-500'>College</span>.
                      Before proceeding to the Document Section. </span>
                  </p>
                </div>
                :
                <>
                  {documentsList?.commonDocuments?.map((doc, index) =>
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc?.key)} viewURL={generateViewUrl(doc?.key)} deleteURL={generateDeleteUrl(doc?.key)} index={index} doc={doc} updateStatus={updateDocumentStatus} />)}
                  {documentsList?.optionalCommonDocuments?.map((doc, index) =>
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc?.key)} viewURL={generateViewUrl(doc?.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />)}
                </>}
          </div>

          <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2'>
            <h5 className="h4-p">Academic Proofs</h5>
            {(documentsListLoading || gapYearLoading)
              ? renderLoadingTiles() : !documentsList?.academicDocuments ?
                <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                  <Lottie className='h-40' animationData={Pending} loop={true} />
                  <p className='flex flex-col text-s font-normal'>
                    <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                    <span> Please select your <span className='font-semibold text-slate-500'>Academic Details</span>.
                      Before proceeding to the Document Section. </span>
                  </p>
                </div>
                : documentsList?.academicDocuments?.map((doc, index) => (
                  <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                ))}
          </div>

          {documentsList?.programDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 bg-tertiary border-2'>
              <h5 className="h4-p">Additional Academic Proofs</h5>
              {(documentsListLoading || gapYearLoading)
                ? renderLoadingTiles(2) : !documentsList?.programDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Program Details</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.programDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          {documentsList?.gapDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2 bg-tertiary'>
              <div className='flex flex-col gap-2'>
                <h5 className="h4-p">Gap Year Proofs</h5>
                <div className='flex flex-col lb-s'>
                  <p>You have {gapYearData} gap Years. Please upload combined proof for all the gap years.</p>
                </div>
              </div>
              {(documentsListLoading || gapYearLoading)
                ? renderLoadingTiles() : !documentsList?.gapDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Academic Details</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.gapDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          {documentsList?.categoryDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2'>
              <h5 className="h4-p">Category Related Proofs</h5>
              {(documentsListLoading || gapYearLoading)
                ? renderLoadingTiles(2) : !documentsList?.categoryDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Category</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.categoryDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          {documentsList?.quotaDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 bg-tertiary py-10 border-2'>
              <h5 className="h4-p">Admission Quota Proofs</h5>
              {(documentsListLoading || gapYearLoading)
                ? renderLoadingTiles(2) : !documentsList?.quotaDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Admission Quota</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.quotaDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          {documentsList?.roundDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2'>
              <h5 className="h4-p">Round Documents Proofs</h5>
              {(documentsListLoading || gapYearLoading)
                ? renderLoadingTiles(2) : !documentsList?.roundDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Round</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.roundDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          <div className='flex flex-col gap-7 p-8 sm:p-14 bg-white border-b-2 border-x-2'>
            {!(id && location.pathname.startsWith('/admin')) && <div className="col-span-full flex items-center">
              <Checkbox id="confirm-checkbox" checked={isConfirmed} color="secondary" onChange={() => setIsConfirmed(!isConfirmed)} />
              <label htmlFor="confirm-checkbox" className="text-s cursor-pointer">I agree to all the terms and conditions of the college and submitting the form with my acknowledgement.</label>
            </div>}
            <div className='flex flex-col sm:flex-row justify-between gap-5'>
              {id && <div className='flex justify-between sm:justify-start w-full sm:w-fit gap-5'>
                <PurpleButton disabled={isBackwardDisabled} onClick={handleBackward} text={<ArrowBack sx={{ color: 'white' }} />} />
                <PurpleButton disabled={isForwardDisabled} onClick={handleForward} text={<ArrowForward sx={{ color: 'white' }} />} />
              </div>}
              {id && location.pathname.startsWith('/admin') ?
                <PurpleButton size='large' disabled={!(documentsList?.commonDocuments && documentsList?.academicDocuments && documentsList?.gapDocuments && documentsList?.programDocuments && documentsList?.categoryDocuments && documentsList?.quotaDocuments && documentsList?.roundDocuments)} type="submit" text='Confirm Changes' onClick={handleFormSubmit} />
                :
                <ConfirmButton size='large' disabled={!(documentsList?.commonDocuments && documentsList?.academicDocuments && documentsList?.gapDocuments && documentsList?.programDocuments && documentsList?.categoryDocuments && documentsList?.quotaDocuments && documentsList?.roundDocuments) || !isConfirmed || !allDocumentsSubmitted()} type="submit" text={submitted ? 'Re-Submit Application' : 'Submit Application'} onClick={handleFormSubmit} />}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UploadDocuments;