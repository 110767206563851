import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { loadingState } from "../../../../../recoil/state";

import useGetData from "../../../../../hooks/useGetData";
import useFormOperations from "../../../../../hooks/useFormOperations";
import useFetchData from "../../../../../hooks/useFetchData";
import useSnackbar from "../../../../../hooks/useSnackbar";
import usePutData from "../../../../../hooks/usePutData";

import VerifyData from "./VerifyData";
import DocumentBar from "./DocumentBar";
import RejectConfirmationDialogue from "./RejectConfirmationDialogue";

const OnlineVerification = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { data: documentsData, isLoading: isDocumentsLoading } = useGetData(`/api/employee/admission/applicants/getDocuments/${id}`);
    const { data: applicantData, isLoading: applicantDataLoading } = useGetData(`/api/employee/admission/applicants/getVerificationFields/${id}`);
    const { data: status } = useGetData(`/api/employee/admission/applicants/getStatus/${id}`);
    const { fetchData } = useFetchData();
    const { putData } = usePutData();

    const [documentState, setDocumentState] = useState({ isOpen: false, key: '', url: '' });
    const [dialogueData, setDialogueData] = useState({ open: false, message: '', onConfirm: () => { }, onClose: () => { } });

    const onSubmit = async (formData, remark, confirmation = false) => {
        const body = { data: formData, message: remark }
        setLoading(true);
        const { data, message, success } = await putData(`/api/employee/admission/applicants/verifyOnlineAdmission/${id}?confirmation=${confirmation}`, body)
        setLoading(false);
        if (data?.confirmationRequired) return setDialogueData({ open: true, message: data.rejectedMessage, onConfirm: () => onSubmit(formData, remark, true), onClose: () => setDialogueData((previousData) => ({ ...previousData, open: false })) });
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred!', 'warning');
        handleSnackbarOpen(`Student Application is ${data.status} successfully`, 'success');
        navigate('/admin/student/applicants');
    };

    const toggleDocument = async (key = null) => {
        if (documentState.isOpen && documentState.key === key)
            return setDocumentState(prevState => ({ ...prevState, isOpen: false, url: '', key: '' }));

        const signedURL = await fetchData(`/api/employee/admission/applicants/getViewURL/${id}?key=${key}`);
        setDocumentState(prevState => ({ ...prevState, isOpen: true, url: signedURL, key }));
    };

    const handleDocumentAction = async (status) => {
        const updatedData = { ...documentsData };
        const key = documentState.key;

        const updateStatus = documents => {
            const doc = documents.find(doc => doc.key === key);
            if (doc) doc.onlineStatus = status;
        };
        Object.values(updatedData).forEach(updateStatus);

        const body = { status, key, type: 'online' }

        const { message, success } = await putData(`/api/employee/admission/applicants/updateDocumentStatus/${id}`, body);
        if (message) return handleSnackbarOpen(message, "warning");
        if (!success) return handleSnackbarOpen(`Opps.. Some Error occurred`, "error");

        handleSnackbarOpen(`The document status has been updated to ${status}.`, "success");
    };

    const { formData, masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit, onDiscard, clearForm } = useFormOperations(applicantData, onSubmit, '', applicantData);
    const formOperations = { masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit, onDiscard, clearForm, };

    return (
        <div className="grid grid-cols-16 w-full transition-transform delay-500 duration-500">
            <VerifyData id={id} formData={formData} applicantData={applicantData} documentOpen={documentState?.isOpen} isLoading={applicantDataLoading} formOperations={formOperations} onlineStatus={status?.onlineVerificationStatus} />
            <DocumentBar formData={formData} data={documentsData} documentState={documentState} toggleDocument={toggleDocument} handleDocumentAction={handleDocumentAction} loading={isDocumentsLoading} />
            {dialogueData.open && <RejectConfirmationDialogue message={dialogueData.message} onConfirm={dialogueData.onConfirm} onClose={dialogueData.onClose} />}
        </div>
    );
};

export default OnlineVerification;