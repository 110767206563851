import React from "react";
import TextBox from '../../../../components/mui/text/TextBox';

const BasicInfo = ({ basicData, loading }) => {

    const fields = [
        { label: 'Gender', value: basicData?.gender },
        { label: 'Blood Group', value: basicData?.bloodGroup },
        {
            label: 'Residential Address',
            value: [
                String(basicData?.residentialAddress?.address || '').replace(/,\s*$/, ''),
                String(basicData?.residentialAddress?.town || '').replace(/,\s*$/, ''),
                String(basicData?.residentialAddress?.city || '').replace(/,\s*$/, ''),
                String(basicData?.residentialAddress?.state || '').replace(/,\s*$/, ''),
                String(basicData?.residentialAddress?.pincode || '').replace(/,\s*$/, '')
            ].filter(Boolean).join(', ')
        },
        {
            label: 'Permanent Address',
            value: [
                String(basicData?.permanentAddress?.address || '').replace(/,\s*$/, ''),
                String(basicData?.permanentAddress?.town || '').replace(/,\s*$/, ''),
                String(basicData?.permanentAddress?.city || '').replace(/,\s*$/, ''),
                String(basicData?.permanentAddress?.state || '').replace(/,\s*$/, ''),
                String(basicData?.permanentAddress?.pincode || '').replace(/,\s*$/, '')
            ].filter(Boolean).join(', ')
        },
    ]

    return (
        <>
            <div className='flex items-center space-x-4'>
                <div className='lb-s whitespace-nowrap'>Basic Info</div>
                <hr className='w-full h-0.5 bg-slate-400' />
            </div>
            <div className='flex flex-col space-y-4'>
                {fields?.map((field, index) =>
                    <TextBox loading={loading} key={index} label={field.label} value={field?.value || ""} />
                )}
            </div>
        </>
    )
}

export default BasicInfo;