import React from 'react'
import { CloseButton, PurpleButton } from '../../../../components/mui/button/Button'
import { ArrowBack, ArrowForward, } from '@mui/icons-material';
import useSteppers from '../../../../hooks/useSteppers';
import { useNavigate } from 'react-router-dom';

const BottomTile = ({ id, clearForm, steps }) => {
    const navigate = useNavigate();
    const { handleBackward, handleForward, isBackwardDisabled, isForwardDisabled } = useSteppers(steps);

    return (
        <div className='flex sm:flex-row flex-col justify-between gap-5 p-8 sm:p-14 bg-white border-b-2 border-x-2'>
            {id && <div className='flex justify-between sm:justify-start w-full sm:w-fit gap-5'>
                <PurpleButton disabled={isBackwardDisabled} onClick={handleBackward} text={<ArrowBack sx={{ color: 'white' }} />} />
                <PurpleButton disabled={isForwardDisabled} onClick={handleForward} text={<ArrowForward sx={{ color: 'white' }} />} />
            </div>}

            {id && <CloseButton variant='contained' size='large' text="Cancel" elevation={false} onClick={() => navigate(`/admin/employee/recruits/onlineVerify/${id}`)} />}

            {!id && <div className='flex flex-col sm:flex-row gap-5'>
                <CloseButton variant='contained' elevation={false} onClick={clearForm} />
                <PurpleButton type="submit" text="Save & Next" />
            </div>}
        </div>
    )
}

export default BottomTile;