import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DemoteIcon from '../../../../assets/icons/DemoteIcon'
import CustomTable from "../../../../components/mui/Table";
import HomeTile from "../../../../components/tiles/AdmissionTile";
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg'
import PromotePopup from "../../../../components/popups/localPopup/promotion/PromotePopup";
import TransferIcon from "../../../../assets/icons/TransferIcon";

const FacultyGrid = ({ facultyInfo, statsLoading }) => {

    return (
        <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(facultyInfo ? Object.keys(facultyInfo) : Array.from({ length: 6 })).map((faculty, index) => {
                return (
                    <HomeTile
                        key={faculty}
                        icon={ProfessorIcon}
                        color="#D6D0F7"
                        heading={faculty}
                        count={facultyInfo?.[faculty]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
    );
};

const CurrentFaculty = () => {
    const { data: facultyData, isLoading: facultyDataLoading, updateData } = useGetData('/api/employee/hr/currents/getCurrents')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/currents/stats/getDesignations');

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const { putData } = usePutData();
    const setLoading = useSetRecoilState(loadingState);

    const [promotePopup, setPromotePopup] = useState({ isOpen: false, _id: '' });

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/employee/currents/profile/" + value + '?type=Personal'));

    const handleDemote = (event, id) => setPromotePopup({ isOpen: true, _id: id });

    const onDemote = async (id, remark) => {
        setPromotePopup({ isOpen: false, _id: '' });
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/recruits/updateAdmissionTransfer/${id}`, { statusMessage: remark });
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Employee successfully Demoted!`, 'success');
    }

    const handleOffBoarding = (event, value) => openPopup('Transfer Employee', 'Are you sure you want to offboard the Employee?', 'edit', () => onOffBoarding(value));

    const onOffBoarding = async (id) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/currents/updateCurrentTransfer/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Employee successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Faculty Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'joiningDate', label: 'Joining Date', minWidth: 140, type: 'date' },
        { id: 'designation', label: 'Designation', minWidth: 180, type: 'text' },
        { id: 'departments', label: 'Department', minWidth: 180, type: 'text' },
        {
            id: 'demote', label: 'Transfer to Recruit', type: 'iconButton',
            align: 'center', onClick: handleDemote,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <DemoteIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'offboarding', label: 'Transfer to Former', type: 'iconButton',
            align: 'center', onClick: handleOffBoarding,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
    ];

    const sortOptions = [
        { label: "Total", value: "none" },
        { label: "Professors", value: "professors" },
        { label: "Associate Professor", value: "associate" },
        { label: "Assistant Professor", value: "assistant" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 rounded-lg drop-shadow-md min-h-svh'>
            <FacultyGrid facultyInfo={statsData} statsLoading={statsLoading} />
            <CustomTable
                heading="All Employees"
                text="Employees data"
                isLoading={facultyDataLoading}
                columns={columns} rows={facultyData}
                sortParameters={['name', 'designation', 'emailId', 'experience']}
                const sortOptions={sortOptions}
                handleRowClick={handleRow}
                activeOperations
            />

            {promotePopup?.isOpen && <PromotePopup id={promotePopup._id} heading='Demote Employee' subtext='Are you sure you want to demote the Employee?' onClose={() => setPromotePopup({ isOpen: false, _id: '' })} onConfirm={onDemote} />}
        </div>
    )
}

export default CurrentFaculty;