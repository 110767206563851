const DemoteIcon = ({className}) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M43.2615 9.71642C46.8202 14.5853 48.393 20.0031 47.9169 25.93C47.081 36.3379 39.8269 44.7211 29.9583 47.2486C18.0628 50.2952 5.71792 43.8278 1.50538 32.3667C-1.90816 23.0794 0.573963 12.6143 8.03325 6.15965C15.1504 0.00100775 23.3321 -1.5361 32.2654 1.53346C36.7532 3.07548 40.36 5.89955 43.2615 9.71642ZM10.3087 38.5782C15.1854 42.9714 20.8743 44.7688 27.361 43.6936C38.5216 41.8437 45.7927 31.1291 43.6262 20.2448C41.472 9.42205 31.5065 2.56206 20.6595 4.33862C11.3138 5.86928 4.11418 13.897 4.00243 23.8387C3.93791 29.5782 6.08108 34.491 10.3087 38.5782Z"/>
    <path d="M19.2947 17.6682C19.2937 15.5071 19.2895 13.4206 19.2939 11.3342C19.2961 10.2957 19.5869 10.0053 20.6331 10.003C22.8853 9.99826 25.1376 9.99909 27.3898 10.0057C28.4338 10.0088 28.7228 10.2961 28.7239 11.3415C28.7286 15.6139 28.7259 19.8862 28.7265 24.1586C28.7266 25.3691 28.7355 25.3777 29.9195 25.3808C30.5452 25.3825 31.1708 25.3875 31.7964 25.3816C32.2475 25.3773 32.6582 25.4651 32.876 25.9026C33.1133 26.3792 32.9929 26.8151 32.6605 27.2131C31.5109 28.5895 30.3684 29.9718 29.2164 31.3462C27.8243 33.0069 26.4263 34.6627 25.0289 36.3191C24.2783 37.2088 23.7697 37.2336 23.0258 36.3525C20.4705 33.3264 17.9291 30.2886 15.3889 27.2499C15.0755 26.8749 14.8657 26.4487 15.1006 25.9481C15.3409 25.4359 15.8051 25.3742 16.3034 25.3795C16.954 25.3865 17.6047 25.3836 18.2553 25.3806C19.2166 25.3762 19.2914 25.3129 19.2942 24.3749C19.3006 22.1642 19.2955 19.9535 19.2947 17.6682Z"/>
  </svg>
);

export default DemoteIcon;