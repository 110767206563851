import React from "react";
import SkeletonText from "./SkeltonText";

const TextBox = ({ label, value, size = 's', loading, lines }) => {

    return (
        <div className={`flex flex-col gap-0.5 xs:gap-1}`}>
            <p className={size === 'l' ? 'h5-p' : 'lb-p'}>{label}</p>
            <SkeletonText lines={lines} loading={loading} width={150} className={size === 'l' ? 'lb-s' : 'text-s'}>{value}</SkeletonText>
        </div>
    )
}

export default TextBox;