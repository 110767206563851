import React from "react";
import TextBox from '../../../../components/mui/text/TextBox';

const FinanceDetails = ({ financeData, loading }) => {

    const fields = [
        { label: 'Bank Name', value: financeData?.bankName },
        { label: 'Account Number', value: financeData?.accountNo },
        { label: 'IFSC', value: financeData?.ifsc },
        { label: 'Aadhar Card Number', value: financeData?.aadharCardNo },
        { label: 'Pan Card Number', value: financeData?.panCardNo }
    ]

    return (
        <div className='flex flex-col w-full gap-5'>
            <div className='flex items-center gap-4'>
                <p className='lb-s whitespace-nowrap'>Financial Details</p>
                <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                {fields?.map((field, index) => (
                    <TextBox key={index} label={field?.label} value={field?.value} loading={loading} />
                ))}
            </div>
        </div>
    )
}

export default FinanceDetails;