import { useState } from "react";

const HOST_URL = process.env.REACT_APP_HOST;

const useDeleteData = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteData = async (url, options={}) => {
        setIsLoading(true);
        setError(null);

        const token = sessionStorage.getItem('auth_token');

        try {
            const response = await fetch(HOST_URL + url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                ...options,
            });
            const result = await response.json();

            setError(result?.error);
            setData(result?.data);
            return { message: result?.message, success: result?.success, data: result?.data, error: result?.error };

        } catch (error) { setError(error?.message); }
        finally { setIsLoading(false); }
    }

    return { data, isLoading, error, deleteData };
};

export default useDeleteData;