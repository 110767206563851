const PromoteIcon = ({className}) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.73846 38.2836C1.17978 33.4147 -0.392985 27.9969 0.0830547 22.07C0.918997 11.6621 8.17308 3.27888 18.0417 0.751403C29.9372 -2.29522 42.2821 4.17218 46.4946 15.6333C49.9082 24.9206 47.426 35.3857 39.9667 41.8403C32.8496 47.999 24.6679 49.5361 15.7346 46.4665C11.2468 44.9245 7.63996 42.1004 4.73846 38.2836ZM37.6913 9.42177C32.8146 5.02861 27.1257 3.23122 20.639 4.30642C9.47839 6.15635 2.20733 16.8709 4.37379 27.7552C6.52799 38.578 16.4935 45.4379 27.3405 43.6614C36.6862 42.1307 43.8858 34.103 43.9976 24.1613C44.0621 18.4218 41.9189 13.509 37.6913 9.42177Z"/>
    <path d="M28.7053 30.3318C28.7063 32.4929 28.7105 34.5794 28.7061 36.6658C28.7039 37.7043 28.4131 37.9947 27.3669 37.997C25.1147 38.0017 22.8624 38.0009 20.6102 37.9943C19.5662 37.9912 19.2772 37.7039 19.2761 36.6585C19.2714 32.3861 19.2741 28.1138 19.2735 23.8414C19.2734 22.6309 19.2645 22.6223 18.0805 22.6192C17.4548 22.6175 16.8292 22.6125 16.2036 22.6184C15.7525 22.6227 15.3418 22.5349 15.124 22.0974C14.8867 21.6208 15.0071 21.1849 15.3395 20.7869C16.4891 19.4105 17.6316 18.0282 18.7836 16.6538C20.1757 14.9931 21.5737 13.3373 22.9711 11.6809C23.7217 10.7912 24.2303 10.7664 24.9742 11.6475C27.5295 14.6736 30.0709 17.7114 32.6111 20.7501C32.9245 21.1251 33.1343 21.5513 32.8994 22.0519C32.6591 22.5641 32.1949 22.6258 31.6966 22.6205C31.046 22.6135 30.3953 22.6164 29.7447 22.6194C28.7834 22.6238 28.7086 22.6871 28.7058 23.6251C28.6994 25.8358 28.7045 28.0465 28.7053 30.3318Z"/>
  </svg>
);

export default PromoteIcon;