import { useState } from "react";
import { Drawer, Skeleton, useMediaQuery } from "@mui/material";
import PdfIcon from '../../../../../assets/icons/documents/PdfIcon';
import DocxIcon from '../../../../../assets/icons/documents/DocxIcon';
import JpgIcon from '../../../../../assets/icons/documents/JpgIcon';
import SkeletonText from '../../../../../components/mui/text/SkeltonText';
import BlinkingBadge from "../../../../../components/mui/BlinkingBadge ";
import { CloseButton, ConfirmButton } from "../../../../../components/mui/button/Button";

const DocumentBar = ({ data, documentState, toggleDocument, handleDocumentAction, loading }) => {
    const largeScreen = useMediaQuery('(min-width:1024px)');
    const [toggleDrawer, setToggleDrawer] = useState(false)

    const documentIcons = {
        pdf: <PdfIcon className="h-8 w-8 fill-red-500" />,
        docx: <DocxIcon className="h-8 w-8 fill-blue-600" />,
        jpg: <JpgIcon className="h-8 w-8 fill-purple-700" />,
        png: <JpgIcon className="h-8 w-8 fill-purple-700" />,
        jpeg: <JpgIcon className="h-8 w-8 fill-purple-700" />
    };

    const loadingData = Array.from({ length: 10 }, () => (
        loading && <div className="flex flex-col items-center justify-center gap-1">
            <Skeleton width={30} height={30} variant="circular" />
            <SkeletonText lines={largeScreen ? 2 : 1} className="w-full text-s text-xs line-clamp-1 lg:line-clamp-2" loading={loading} />
        </div>
    ));

    const documentOpen = documentState?.isOpen;
    const documentKey = documentState?.key;
    const documentURL = documentState?.url;

    const getBadgeColor = (data, currentDocumentType, document) => {
        if (document?.key === documentKey) return '#5D45DB' // Current Document
        const targetDocument = data[currentDocumentType]?.find(doc => doc.key === document?.key);
        return targetDocument?.onlineStatus === 'Verified' ? '#16A34A' :
            targetDocument?.onlineStatus === 'Rejected' ? '#ef4444' : '#facc15';
    };

    const openDrawer = async (key) => {
        await toggleDocument(key);
        !largeScreen && setToggleDrawer(true);
    }

    const closeDrawer = (key) => {
        toggleDocument(key);
        !largeScreen && setToggleDrawer(false);
    }

    return (
        <div className={`overflow-y-scroll no-scrollbar h-20 lg:h-[calc(100svh-5rem)] transition-transform delay-500 duration-500 z-20 col-span-full ${documentOpen ? "lg:col-span-8 grid grid-cols-8" : "lg:col-span-1 border-t lg:drop-shadow-lg bg-white"}`}>

            {(documentOpen && largeScreen) ?
                <div className='lg:col-span-7 flex flex-col h-full w-full transition-transform delay-500 duration-500 border-l'>

                    <div className="flex flex-col sm:flex-row gap-5 w-full p-5">
                        <ConfirmButton onClick={() => handleDocumentAction('Verified')} fullWidth text="Verify Document" />
                        <CloseButton variant="contained" onClick={() => handleDocumentAction('Rejected')} fullWidth text="Reject Document" />
                    </div>

                    <object className='h-[70svh]' data={documentURL} type="application/pdf" width="100%" height="100%">
                        <p class="text-center p-10 text-gray-700">
                            Unable to display PDF.&nbsp;
                            <a href={documentURL} class="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Please click here to view the document.</a>
                        </p>
                    </object>
                </div>
                :
                <Drawer anchor='bottom' open={toggleDrawer} onClose={() => closeDrawer(false)}>
                    <div className='flex flex-col'>
                        <object className='' data={documentURL} type="application/pdf" width="100%" height="100%">
                            <p class="text-center text-gray-700">
                                Unable to display PDF.&nbsp;
                                <a href={documentURL} class="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Please click here to view the document.</a>
                            </p>
                        </object>
                        <div className="flex gap-5 w-full p-5">
                            <ConfirmButton onClick={() => handleDocumentAction('Verified')} fullWidth text="Verify" />
                            <CloseButton variant="contained" onClick={() => handleDocumentAction('Rejected')} fullWidth text="Reject" />
                        </div>
                    </div>
                </Drawer>
            }

            <div className="col-span-full lg:col-span-1 flex flex-row lg:flex-col gap-8 p-3 overflow-y-scroll no-scrollbar h-full drop-shadow-lg bg-white lg:py-5">
                {loadingData}
                {data?.commonDocuments?.map((document, index) => <BlinkingBadge active={documentKey === document?.key} badgeColor={getBadgeColor(data, 'commonDocuments', document)} key={index}>
                    <button onClick={() => openDrawer(document?.key)} className="flex flex-col items-center justify-center gap-1 w-full">
                        {documentIcons[document?.key?.split('.')?.pop()]}
                        <p className="w-full text-s whitespace-nowrap lg:whitespace-normal lg:line-clamp-2">
                            {document?.title}
                        </p>
                    </button>
                </BlinkingBadge>
                )}

                {data?.academicDocuments?.length > 0 && <>
                    <hr className="h-full lg:h-fit border-[1px] bg-slate-400" />
                    {data?.academicDocuments?.map((document, index) => <BlinkingBadge active={documentKey === document?.key} badgeColor={getBadgeColor(data, 'academicDocuments', document)} key={index}>
                        <button onClick={() => openDrawer(document?.key)} className="flex flex-col items-center justify-center gap-1 w-full">
                            {documentIcons[document?.key?.split('.')?.pop()]}
                            <p className="w-full text-s whitespace-nowrap lg:whitespace-normal lg:line-clamp-2">
                                {document?.title}
                            </p>
                        </button>
                    </BlinkingBadge>)}
                </>}

                {data?.categoryDocuments?.length > 0 && <>
                    <hr className="h-full lg:h-fit border-[1px] bg-slate-400" />
                    {data?.categoryDocuments?.map((document, index) => <BlinkingBadge active={documentKey === document?.key} badgeColor={getBadgeColor(data, 'categoryDocuments', document)} key={index}>
                        <button onClick={() => openDrawer(document?.key)} className="flex flex-col items-center justify-center gap-1 w-full">
                            {documentIcons[document?.key?.split('.')?.pop()]}
                            <p className="w-full text-s whitespace-nowrap lg:whitespace-normal lg:line-clamp-2">
                                {document?.title}
                            </p>
                        </button>
                    </BlinkingBadge>)}
                </>}
            </div>
        </div>
    );
};

export default DocumentBar;