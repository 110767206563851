import React from 'react'
import Lottie from 'lottie-react'
import { PurpleButton } from '../../../components/mui/button/Button'
import { useNavigate } from 'react-router-dom'

import UpdateInfo from '../../../assets/lotties/UpdateInfo.json'
import ChangePassword from '../../../assets/lotties/ChangePassword.json'
import SocialMedia from '../../../assets/lotties/SocialMedia.json'

const Setting = () => {
    const navigate = useNavigate();
    const settingsTileData = [
        {
            lottie: UpdateInfo,
            text: 'Update Info',
            navigateTo: '/admin/settings/updateInfo?type=Personal'
        },
        {
            lottie: ChangePassword,
            text: 'Change Password',
            navigateTo: '/admin/settings/changePassword'
        },
        {
            lottie: SocialMedia,
            text: 'Manage Social Links',
            navigateTo: '/admin/settings/socialLinks'
        }
    ]

    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 w-full bg-white p-5 min-h-svh'>
            {settingsTileData.map((tile, index) => (
                <div className='flex flex-col w-full space-y-3' onClick={() => { navigate(tile.navigateTo) }}>
                    <div className='flex items-center justify-center w-full rounded-md bg-secondary drop-shadow p-5 cursor-pointer' >
                        <Lottie className='h-64 w-64' animationData={tile.lottie} loop={true} />
                    </div>
                    <PurpleButton text={tile.text} fullWidth />
                </div>
            ))}
        </div>
    )
}

export default Setting;