import { atom } from "recoil";
export const formState = atom({
    key: 'formState',
    default: {},
});

export const snackbarState = atom({
    key: 'snackbarState',
    default: {
        isSnackbarOpen: false,
        snackbarMessage: '',
        snackbarType: 'info',
        snackbarIcon: undefined,
        vertical: '',
        horizontal: ''
    },
});

export const popupState = atom({
    key: 'popupState',
    default: {
        open: false,
        icon: '',
        heading: '',
        message: '',
        onConfirm: () => { },
        onClose: () => { },
    },
});

export const viewPopupState = atom({
    key: 'viewPopupState',
    default: {
        open: false,
        icon: '',
        heading: '',
        message: '',
        onClose: () => { },
    },
});

export const loadingState = atom({
    key: 'loadingState',
    default: false,
});

export const loggedInState = atom({
    key: 'loggedInState',
    default: null,
});

export const userState = atom({
    key: 'userState',
    default: {},
});

export const profilePhotoState = atom({
    key: 'profilePhotoState',
    default: ''
});

export const breadcrumbState = atom({
    key: 'breadcrumbState',
    default: {
        firstCrumb: {
            name: '',
            path: ''
        },
        secondCrumb: {
            name: '',
            path: ''
        },
        thirdCrumb: {
            name: '',
            path: ''
        }
    }
});
