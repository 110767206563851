import React, { useState } from "react";
import renderFormField from '../../../../../utils/renderFormField';
import { CustomTextField, CustomAsyncSelect } from '../../../../../components/mui/Input'
import { maritalStatus } from "../../../../../constants/personal";

const FamilyInfo = ({ formData, handleNestedChange, isLoading }) => {

    const [partner, setPartner] = useState(formData?.familyInfo?.partnerDetail !== undefined)
    const handleMaritalStatusChange = (event) => {
        if(event.target.value === 'Married') {
            setPartner(true);
        } else {
            setPartner(false);
        }
        handleNestedChange(event);
    }

    const familyPropertyFields = [
        { type: 'input', size: 1, label: "Middle Name", name: 'middleName' },
        { type: 'input', size: 1, label: "Last Name", name: 'lastName' },
        { type: 'input', size: 1, label: "Income", name: "income", customType: "number" },
        { type: 'input', size: 1, label: "Education", name: "education" },
        { type: 'input', size: 1, label: "Profession", name: "profession" },
    ];

    const childrenProperties = [
        { type: 'space' },
        { type: 'input', size: 1, label: "First Name", name: "firstName" },
        { type: 'input', size: 1, label: "Middle Name", name: "middleName" },
        { type: 'input', size: 1, label: "Last Name", name: "lastName" },
        { type: 'date', size: 1, label: "DOB", name: "dob" },
        { type: 'input', size: 1, label: "Education", name: "education" }
    ];
    // { type: 'select', size: 1, label: "Marital Status", value: formData?.additionalInfo?.maritalStatus, name: "formData.additionalInfo.maritalStatus", options: maritalStatusOptions.map(option => ({ value: option, label: option })) },
    return (
        <div class="flex flex-col w-full gap-8">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <CustomAsyncSelect
                    className='bg-white' label="Marital Status"
                    options={maritalStatus?.map(option => ({ value: option, label: option }))} onChange={handleMaritalStatusChange}
                    name='formData.additionalInfo.maritalStatus' value={formData?.additionalInfo?.maritalStatus}
                />
                <h5 className="col-span-full h4-p">Father Details</h5>
                {!isLoading ? <div className="flex w-full col-span-full md:col-span-1">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange}
                        name='formData.familyInfo.fatherDetail.title' value={formData?.familyInfo?.fatherDetail?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                    <CustomTextField
                        label="First Name" name='formData.familyInfo.fatherDetail.firstName' value={formData?.familyInfo?.fatherDetail?.firstName} onChange={handleNestedChange}
                        fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
                    />
                </div> : <div className="animate-pulse h-14 w-full rounded-md bg-muiLight" />}

                {familyPropertyFields?.map((field, index) => renderFormField({
                    type: field?.type,
                    size: field?.size,
                    label: field?.label,
                    name: `formData.familyInfo.fatherDetail.${field?.name}`,
                    value: formData?.familyInfo?.fatherDetail?.[field.name],
                    onChange: handleNestedChange,
                    customType: field?.customType
                }, index, isLoading))}
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <h5 className="col-span-full h4-p">Mother Details</h5>
                {!isLoading ? <div className="flex w-full col-span-full md:col-span-1">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange}
                        name='formData.familyInfo.motherDetail.title' value={formData?.familyInfo?.motherDetail?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                    <CustomTextField
                        label="First Name" name='formData.familyInfo.motherDetail.firstName' value={formData?.familyInfo?.motherDetail?.firstName} onChange={handleNestedChange}
                        fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
                    />
                </div> : <div className="animate-pulse h-14 w-full rounded-md bg-muiLight" />}

                {familyPropertyFields?.map((field, index) => renderFormField({
                    type: field?.type,
                    size: field?.size,
                    label: field?.label,
                    name: `formData.familyInfo.motherDetail.${field?.name}`,
                    value: formData?.familyInfo?.motherDetail?.[field.name],
                    onChange: handleNestedChange,
                    customType: field?.customType
                }, index, isLoading))}
            </div>

            {partner && <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <h5 className="col-span-full h4-p">Partner Details</h5>
                {!isLoading ? <div className="flex w-full col-span-full md:col-span-1">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange}
                        name='formData.familyInfo.partnerDetail.title' value={formData?.familyInfo?.partnerDetail?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                    <CustomTextField
                        label="First Name" name='formData.familyInfo.partnerDetail.firstName' value={formData?.familyInfo?.partnerDetail?.firstName} onChange={handleNestedChange}
                        fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
                    />
                </div> : <div className="animate-pulse h-14 w-full rounded-md bg-muiLight" />}

                {familyPropertyFields?.map((field, index) => renderFormField({
                    type: field?.type,
                    size: field?.size,
                    label: field?.label,
                    name: `formData.familyInfo.partnerDetail.${field?.name}`,
                    value: formData?.familyInfo?.partnerDetail?.[field.name],
                    onChange: handleNestedChange,
                    customType: field?.customType
                }, index, isLoading))}
            </div>}

            {formData?.familyInfo?.children && <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <h5 className="col-span-full h4-p">Children Details</h5>
                {
                    formData?.familyInfo?.children?.map((_, i) => (
                        childrenProperties?.map((property, index) => ((property?.type !== 'space' || i !== 0) && renderFormField({
                            type: property?.type,
                            size: property?.size,
                            label: property?.label,
                            name: `formData.familyInfo.children.${i}.${property?.name}`,
                            value: formData?.familyInfo?.children?.[i]?.[property?.name],
                            onChange: handleNestedChange,
                            customType: property?.customType
                        }, index, isLoading)))))
                }
            </div>}
        </div>
    )
}

export default FamilyInfo;