import React from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";

import TransferIcon from '../../../../assets/icons/TransferIcon'
import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import CustomTable from "../../../../components/mui/Table";
import HomeTile from "../../../../components/tiles/AdmissionTile";

const StudentGrid = ({ studentInfo, statsLoading }) => {
    return (
        <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(studentInfo ? Object.keys(studentInfo) : Array.from({ length: 6 })).map((student, index) => {
                return (
                    <HomeTile
                        key={student}
                        icon={StudentIcon}
                        color="#D6D0F7"
                        heading={student}
                        count={studentInfo?.[student]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
    );
};

const AlumniStudents = () => {
    const { data: enrollData, isLoading: enrollDataLoading, updateData } = useGetData('/api/employee/admission/alumnis/getAlumnis');
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/alumnis/stats/getStudentsProgramsCount');

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { putData } = usePutData();

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/alumnis/profile/" + value + '?type=Personal'));

    const handleReEnrollment = (event, value) => openPopup('Transfer Student', 'Are you sure you want to reEnroll the Alumni?', 'edit', () => onReEnrollment(value));

    const onReEnrollment = async (id) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledTransfer/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Student Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 180, type: 'text' },
        { id: 'graduationYear', label: 'Graduation Year', minWidth: 180, type: 'text' },
        {
            id: 'reScholar', label: 'Transfer to Enrolled', type: 'iconButton',
            align: 'center', onClick: handleReEnrollment,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
    ];

    const sortOptions = [
        { label: "Total", value: "none" },
        // { label: "Professors", value: "professors" },
        // { label: "Associate Professor", value: "associate" },
        // { label: "Assistant Professor", value: "assistant" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 rounded-lg drop-shadow-md min-h-svh'>
            <StudentGrid studentInfo={statsData} statsLoading={statsLoading} />
            <CustomTable
                heading="All Alumni Students"
                text="Alumni students data"
                isLoading={enrollDataLoading}
                columns={columns} rows={enrollData}
                sortParameters={['name', 'emailId', 'admissionYear', 'graduationYear']}
                const sortOptions={sortOptions}
                handleRowClick={handleRow}
                activeOperations
            />
        </div>
    )
}

export default AlumniStudents;