import React, { useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { ChangeCircle } from '@mui/icons-material';
import { loadingState } from "../../../../../recoil/state";

import useSnackbar from "../../../../../hooks/useSnackbar";
import usePostData from "../../../../../hooks/usePostData";
import useFormOperations from "../../../../../hooks/useFormOperations";

import { CustomAsyncSelect, CustomTextField } from "../../../../../components/mui/Input";
import { ConfirmButton, PurpleButton } from "../../../../../components/mui/button/Button";

const ContactInfo = ({ defaultContactInfo, isLoading }) => {
    const [, setLoading] = useRecoilState(loadingState);
    const { postData } = usePostData();

    const [identity, setIdentity] = useState(null);
    const [contact, setContact] = useState(null);
    const verifyOtpButtonRef = useRef(null);
    const [otp, setOtp] = useState('')
    const [infoSubmitted, setInfoSubmitted] = useState(false);

    const defaultValues = { otpType: 'Login' };
    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);
    const { handleSnackbarOpen } = useSnackbar();

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, message } = await postData('/api/otp/generateAdmissionLoginOTP', formData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP to your Email ID!", 'error')

        handleSnackbarOpen("OTP sent to your Email ID!", 'success')
        setInfoSubmitted(true)
    }

    const { formData, handleNestedChange, handleSubmit, clearForm } = useFormOperations({}, onSubmit);

    const selectedInfoLabel = () => {
        if (contact === 'emailId' || contact === 'personalEmailId') {
            return 'Email ID';
        } else if (contact === 'phNo') {
            return 'Phone Number';
        } else if (contact === 'alternatePhNo') {
            return 'Alternate Phone Number';
        } else {
            return "";
        }
    }

    const selectedInfoValue = (data) => {
        if (identity && contact) {
            const identityParts = identity.split('.');
            for (const part of identityParts) {
                data = data?.[part];
            }
            return data?.[contact] ?? '';
        }
        return '';
    }

    const toggleInfoSubmitted = () => setInfoSubmitted(!infoSubmitted)
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (verifyOtpButtonRef.current) verifyOtpButtonRef.current.click();
        }
    };

    const onOTPVerify = async () => {
        // const OTPFormData = { email: formData?.email, otp, type: activeTab }
        // setLoading(true);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // const { success, message, data } = await usePostData('/api/otp/verifyAdmissionLoginOTP', OTPFormData)
        // setLoading(false);
        // if (message) return handleSnackbarOpen(message, 'error');
        // if (!success) return handleSnackbarOpen("Opps.. Wrong OTP!", 'error');

        // const { authToken, submitted } = data;
        // sessionStorage.setItem('auth_token', authToken);
        // sessionStorage.setItem('user_type', 'admissionUser');
        // setEmailSubmitted(true);
        // handleSnackbarOpen("OTP Verified successfully!", 'success');

        // if (submitted) return navigate('/admissions/profile');
        // navigate('/admissions/form?step=Personal+Details');
    }

    const identityInfo = defaultContactInfo?.familyInfo?.partnerDetail ? [
        { value: 'personalInfo', label: 'Yourself' },
        { value: 'familyInfo.fatherDetail', label: 'Father' },
        { value: 'familyInfo.motherDetail', label: 'Mother' },
        { value: 'familyInfo.partnerDetail', label: 'Partner' },
    ] : [{ value: 'personalInfo', label: 'Yourself' },
    { value: 'familyInfo.fatherDetail', label: 'Father' },
    { value: 'familyInfo.motherDetail', label: 'Mother' }]

    const contactInfo = identity === 'personalInfo' ? [
        { value: 'personalEmailId', label: 'Email ID' },
        { value: 'phNo', label: 'Phone Number' },
        { value: 'alternatePhNo', label: 'Alternate Phone Number' },
    ] : [
        { value: 'emailId', label: 'Email ID' },
        { value: 'phNo', label: 'Phone Number' },
        { value: 'alternatePhNo', label: 'Alternate Phone Number' },
    ]

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            <div className="flex flex-col gap-5 max-w-xl">
                {!isLoading ? <CustomAsyncSelect
                    label="Select Your identity"
                    value={identity}
                    onChange={(e) => setIdentity(e.target.value)}
                    options={identityInfo}
                /> : <div className="animate-pulse col-span-full h-14 w-full rounded-md bg-muiLight" />}
                {!isLoading ? <CustomAsyncSelect
                    label={!identity ? "Select the identity Info first" : "Select your contact Info"}
                    value={contact}
                    disabled={!identity}
                    onChange={(e) => setContact(e.target.value)}
                    options={contactInfo}
                /> : <div className="animate-pulse col-span-full h-14 w-full rounded-md bg-muiLight" />}
            </div>

            <form className="flex flex-col gap-5 max-w-md self-end" onSubmit={handleSubmit}>
                <CustomTextField
                    name={'formData.' + identity + '.' + contact} label={(identity && contact) ? selectedInfoLabel() : "Select the identity and contact info first"}
                    value={selectedInfoValue(formData)} onChange={handleNestedChange} disabled={(!identity || !contact) || infoSubmitted}
                    endIcon={infoSubmitted && <ChangeCircle className="text-light hover:text-purple cursor-pointer" onClick={toggleInfoSubmitted} />}
                />

                {infoSubmitted ?
                    <div className="flex flex-col space-y-2 h-20">
                        <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 12 } }} onKeyDown={handleKeyDown} />
                        <p className="flex space-x-2">
                            <span className="text-light">Didn't receive the code?</span>
                            <button type="submit" className="text-purple hover:underline ">Resend it.</button>
                        </p>
                    </div>
                    :
                    <p className="h-20 grid place-items-center border-dashed border-2 w-full p-3">
                        <span className=" text-s leading-6">
                            Want to update your data? <br />
                            Change the Information to update.
                        </span>
                    </p>
                }
                {infoSubmitted ? <PurpleButton disabled={otp.length < 6} fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} ref={verifyOtpButtonRef} />
                    : <ConfirmButton fullWidth disabledElevation={true} type="submit" text="Generate OTP" />
                }
            </form>
        </div>
    )
}

export default ContactInfo