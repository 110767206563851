import React from "react";
import TextBox from '../../../components/mui/text/TextBox';
import { dateFormat } from "../../../utils/dateFormat";

const EducationDetails = ({ educationData = {}, loading = false }) => {
    const sectionsMap = {
        elementaryDetail: {
            fields: [
                { label: "Exam Year", value: (data) => data?.examYear },
                { label: "School Name", value: (data) => data?.schoolName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "Total Marks", value: (data) => data?.totalMarks },
                { label: "Obtained Marks", value: (data) => data?.obtainedMarks },
                { label: "Grade", value: (data) => data?.grade },
                { label: "Percentage", value: (data) => data?.percentage },
                { label: "CGPA", value: (data) => data?.cgpa },
            ],
            label: 'Elementary Details'
        },
        diplomaDetails: {
            fields: [
                { label: "Degree", value: (data) => data?.degree },
                { label: "Passing Year", value: (data) => data?.passingYear },
                { label: "Major", value: (data) => data?.major },
                { label: "College Name", value: (data) => data?.collegeName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "CGPA", value: (data) => data?.cgpa },
                { label: "Percentage", value: (data) => data?.percentage },
                { label: "Grade", value: (data) => data?.grade },
            ],
            label: 'Diploma Details'
        },
        intermediateDetail: {
            fields: [
                { label: "Exam Year", value: (data) => data?.examYear },
                { label: "School Name", value: (data) => data?.schoolName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "Total Marks", value: (data) => data?.totalMarks },
                { label: "Obtained Marks", value: (data) => data?.obtainedMarks },
                { label: "Grade", value: (data) => data?.grade },
                { label: "Percentage", value: (data) => data?.percentage },
                { label: "CGPA", value: (data) => data?.cgpa },
            ],
            label: 'Intermediate Details'
        },
        ugDetails: {
            fields: [
                { label: "Degree", value: (data) => data?.degree },
                { label: "Passing Year", value: (data) => data?.passingYear },
                { label: "Major", value: (data) => data?.major },
                { label: "College Name", value: (data) => data?.collegeName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "CGPA", value: (data) => data?.cgpa },
                { label: "Percentage", value: (data) => data?.percentage },
                { label: "Grade", value: (data) => data?.grade },
            ],
            label: 'Under Graduation Details'
        },
        pgDetails: {
            fields: [
                { label: "Degree", value: (data) => data?.degree },
                { label: "Passing Year", value: (data) => data?.passingYear },
                { label: "Major", value: (data) => data?.major },
                { label: "College Name", value: (data) => data?.collegeName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "CGPA", value: (data) => data?.cgpa },
                { label: "Percentage", value: (data) => data?.percentage },
                { label: "Grade", value: (data) => data?.grade },
            ],
            label: 'Post Graduation Details'
        },
        doctorateDetails: {
            fields: [
                { label: "Degree", value: (data) => data?.degree },
                { label: "Dissertation Title", value: (data) => data?.dissertationTitle },
                { label: "Supervisor", value: (data) => data?.supervisor },
                { label: "Completion Year", value: (data) => data?.completionYear },
                { label: "University Name", value: (data) => data?.universityName },
                { label: "City", value: (data) => data?.city },
                { label: "State", value: (data) => data?.state },
                { label: "Country", value: (data) => data?.country },
                { label: "CGPA", value: (data) => data?.cgpa },
            ],
            label: 'PhD Details'
        },
        certificationDetails: {
            fields: [
                { label: "Certification", value: (data) => data?.certification },
                { label: "Issuing Organization", value: (data) => data?.issuingOrganization },
                { label: "Issue Date", value: (data) => dateFormat(data?.issueDate) },
                { label: "Expiry Date", value: (data) => dateFormat(data?.expiryDate) },
                { label: "Certificate ID", value: (data) => data?.certificateID },
                { label: "Certificate URL", value: (data) => <a href={data?.certificateUrl} target="_blank" rel="noopener noreferrer">{data?.certificateUrl}</a> },
                { label: "License", value: (data) => data?.license },
                { label: "Score", value: (data) => data?.score },
            ],
            label: 'Certification Details'
        }
    };

    const renderSection = (sectionKey, last) => {
        const section = educationData?.[sectionKey];
        const sectionConfig = sectionsMap?.[sectionKey];
        // if (!section || !sectionConfig) return null;

        const dataArray = Array?.isArray(section) ? section : [section];

        return dataArray.map((data, index) => (
            <div className="flex flex-col gap-5" key={index}>
                <div className='flex items-center gap-4'>
                    <h6 className='lb-s whitespace-nowrap'>{sectionConfig?.label}</h6>
                    <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1">
                    {sectionConfig?.fields?.map((field, i) => (
                        <TextBox key={i} label={field?.label} value={field?.value(data)} loading={loading} />
                    ))}
                </div>
                {!last ? <hr className="w-full bg-slate-400 border-[1px]" /> : null}
            </div>
        ));
    };

    return (
        <div className="flex flex-col gap-8">
            {Object.keys(sectionsMap).map((section, index) => (
                // TODO: CONDITION FOR LAST OPTION SO DON'T DISPLAY LINE 
                <React.Fragment key={index}>
                    {renderSection(section, false)}
                </React.Fragment>
            ))}
        </div>
    );
};

export default EducationDetails;