import React, { useState } from 'react'
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/state";

import useFormOperations from '../../../hooks/useFormOperations';
import useSnackbar from '../../../hooks/useSnackbar';
import usePutData from '../../../hooks/usePutData';

import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import ChangePasswordImg from '../../../assets/images/ChangePassword.webp'
import { CustomTextField } from '../../../components/mui/Input'
import { PurpleButton } from '../../../components/mui/button/Button';

const ChangePassword = () => {

    const { putData } = usePutData();
    const setLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();
    const [showPassword, setShowPassword] = useState({ oldPassword: false, newPassword: false, confirmPassword: false });

    const handleClickShowPassword = (name) => {
        setShowPassword((prevData) => ({ ...prevData, [name]: !showPassword[name] }))
    }

    const onSubmit = async () => {
        if (formData.confirmPassword !== undefined && formData.newPassword !== formData.confirmPassword)
            return handleSnackbarOpen("Confirm Password does not match your new password", 'error');
        setLoading(true);
        const { success, message } = await putData("/api/employee/personal/updatePassword", { oldPassword: formData.oldPassword, newPassword: formData.newPassword });
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
        handleSnackbarOpen('Password Changed Successfully', 'success')
        clearForm();
        setShowPassword({ oldPassword: false, newPassword: false, confirmPassword: false });
    }

    const { formData, handleSubmit, handleChange, clearForm } = useFormOperations({}, onSubmit);

    return (
        <div className='grid grid-cols-1 lg:grid-cols-20 w-full gap-5 bg-white p-5'>
            <div className="hidden lg:col-span-9 lg:flex flex-col items-center justify-center px-4 py-8 text-center bg-white drop-shadow rounded-lg gap-2">
                <img className='h-56' src={ChangePasswordImg} alt="Change Password" />
                <h5 className='h4-p'>Upgrade your security!  <span className='text-red-500'>Change your password</span>, secure your account.</h5>
            </div>

            <div className='col-span-1 lg:col-span-11 flex flex-col items-center justify-center px-4 py-8 sm:p-8 bg-white drop-shadow rounded-lg'>
                <form className='flex flex-col w-full max-w-xl gap-2' onSubmit={handleSubmit}>
                    <div className='grid grid-cols-12 items-center gap-5'>
                        <h5 className='col-span-full xs:col-span-4 hidden xs:block text-p'>Old Password</h5>
                        <CustomTextField
                            className='col-span-full xs:col-span-8 w-full'
                            label="Old Password"
                            name="oldPassword"
                            value={formData.oldPassword ?? ""}
                            type={showPassword.oldPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('oldPassword')} edge="end"
                                >
                                    {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                        />
                        <h5 className='col-span-full xs:col-span-4 hidden xs:block text-p'>New Password</h5>
                        <CustomTextField
                            className='col-span-full xs:col-span-8 w-full'
                            label="New Password"
                            name="newPassword"
                            value={formData.newPassword ?? ""}
                            type={showPassword.newPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('newPassword')} edge="end"
                                >
                                    {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                        />
                        <h5 className='col-span-full xs:col-span-4 hidden xs:block text-p mb-4'>Confirm Password</h5>
                        <CustomTextField
                            className='col-span-full xs:col-span-8 w-full'
                            label="Confirm Password"
                            name="confirmPassword"
                            value={formData.confirmPassword ?? ""}
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('confirmPassword')} edge="end"
                                >
                                    {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                            error={formData.confirmPassword !== undefined && formData.confirmPassword !== formData.newPassword}
                            helperText={(formData.confirmPassword !== undefined && formData.confirmPassword !== formData.newPassword) ? 'Confirm Password does not match your new password.' : " "}
                        />
                    </div>
                    <PurpleButton type='submit' text='Set Password' fullWidth disabled={!formData.oldPassword || !formData.newPassword || !formData.confirmPassword} />
                </form>
            </div>
        </div>
    )
}

export default ChangePassword;