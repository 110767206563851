import React from "react";
import SkeletonText from "../mui/text/SkeltonText";

const HomeTile = ({ icon, color, heading, count, spanClass, isLoading }) => {
    return (
        <div className={`grid grid-cols-16 w-full drop-shadow bg-white rounded-md ${spanClass}`}>
            <div className="col-span-4 flex items-center justify-center p-3 rounded-l-md" style={{ "backgroundColor": color }}>
                <img className="h-12" src={icon} alt="IMG" />
            </div>
            <div className="col-span-12 flex flex-col py-4 px-6">
                <SkeletonText loading={isLoading} height={21} width={100}><p className="h5-p">{heading}</p></SkeletonText>
                <SkeletonText loading={isLoading} height={27} width={100}><span className="h4-s">{count}</span></SkeletonText>
            </div>
        </div>
    )
}

export default HomeTile;