import React from "react";
import { dateFormat } from "../../../../utils/dateFormat";
import TextBox from '../../../../components/mui/text/TextBox';

const PersonalDetails = ({ data, loading }) => {

    const fields = [
        { label: 'Alternate Phone No', value: data?.alternatePhNo },
        { label: 'Alternate Email ID', value: data?.alternateEmailId },
        { label: 'Date of Birth', value: dateFormat(data?.personalInfo?.dob) },
        { label: 'Nationality', value: data?.identityInfo?.nationality },
        { label: 'Religion', value: data?.identityInfo?.religion },
        { label: 'Caste', value: data?.identityInfo?.caste },
        { label: 'Sub-Caste', value: data?.identityInfo?.subCaste },
        { label: 'Category', value: data?.identityInfo?.category },
        { label: 'Mother Tongue', value: data?.additionalInfo?.motherTongue },
        { label: 'Disability', value: data?.additionalInfo?.disability || 'N/A' },
        { label: 'Marital Status', value: data?.additionalInfo?.maritalStatus },
    ]

    return (
        <div className='flex flex-col w-full space-y-5'>
            <div className='flex items-center gap-4'>
                <p className='lb-s whitespace-nowrap'>Personal Details</p>
                <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                {fields?.map((field, index) => <TextBox key={index} label={field?.label} value={field?.value} loading={loading} />
                )}
            </div>
        </div>
    )
}

export default PersonalDetails;