import React from 'react'
import renderFormField from '../../../../../utils/renderFormField';
import { generateGeoOptions } from '../../../../../utils/geoLocationOptions';

const AddressInfo = ({ formData, handleNestedChange, isLoading }) => {

  const { countryOptions, stateOptions: permanentStateOptions, cityOptions: permanentCityOptions } = generateGeoOptions(formData?.personalInfo?.permanentAddress?.country, formData?.personalInfo?.permanentAddress?.state);
  const { stateOptions: residentialStateOptions, cityOptions: residentialCityOptions } = generateGeoOptions(formData?.personalInfo?.residentialAddress?.country, formData?.personalInfo?.residentialAddress?.state);

  const addressDetailsFields = [
    { type: 'input', size: 'full', label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address, name: "formData.personalInfo.residentialAddress.address" },
    { type: 'select', size: 1, label: "Country", value: formData?.personalInfo?.residentialAddress?.country, name: "formData.personalInfo.residentialAddress.country", options: countryOptions },
    { type: 'select', size: 1, label: "State", value: formData?.personalInfo?.residentialAddress?.state, name: "formData.personalInfo.residentialAddress.state", options: residentialStateOptions },
    { type: 'select', size: 1, label: "City", value: formData?.personalInfo?.residentialAddress?.city, name: "formData.personalInfo.residentialAddress.city", options: residentialCityOptions },
    { type: 'input', label: "Town", value: formData?.personalInfo?.residentialAddress?.town, name: "formData.personalInfo.residentialAddress.town" },
    { type: 'input', label: formData?.personalInfo?.residentialAddress?.country === 'India' ? 'Pin Code' : 'Zip Code', value: formData?.personalInfo?.residentialAddress?.pincode, name: "formData.personalInfo.residentialAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } } },
    { type: 'space' },
    { type: 'input', size: 'full', label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address, name: "formData.personalInfo.permanentAddress.address" },
    { type: 'select', size: 1, label: "Country", value: formData?.personalInfo?.permanentAddress?.country, name: "formData.personalInfo.permanentAddress.country", options: countryOptions },
    { type: 'select', size: 1, label: "State", value: formData?.personalInfo?.permanentAddress?.state, name: "formData.personalInfo.permanentAddress.state", options: permanentStateOptions },
    { type: 'select', size: 1, label: "City", value: formData?.personalInfo?.permanentAddress?.city, name: "formData.personalInfo.permanentAddress.city", options: permanentCityOptions },
    { type: 'input', label: "Town", value: formData?.personalInfo?.permanentAddress?.town, name: "formData.personalInfo.permanentAddress.town" },
    { type: 'input', label: formData?.personalInfo?.permanentAddress?.country === 'India' ? 'Pin Code' : 'Zip Code', value: formData?.personalInfo?.permanentAddress?.pincode, name: "formData.personalInfo.permanentAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } } },
  ]

  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {addressDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
    </div>
  )
}

export default AddressInfo;