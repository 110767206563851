import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
*/
const useOutsideClick = (ref, callback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target))
            callback();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);

    }, [handleClickOutside]);
};

export default useOutsideClick;
