import React from 'react';
import { Button } from '@mui/material';
import BlackToolTip from '../BlackToolTip';

export const SquareIconButton = ({ title, onClick, icon, className, sx, ...props }) => (
    <BlackToolTip arrow title={title}>
        <Button disableRipple disableElevation onClick={onClick} variant="text" size="large" className={` ${className}`} sx={sx} {...props}>
            {icon}
        </Button>
    </BlackToolTip>
);