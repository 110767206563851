import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { TypeAnimation } from "react-type-animation";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Person, Person3, ChangeCircle, Email } from '@mui/icons-material';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import { loadingState, loggedInState, userState } from "../../../recoil/state";

import useFormOperations from "../../../hooks/useFormOperations";
import usePostData from "../../../hooks/usePostData";
import useSnackbar from "../../../hooks/useSnackbar";
import useGetData from "../../../hooks/useGetData";

import { CustomTextField } from "../../../components/mui/Input";
import { PurpleButton } from "../../../components/mui/button/Button";
import BoxTabSmall from "../../../components/mui/tab/BoxTabSmall";
import BlackToolTip from "../../../components/mui/BlackToolTip";
import google from "../../../assets/logo/google.svg"
import collegeLogos from "../../../CollegeConfig";

const AdmissionLogin = () => {
    useGetData('/api/cookies/flushCookies');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('type');

    const setLoading = useSetRecoilState(loadingState);
    const setLoggedInState = useSetRecoilState(loggedInState);
    const setUserState = useSetRecoilState(userState);

    const { postData } = usePostData();

    const [otp, setOtp] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [remainingTime, setRemainingTime] = useState();

    const defaultValues = { otpType: 'admissionLogin' };
    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);
    const { handleSnackbarOpen } = useSnackbar();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const otpVerifyButton = document.getElementById('otpVerify');
            if (otpVerifyButton) otpVerifyButton.click();
        }
    };

    const toggleEmailSubmitted = () => setEmailSubmitted(!emailSubmitted)

    const onSubmit = async (formData) => {
        const OTPFormData = { email: formData?.email, type: activeTab }
        setLoading(true);
        const { success, message } = await postData('/api/admission/login/generateOTP', OTPFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP to your Email ID!", 'error')

        handleSnackbarOpen('OTP sent to your Email ID!', 'success');
        setEmailSubmitted(true);
        setRemainingTime(5 * 60);
    }

    const { formData, handleChange, handleSubmit } = useFormOperations(defaultValues, onSubmit, '/admissions/form?step=Personal+Details');

    const onOTPVerify = async () => {
        const OTPFormData = { email: formData?.email, otp, type: activeTab }
        setLoading(true);
        const { success, message, data } = await postData('/api/admission/login/verifyOTP', OTPFormData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        const { loginType, submitted } = data;
        setLoggedInState(loginType);
        setUserState({ submitted });

        setEmailSubmitted(true);
        handleSnackbarOpen("OTP Verified successfully!", 'success');

        if (submitted) return navigate(`/admissions/${activeTab.toLowerCase()}/profile?type=Personal`);
        navigate(`/admissions/${activeTab.toLowerCase()}/form?step=Personal+Details`);
    }

    const SignGoogle = async (data) => {
        const { success, message, data: status } = await postData('/api/admission/login/googleSuccess', { code: data?.code, type: activeTab })
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some error occurred from our end, pls try again!", 'error');

        if (status?.submitted) return navigate(`/admissions/${activeTab.toLowerCase()}/profile?type=Personal`);
        navigate(`/admissions/${activeTab.toLowerCase()}/form?step=Personal+Details`);
    };

    const showErrorPopup = () => handleSnackbarOpen("Opps.. Some error occurred!", 'error');

    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: SignGoogle,
        onNonOAuthError: (error) => { if (error.type !== 'popup_closed') showErrorPopup(); },
        onError: showErrorPopup,
        ux_mode: 'popup',
    });

    useGoogleOneTapLogin({
        onError: () => handleSnackbarOpen("Opps.. Some error occurred!", 'error'),
        onSuccess: SignGoogle,
        googleAccountConfigs: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
        }
    });

    const tabs = [{ label: 'Student', icon: <Person /> }, { label: 'Employee', icon: <Person3 /> }]

    useEffect(() => {
        let timer;
        if (emailSubmitted && remainingTime > 0)
            timer = setInterval(() => setRemainingTime(t => t - 1), 1000);

        return () => clearInterval(timer);
    }, [emailSubmitted, remainingTime]);

    const formattedTime = emailSubmitted && remainingTime > 0 ? `${Math.floor(remainingTime / 60)} min ${remainingTime % 60} sec Remaining` : emailSubmitted && 'OTP EXPIRED PLS SEND AGAIN';



    return (
        <div className="relative flex justify-center items-center min-h-screen h-full md:p-10 md:px-20">

            {/* Wrapper for background split */}
            <div className="absolute inset-0 flex">
                <div className="w-1/2 bg-regal-purple-10 h-full"></div>
                <div className="flex w-1/2 bg-regal-purple-800 items-end justify-end h-full text-white text-opacity-50 text-sm lg:text-base p-2"> Crafted with ❤️ by the BytesWrite team </div>
            </div>

            {/* Main content container */}
            <div className="relative z-10 grid grid-cols-12 min-h-[670px] h-full w-full max-w-8xl drop-shadow-md md:rounded-3xl bg-white">

                <div className="lg:col-span-6 hidden lg:flex flex-col px-16 py-10 lg:py-20 gap-20">
                    <img draggable={false} className='hidden tab:block h-20 w-20 xl:h-28 xl:w-28' src={collegeLogos?.shortLight} alt="College Logo" />
                    <p className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-700 to-slate-500">Join a vibrant community where innovation meets opportunity! We foster an inclusive culture, empowering students to excel and reach their full potential. Take the first step toward a transformative educational experience with us.</p>
                    <div className='flex flex-col gap-10'>
                        <TypeAnimation
                            sequence={[
                                `Welcome to ${process.env.REACT_APP_COLLEGE_NAME}`,
                                2000,
                                `Welcome to ${process.env.REACT_APP_COLLEGE_NAME}`,
                                2000
                            ]}
                            wrapper="span"
                            speed={10}
                            style={{ fontSize: '1.5em', display: 'inline-block', fontWeight: 500, color: '#2C0A63' }}
                            repeat={Infinity}
                        />
                        <span className='text-base text-slate-700'>Built with ❤️ by the developers at Byteswrite</span>
                    </div>
                </div>

                <div className="col-span-full flex flex-col lg:col-span-6 items-center text-center w-full px-8 sm:px-10 py-10 lg:py-14 gap-10">
                    <header className='flex flex-col items-center select-none gap-2'>
                        <img draggable={false} className='block lg:hidden h-20 w-20' src={collegeLogos?.shortLight} alt="College Logo" />
                        <img draggable={false} className='w-48 sm:w-52 h-20' src="/logo/campus.svg" alt="CAMPUS_Logo" />
                        <p className='text-light text-xs sm:text-sm font-medium text-center max-w-xl w-full'>Achieve Your Academic Goals with Us</p>
                    </header>

                    <div className="flex flex-col gap-3 items-center w-full max-w-md">
                        <BoxTabSmall disabled={emailSubmitted} className='w-full mb-4' tabs={tabs} />
                        <form className="flex flex-col w-full gap-4" onSubmit={handleSubmit}>
                            <CustomTextField
                                name="email" label="Email Address" color="secondary" type="email" placeholder="abc@example.com"
                                value={formData?.email} onChange={handleChange} disabled={emailSubmitted}
                                startIcon={<Email className="text-regal-purple-600" />}
                                endIcon={emailSubmitted &&
                                    <BlackToolTip arrow message='Change email address?'>
                                        <ChangeCircle className="text-light hover:text-regal-purple-600 cursor-pointer" onClick={toggleEmailSubmitted} />
                                    </BlackToolTip>
                                }
                            />

                            {emailSubmitted ?
                                <div className="flex flex-col space-y-2 h-20">
                                    <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 12 } }} onKeyDown={handleKeyDown} />
                                    <p className="flex gap-x-2">
                                        <span className="text-light">Didn't receive the code?</span>
                                        <button type="submit" className="text-regal-purple-500 hover:underline ">Resend it.</button>
                                    </p>
                                </div>
                                :
                                <p className="h-20 grid place-items-center border-dashed border-2 w-full p-3">
                                    <span className=" text-s leading-6">
                                        New? Create your account. <br />
                                        Existing? Sign in seamlessly.
                                    </span>
                                </p>
                            }

                            {emailSubmitted ? <PurpleButton id='otpVerify' disabled={otp.length < 6} fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} sx={{ paddingX: 3, paddingY: 1.3 }} />
                                : <PurpleButton fullWidth disabledElevation={true} type="submit" text="Generate OTP" sx={{ paddingX: 3, paddingY: 1.3 }} />
                            }
                        </form>

                        <button className='flex py-2 px-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-[4px] w-full select-none'
                            onClick={handleGoogleLogin} disabled={emailSubmitted}
                        >
                            <div className='h-fit w-fit flex p-1 bg-white rounded-full'>
                                <img draggable={false} src={google} className='h-5 w-5' alt="googleImage" />
                            </div>
                            <h5 className='text-p font-semibold'>Sign in with Google</h5>
                        </button>

                        <p className="text-s">Already have an account? <Link to='/login?type=password' className=' hover:text-regal-purple-500 hover:underline'>
                            Log in here.
                        </Link>
                        </p>
                        <p className="text-p text-signal h-4">{formattedTime}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdmissionLogin;