import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { LocalPhone, MailOutline, CalendarMonth, VpnKey } from '@mui/icons-material';
import { Avatar, useMediaQuery } from '@mui/material';

import useGetData from "../../../../hooks/useGetData";

import TabSmall from '../../../../components/mui/TabSmall';
import SkeletonText from '../../../../components/mui/text/SkeltonText';
import BubbleTile from '../../../../components/tiles/BubbleTileFields';

import BasicInfo from './BasicInfo';
import PersonalDetails from './PersonalDetails';
import FamilyDetails from './FamilyDetails';
import EducationDetails from './EducationDetails';
import FinanceDetails from './FinanceDetails';
import DocumentDetails from './DocumentDetails';
import BackNavigate from '../../../../components/mui/BackNavigate';

const Profile = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const routeMap = {
        enrolled: `/api/employee/admission/enrolleds/getEnrolled/${id}`,
        alumni: `/api/employee/admission/alumnis/getAlumni/${id}`
    };
    const key = location?.pathname?.includes('enrolled') ? 'enrolled' : 'alumni';

    const { data: studentData, isLoading: studentDataLoading } = useGetData(routeMap[key]);

    const activeTab = searchParams.get('type');
    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const tabs = ['Personal', 'Education', 'Family', 'Finance', 'Documents'];
    const fullName = `${studentData?.firstName} ${studentData?.middleName ?? ""} ${studentData?.lastName}`;

    const contactInfoMap = [
        { label: 'Admission Year', data: studentData?.admissionYear, icon: <CalendarMonth color='secondary' /> },
        { label: 'Admission ID', data: studentData?.studentId, icon: <VpnKey color='warning' /> },
        { label: 'Contact Number', data: studentData?.phNo, icon: <LocalPhone color='success' /> },
        { label: 'Email ID', data: studentData?.emailId, icon: <MailOutline color='error' /> },
    ];

    return (
        <div className='flex flex-col gap-y-5 w-full h-full bg-secondary xs:p-5'>
            <div className='flex flex-col gap-5 bg-white p-5 xs:py-6 xs:px-8 xs:rounded-lg drop-shadow-md min-h-svh'>

                <div className='relative flex flex-col tab:flex-row items-center tab:items-start gap-5'>
                    {id && <BackNavigate previousLink={`/admin/student/${key}s`} text='Back' subText={`Return to ${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`} className='absolute left-0 top-0 tab:hidden' />}
                    <Avatar loading={studentDataLoading} src={studentData?.photoURL} alt={fullName} variant="rounded" sx={{ fontSize: 75, width: 125, height: 125 }} />

                    <div className='flex flex-col gap-5 w-full'>
                        <div className='flex flex-col gap-1 items-center tab:items-start tab:w-full'>
                            <div className='flex justify-between tab:w-full'>
                                <SkeletonText loading={studentDataLoading} width={250} className='h4-p sm:h2-p'>{fullName}</SkeletonText>
                                {id && <BackNavigate previousLink={`/admin/student/${key}s`} text='Back' subText={`Return to ${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`} className='hidden tab:flex' />}
                            </div>
                            <SkeletonText loading={studentDataLoading} width={300} lines={isSmallScreen ? 2 : 1} className='lb-s text-center'>{studentData?.admissionQuota} | {studentData?.programKey} <span className='text-purple'>| {studentData?.collegeName} </span></SkeletonText>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-8'>
                            <div className='grid xs:grid-cols-2 xl:grid-cols-4 gap-5 w-fit xs:w-full truncate'>
                                {contactInfoMap?.map((info, index) => (
                                    <div className='flex flex-col space-y-1' key={index}>
                                        <div className='flex space-x-2 items-center'>
                                            {info?.icon}
                                            <span className='text-p text-[15px]'>{info.label}</span>
                                        </div>
                                        <SkeletonText loading={studentDataLoading} width={150} className='text-s'>{info.data}</SkeletonText>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col-reverse lg:flex-row gap-10 w-full h-full'>
                    <div className='flex flex-col flex-shrink-0 w-full lg:w-56 gap-5 mt-3 xs:mt-5 lg:mt-9'>
                        <BasicInfo basicData={studentData?.personalInfo} loading={studentDataLoading} />
                        <BubbleTile title="Hobbies" data={studentData?.additionalInfo?.hobbies} loading={studentDataLoading} />
                    </div>

                    <div className='flex flex-col w-full gap-5'>
                        <TabSmall tabs={tabs} />
                        {/* todo: fix tab heights lg:h-[calc(100svh-400px)]*/}
                        <div className='flex flex-col w-full'>
                            {activeTab === "Personal" && <PersonalDetails data={studentData} loading={studentDataLoading} />}
                            {activeTab === "Education" && <EducationDetails educationData={studentData?.exams} loading={studentDataLoading} />}
                            {activeTab === "Family" && <FamilyDetails familyData={studentData?.familyInfo} loading={studentDataLoading} />}
                            {activeTab === "Finance" && <FinanceDetails financeData={studentData?.financialAndLegalInfo} loading={studentDataLoading} />}
                            {activeTab === "Documents" && <DocumentDetails documentData={studentData?.documents} loading={studentDataLoading} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;