import React from 'react'
import { useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';

import { loadingState } from '../../../../../recoil/state';
import useGetData from '../../../../../hooks/useGetData';
import usePutData from '../../../../../hooks/usePutData';
import useSnackbar from '../../../../../hooks/useSnackbar';

import { CloseButton, ConfirmButton, PurpleButton } from '../../../../../components/mui/button/Button';
import VerifyDocuments from '../../../../../components/tiles/documents/VerifyDocuments';

const OfflineVerification = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { data: documentsData, isLoading: isDocumentsLoading, updateData } = useGetData(`/api/employee/hr/recruits/getDocuments/${id}`);
    const { data: statusData } = useGetData(`/api/employee/hr/recruits/getStatus/${id}`);
    const { putData } = usePutData();

    const generateViewUrl = (key) => `/api/employee/hr/recruits/getViewURL/${id}?key=${key}`;
    const disabled = !documentsData || ![...Object?.values(documentsData)]?.flat()?.every(doc => doc?.offlineStatus === "Verified");

    const updateDocumentStatus = async (key, newStatus, allUpdate = false) => {
        const body = { key, type: 'offline', status: newStatus };
        const { message, success } = await putData(`/api/employee/hr/recruits/updateDocumentStatus/${id}`, body);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred while Updating status', 'warning');

        const snackbarMessage = newStatus === 'Verified'
            ? 'Document status updated to verified successfully!'
            : 'Document reverted to pending! Status change was successful.';

        updateData(prevState => {
            const updatedDocuments = { ...prevState };
            Object.keys(updatedDocuments).forEach(category => {
                updatedDocuments[category] = updatedDocuments[category].map(doc =>
                    doc.key === key ? { ...doc, offlineStatus: newStatus } : doc
                );
            });

            return updatedDocuments;
        });

        if (!allUpdate) handleSnackbarOpen(snackbarMessage, 'success');
    }

    const handleAllUpdateStatus = async () => {
        try {
            setLoading(true);
            const newStatus = disabled ? 'Verified' : 'Pending';

            const documentsToUpdate = [];
            Object.values(documentsData).forEach(category => { category.forEach(document => { if (document.offlineStatus !== newStatus) { documentsToUpdate.push(document); } }); });

            const promises = documentsToUpdate.map(document => updateDocumentStatus(document.key, newStatus, true));
            await Promise.all(promises);

            handleSnackbarOpen('All documents updated successfully!', 'success');
        } catch (error) {
            handleSnackbarOpen('Error updating documents. Please try again.', 'error');
        } finally { setLoading(false); }
    };

    const handleVerifyOffline = async () => {
        setLoading(true);
        const { message, success } = await putData(`/api/employee/hr/recruits/verifyOfflineAdmission/${id}`);
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred while Updating status', 'warning');
        const approvalType = statusData?.offlineVerificationStatus !== 'Verified' ? 'approved' : 'made pending';
        handleSnackbarOpen(`Recruit Application ${approvalType} offline successfully.`, 'success');
        navigate('/admin/employee/recruits');
    };

    const handleBackward = () => navigate('/employee/hr/recruits')

    const renderLoadingTiles = (count = 5) =>
        Array.from({ length: count }, (_, index) => <VerifyDocuments key={`loading-${index}`} loading />);

    return (
        <div className='flex flex-col w-full'>
            <h5 className='h4-p xs:h3-p px-5 tab:p-10 py-8'>Offline Document Verification</h5>

            <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-tertiary'>
                <h5 className='h5-p xs:h4-p'>General Proofs</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles(8)
                        : documentsData && documentsData?.commonDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>

            {documentsData?.categoryDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Category Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles()
                        : documentsData && documentsData?.categoryDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.academicDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Academic Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles()
                        : documentsData && documentsData?.academicDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            <div className='flex flex-col-reverse sm:flex-row justify-end gap-3 xs:gap-5 p-7 xs:py-10 tab:p-14 bg-white'>
                <CloseButton onClick={handleBackward} text='Cancel' />
                {statusData?.offlineVerificationStatus !== 'Verified' && <ConfirmButton onClick={handleAllUpdateStatus} className='transition-transform transform duration-500' text={disabled ? 'Mark all Submitted' : 'Revert all to Pending'} />}
                {statusData?.offlineVerificationStatus !== 'Verified' ?
                    <PurpleButton onClick={handleVerifyOffline} disabled={disabled} text='Approve Offline' />
                    : <CloseButton variant='contained' onClick={handleVerifyOffline} disabled={disabled} text='Revoke Approval' />
                }
            </div>
        </div>
    )
}

export default OfflineVerification;