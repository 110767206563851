export const disableTextField = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'gray', // Initial border color
            borderWidth: '1px', // Initial border width
        },
        '&:hover fieldset': {
            borderColor: 'gray', // Border color on hover
            borderWidth: '1px', // Border width on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: 'gray', // Border color on focus
            borderWidth: '1px', // Border width on focus
        },
    },
}