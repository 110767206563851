import React from "react";
import renderFormField from "../utils/renderFormField";
import { Add } from "@mui/icons-material";
import { CrossButton } from "./mui/button/Button";
import { generateGeoOptions } from '../utils/geoLocationOptions';

const AddComponent = ({ array, name, properties, label, handleChange, verifiedValues, loading }) => {

    const handleDelete = (index) => {
        const newDetail = [...array];
        newDetail?.splice(index, 1);
        let finalDetail = newDetail?.filter(child => Object.keys(child).length !== 0);
        if (finalDetail.length === 0) {
            finalDetail.push({});
        }
        handleChange({ target: { name: name, value: finalDetail } })
    }

    const handleAdd = () => {
        const newDetail = [...array, {}];
        handleChange({ target: { name: name, value: newDetail } });
    };

    return (
        <div className="col-span-full flex flex-col gap-6">
            {array.map((item, i) => {
                const { countryOptions, stateOptions, cityOptions } = generateGeoOptions(
                    item?.country,
                    item?.state
                );

                return (
                    <div className="col-span-full flex flex-col p-5 gap-4 bg-white rounded-lg border-[1px] border-muiLight" key={i}>
                        <div className="relative col-span-full flex justify-between">
                            <h5 className="col-span-full h4-p">{`${label} ${i + 1}`}</h5>
                            {i > 0 && <CrossButton className="absolute -right-2 -top-3" size={22} onClose={() => handleDelete(i)} />}
                        </div>
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
                            {properties.map((field, index) => {
                                const fieldProps = {
                                    ...field,
                                    name: `${name}.${[i]}.${field.name}`,
                                    value: item?.[field.name],
                                    disabled: verifiedValues ? (verifiedValues === 'accepted' ? true : verifiedValues?.[i]?.[field.name]?.status === 'Verified' ? true : false) : false,
                                    onChange: e => handleChange(e),
                                    options: field.name === 'country' ? countryOptions :
                                        field.name === 'state' ? stateOptions
                                            : field.name === 'city' ? cityOptions
                                                : field.options,
                                    noOptionsMessage: field.name === 'state' ? 'Select country first'
                                        : field.name === 'city' ? 'Select State first'
                                            : field.options
                                };
                                return renderFormField(fieldProps, index, loading);
                            })}
                        </div>
                    </div>
                );
            })}
            <div className="relative col-span-full flex flex-col px-4 py-5 gap-3 bg-white rounded-lg border-[1px] border-muiLight">
                <div className="absolute -top-3.5 left-3.5 cursor-pointer" onClick={handleAdd}>
                    <Add className=" bg-[#E3E9F7] rounded-full  transition-transform transform hover:scale-110 duration-300" sx={{ fontSize: 22 }} />
                </div>
                <h5 className="h4-p">Add {label}</h5>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
                    {properties.map((field, index) => renderFormField({
                        ...field, disabled: true, onChange: e => handleChange(e)
                    }, index, loading))}
                </div>
            </div>
        </div>
    )
}

export default AddComponent;