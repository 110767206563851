import { IconButton } from '@mui/material';
import { CheckCircle, PublishedWithChanges, FileOpen } from '@mui/icons-material';

import pendingIcon from '../../../assets/icons/Pending.svg';
import successIcon from '../../../assets/icons/Success.svg';

import SkeletonText from '../../mui/text/SkeltonText';
import BlackToolTip from '../../mui/BlackToolTip';
import useSnackbar from '../../../hooks/useSnackbar';
import useFetchData from '../../../hooks/useFetchData';

const VerifyDocuments = ({ doc = {}, viewURL, loading, updateStatus }) => {
    const { offlineStatus: status, title, description, key, collectionType } = doc;
    const iconSrc = status === 'Verified' ? successIcon : pendingIcon;

    const { fetchData, error } = useFetchData();
    const { handleSnackbarOpen } = useSnackbar();

    const getViewURL = async () => {
        const signedURL = await fetchData(viewURL);
        if (error) return handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        handleSnackbarOpen('Document opened successfully', 'success');
        window.open(signedURL, '_blank');
    };

    const verifyDocument = async () => updateStatus(key, 'Verified');
    const revokeDocument = async () => updateStatus(key, 'Pending');

    const fileExtension = key?.endsWith('.pdf') ? 'pdf' : 'image';

    return (
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center p-4 gap-2 lg:gap-5 bg-white border-slate-300 border-2 rounded-md">
            <div className="flex gap-2 items-center w-full">

                <BlackToolTip title={loading ? 'Loading' : status}>
                    {loading ? <div className='flex flex-shrink-0 bg-muiLight animate-pulse rounded-full h-14 w-14' /> :
                        <img className='flex flex-shrink-0 cursor-pointer h-14 w-14' src={iconSrc} alt={status} />}
                </BlackToolTip>

                <div className="flex flex-col w-full gap-0.5">
                    <div className='flex flex-col xs:flex-row items-baseline gap-0 xs:gap-1.5'>
                        <SkeletonText loading={loading} width='20%' className="h5-p xs:font-semibold">{title}</SkeletonText>
                        {!loading && <p className='hidden xs:block'>|</p>}
                        {!loading && <p className="text-xs xs:text-sm xs:font-semibold text-purple">{collectionType}</p>}
                    </div>
                    <SkeletonText loading={loading} width='50%' className="hidden xs:block text-s font-normal">{description}</SkeletonText>
                </div>

            </div>

            <div className='flex items-center gap-4 max-w-fit w-full'>
                <div className='ml-2 w-fit'>
                    <SkeletonText width={50} loading={loading} className='bg-orange px-2 py-1.5 text-p text-white rounded-[4px]'>{fileExtension.toUpperCase()}</SkeletonText>
                </div>
                <div className="grid grid-cols-3 gap-1">
                    <BlackToolTip arrow title='View Uploaded Document'>
                        <IconButton disabled={loading} onClick={getViewURL}>
                            <FileOpen className='text-purple' />
                        </IconButton>
                    </BlackToolTip>

                    <BlackToolTip arrow title='Verify Document'>
                        <IconButton disabled={loading || (status !== 'Pending')} onClick={verifyDocument}>
                            <CheckCircle className={(loading || (status !== 'Pending')) ? '' : 'text-green'} />
                        </IconButton>
                    </BlackToolTip>

                    <BlackToolTip arrow title='Revoke Document Status'>
                        <IconButton disabled={loading || (status === 'Pending')} onClick={revokeDocument}>
                            <PublishedWithChanges className={loading || (status === 'Pending') ? '' : 'text-signal'} />
                        </IconButton>
                    </BlackToolTip>
                </div>
            </div>
        </div>
    );
};

export default VerifyDocuments;