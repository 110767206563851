import React from "react";
import TextBox from '../../../../components/mui/text/TextBox';

const EducationDetails = ({ educationData, loading }) => {

    const boardExamProperties = [
        { label: 'Board Name', name: 'boardName' },
        { label: 'Roll Number', name: 'rollNo' },
        { label: 'Exam Year', name: 'examYear' },
        { label: 'School Name', name: 'schoolName' },
        { label: 'School City', name: 'city' },
        { label: 'School State', name: 'state' },
        { label: 'School Country', name: 'country' },
        { label: 'Total Marks', name: 'totalMarks' },
        { label: 'Obtained Marks', name: 'obtainedMarks' },
        { label: 'Grade', name: 'grade' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'Percentile', name: 'percentile' },
        { label: 'CGPA', name: 'cgpa' }
    ];

    const otherExamsProperties = [
        { label: 'Roll Number', name: 'rollNo' },
        { label: 'Exam Year', name: 'examYear' },
        { label: 'Total Marks', name: 'totalMarks' },
        { label: 'Obtained Marks', name: 'obtainedMarks' },
        { label: 'Grade', name: 'grade' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'Percentile', name: 'percentile' },
        { label: 'Caste Rank', name: 'casteRank' },
        { label: 'State Rank', name: 'stateRank' },
        { label: 'National Rank', name: 'nationalRank' }
    ];

    const subjectProperties = [
        { label: 'Subject Name', name: 'subjectName' },
        { label: 'Obtained Marks', name: 'obtainedMarks' },
        { label: 'Total Marks', name: 'totalMarks' }
    ];

    const graduateProperties = [
        { label: 'Degree', name: 'degree' },
        { label: 'Major', name: 'major' },
        { label: 'Degree Type', name: 'degreeType' },
        { label: 'Completion Year', name: 'passingYear' },
        { label: 'College Name', name: 'collegeName' },
        { label: 'College Country', name: 'country' },
        { label: 'College State', name: 'state' },
        { label: 'College City', name: 'city' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'Grade', name: 'grade' },
        { label: 'CGPA', name: 'cgpa' }
    ];

    return (
        <div className='flex flex-col w-full space-y-8'>
            <div className="flex flex-col gap-y-5">
                <div className='flex items-center gap-4'>
                    <p className='lb-s whitespace-nowrap'>Elementary Details</p>
                    <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                    {boardExamProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.elementaryDetail?.[field.name]} loading={loading} />
                    )}
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1 mt-5'>
                    {educationData?.elementaryDetail?.subjects?.map((subjectDetail, i) => subjectProperties.map((field, index) => <TextBox key={index} label={field.label} value={subjectDetail?.[field.name]} loading={loading} />))}
                </div>
            </div>

            {(loading || educationData?.intermediateDetail) && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Intermediate Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {boardExamProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.intermediateDetail?.[field.name]} loading={loading} />
                        )}
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1 mt-5'>
                        {educationData?.intermediateDetail?.subjects?.map((subjectDetail, i) => subjectProperties.map((field, index) => <TextBox key={index} label={field.label} value={subjectDetail?.[field.name]} loading={loading} />))}
                    </div>
                </div>
            </>}

            {educationData?.lateralEntryDetail && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Lateral Entry Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.lateralEntryDetail?.[field.name]} loading={loading} />
                        )}
                    </div>
                </div>
            </>}

            {educationData?.requiredExamsDetail && Object?.keys(educationData?.requiredExamsDetail)?.map((round, index) => educationData?.requiredExamsDetail?.[round]?.map((exam, examIndex) =>
                <React.Fragment key={examIndex}>
                    <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                    <div className="flex flex-col gap-y-5">
                        <div className='flex items-center gap-4'>
                            <p className='lb-s whitespace-nowrap'>{round} Exam {round?.length > 0 ? examIndex + 1 : ''} Details</p>
                            <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                            {otherExamsProperties?.map((field, index) => <TextBox key={index} label={field.label} value={exam?.[field.name]} loading={loading} />
                            )}
                        </div>
                    </div>
                </React.Fragment>))}

            {educationData?.otherExams?.optional && Object?.keys(educationData?.otherExams?.optional)?.map((examName, index) =>
                <React.Fragment key={index}>
                    <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                    <div className="flex flex-col gap-y-5">
                        <div className='flex items-center gap-4'>
                            <p className='lb-s whitespace-nowrap'>{examName} Details</p>
                            <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                            {otherExamsProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.otherExams?.optional?.[examName]?.[field.name]} loading={loading} />
                            )}
                        </div>
                    </div>
                </React.Fragment>)}

            {educationData?.ugDetail && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>UnderGraduate Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.ugDetail?.[field.name]} loading={loading} />
                        )}
                    </div>
                </div>
            </>}

            {educationData?.pgDetail && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>PostGraduate Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => <TextBox key={index} label={field.label} value={educationData?.pgDetail?.[field.name]} loading={loading} />
                        )}
                    </div>
                </div>
            </>}
        </div>
    )
}

export default EducationDetails;