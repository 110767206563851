import React from 'react'
import { nationalities } from '../../../../../constants/geoConstants';
import { indianCategories, indianReligions, } from "../../../../../constants/personal";
import renderFormField from '../../../../../utils/renderFormField';

const IdentityInfo = ({ formData, handleNestedChange, isLoading }) => {

  const nationalityOptions = nationalities.map(nationality => ({ value: nationality, label: nationality }));
  const categoryOptions = indianCategories.map(category => ({ value: category, label: category }));
  const religionOptions = indianReligions.map(religion => ({ value: religion, label: religion }));

  const casteDetailsFields = [
    {
      type: 'select', size: 1, label: "Nationality",
      value: formData?.identityInfo?.nationality, name: "formData.identityInfo.nationality",
      options: nationalityOptions,
    },
    {
      type: 'select',
      size: 1,
      label: "Category",
      value: formData?.identityInfo?.category,
      name: "formData.identityInfo.category",
      options: categoryOptions
    },
    {
      type: 'select',
      size: 1,
      label: "Religion",
      value: formData?.identityInfo?.religion,
      name: "formData.identityInfo.religion",
      options: religionOptions
    },
    { type: 'input', size: 1, label: "Caste", value: formData?.identityInfo?.caste, name: "formData.identityInfo.caste" },
    { type: 'input', size: 1, label: "Sub Caste", value: formData?.identityInfo?.subCaste, name: "formData.identityInfo.subCaste" },
  ]
  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {casteDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
    </div>
  )
}

export default IdentityInfo;