import React from 'react'
import { Navigate } from 'react-router-dom'

const LandingPage = () => {
    const redirectTo = '/login';
    if (redirectTo) return <Navigate to={redirectTo} />

    return (
        <div>LandingPage</div>
    )
}

export default LandingPage
