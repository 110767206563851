import React from 'react'
import { Route, Routes } from 'react-router-dom'
import StudentForm from '../pages/admissions/student/forms/Form'
import StudentProfile from '../pages/admissions/student/home/Profile'
import EmployeeForm from '../pages/admissions/employee/forms/Form'
import EmployeeProfile from '../pages/admissions/employee/home/Profile'

const AdmissionRoutes = () => {
    return (
        <Routes>
            <Route path="/student/profile" element={<StudentProfile />} />
            <Route path="/student/form" element={<StudentForm />} />

            <Route path="/employee/profile" element={<EmployeeProfile />} />
            <Route path="/employee/form" element={<EmployeeForm />} />
        </Routes>
    )
}

export default AdmissionRoutes;