import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from 'recoil'

import { loadingState } from '../../recoil/state'
import useFormOperations from "../../hooks/useFormOperations";
import useSnackbar from "../../hooks/useSnackbar";
import usePostData from "../../hooks/usePostData";

import { CustomTextField } from "../../components/mui/Input";
import { PurpleButton } from "../../components/mui/button/Button";
import ResetPassword from "../../assets/images/resetPassword.jpg"

const UpdatePassword = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const [, setLoading] = useRecoilState(loadingState);
    const { postData } = usePostData();

    const onUpdatePassword = async () => {
        const passwordData = { token: id, newPassword: formData?.newPassword, confirmPassword: formData?.confirmPassword }
        setLoading(true);
        const { success, message } = await postData('/api/forgotPassword/updatePassword', passwordData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        handleSnackbarOpen("Password Reset successfully", 'success');
        navigate('/login');
    }

    const { formData, handleSubmit, handleChange } = useFormOperations({}, onUpdatePassword);

    return (
        <div className="relative flex items-center justify-center px-5 xs:px-10 py-8 xs:py-16 bg-regal-purple-10 min-h-screen w-full overflow-hidden">

            <div className="absolute top-[20%] -left-[10%] rounded-full size-48 lg:size-64 bg-gradient-to-t from-violet-200 to-violet-100" />

            <div className="hidden tab:block absolute top-[10%] left-[28%] rounded-full size-48 lg:size-64 bg-gradient-to-l from-violet-200 to-violet-100" />
            <div className="hidden tab:block absolute bottom-[10%] right-[30%] rounded-full size-20 lg:size-40 bg-gradient-to-l from-violet-200 to-violet-100" />

            <div className="hidden xs:block absolute -bottom-28 left-[20%] rounded-full size-40 bg-gradient-to-t from-violet-200 to-violet-100" />
            <div className="absolute bottom-5 left-[14%] rounded-full size-16 xl:size-20 bg-gradient-to-tr from-violet-200 to-violet-100" />
            <div className="hidden tab:block absolute bottom-20 left-[20%] rounded-full size-24 xl:size-32 bg-gradient-to-b from-violet-200 to-violet-100" />

            <div className="absolute -top-36 xs:-top-28 right-[15%] rounded-full size-60 xl:size-80 bg-gradient-to-b from-violet-200 to-violet-100" />
            <div className="hidden tab:block absolute top-5 right-[5%] rounded-full size-24 xl:size-32 bg-gradient-to-b from-violet-200 to-violet-100" />
            <div className="hidden tab:block absolute top-28 xl:top-40 right-[13%] xl:right-[11%] rounded-full size-16 xl:size-20 bg-gradient-to-tr from-violet-200 to-violet-100" />

            <div className="absolute -bottom-28 -right-12 rounded-full size-52 xs:size-60 lg:size-80 bg-gradient-to-t from-violet-200 to-violet-100" />

            <div className="flex flex-col text-center gap-10 py-8 px-7 xs:px-14 xs:py-16 w-full max-w-lg rounded-xl bg-white drop-shadow-md">
                <div className="flex flex-col gap-3">
                    <img className="h-24 w-24 xs:h-28 xs:w-28 self-center rounded-full drop-shadow-sm" src={ResetPassword} alt="Reset Password" />
                    <div className="flex flex-col gap-1">
                        <h1 className="h1-p text-regal-purple-800">Reset Password</h1>
                        <p className="text-base text-slate-500">Enter and confirm your new password below to securely reset it and regain access to your account</p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col gap-5">
                    <CustomTextField
                        label="New Password"
                        name="newPassword"
                        className="bg-white"
                        value={formData?.newPassword ?? ''}
                        placeholder='**********'
                        fullWidth
                        onChange={handleChange}
                    />
                    <CustomTextField
                        label="Confirm Password"
                        name="confirmPassword"
                        className="bg-white"
                        value={formData?.confirmPassword ?? ''}
                        placeholder='**********'
                        fullWidth
                        onChange={handleChange}
                    />
                    <PurpleButton fullWidth disabledElevation={true} size='large' type="submit" text="Update password" />
                </form>
            </div>
        </div>
    )
}

export default UpdatePassword;