import { useSearchParams } from 'react-router-dom';

const useSteppers = (steps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeStep = searchParams.get('step');
    const activeStepIndex = steps?.indexOf(activeStep);

    const changeStep = (newValue) => {
        if (newValue >= 0 && newValue < steps?.length) {
            const newStep = steps[newValue];
            setSearchParams((prevParams) => {
                const newParams = new URLSearchParams(prevParams);
                newParams.set('step', newStep);
                return newParams.toString();
            });
        }
    };
    const handleBackward = () => changeStep(activeStepIndex - 1);
    const handleForward = () => changeStep(activeStepIndex + 1);

    const isBackwardDisabled = activeStepIndex <= 0;
    const isForwardDisabled = activeStepIndex >= steps?.length - 1;

    return {
        handleBackward, handleForward, searchParams,
        activeStep, activeStepIndex, changeStep,
        isBackwardDisabled, isForwardDisabled
    };
};

export default useSteppers;
