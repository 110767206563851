import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Forward } from '@mui/icons-material';
import UploadDocumentTile from '../../../../components/upload/UploadDocumentTile';
import { ConfirmButton, CloseButton, PurpleButton } from '../../../../components/mui/button/Button'
import { CustomTextField } from '../../../../components/mui/Input';

const SubmitDocument = () => {

  const isMedium = useMediaQuery('(max-width:768px)');

  const navigate = useNavigate();
  const handleDiscard = () => {
    navigate('/admin/settings/updateInfo?type=Personal')
  }

  const candidateDocuments = [
    {
      title: 'Candidate’s Passport',
      description: 'This is a document used for verification.',
      file: 'image1.png',
      size: '6.4 mb',
      status: "success"
    },
    {
      title: 'Candidate’s Aadhar Card',
      description: 'This is a document used for verification.',
      file: 'image1.png',
      size: '6.4 mb',
      status: "pending"
    },
    {
      title: 'Candidate’s Voter ID',
      description: 'This is a document used for verification.',
      file: 'image1.png',
      size: '6.4 mb',
      status: "success"
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-6 p-5 md:pb-8 md:px-8 bg-white drop-shadow rounded-lg'>
        <h5 className='h3-p items-center'>Preview Changes</h5>
        <div className='flex items-center gap-6'>
          <CustomTextField
            label="First Name" name='formData.firstName' value="{formData?.firstName}"
            fullWidth className='bg-white' />
          {!isMedium && <Forward className='w-full place-content-center' color='success' sx={{ fontSize: 50 }} />}
          <CustomTextField
            label="First Name" name='formData.firstName' value="{formData?.firstName}"
            fullWidth className='bg-white' />
        </div>
        <CustomTextField label='Reason' multiline rows={4} className='no-scrollbar' />
      </div>

      <div className='flex flex-col gap-6 p-5 md:p-8 bg-white drop-shadow rounded-lg'>
        <div className='flex flex-col'>
          <h5 className='h3-p'>Required Documents</h5>
          <p className='lb-s text-orange'>All the documents should be in pdf format only</p>
        </div>
        <div className='flex flex-col gap-4'>
          {candidateDocuments?.map((doc, index) =>
            <UploadDocumentTile key={index} doc={doc} isEmployee={false} />
          )}
        </div>
        <div className='flex justify-end gap-5 w-full'>
          <CloseButton text="Discard" onClick={handleDiscard} />
          <PurpleButton text='Preview' />
          <ConfirmButton text='Submit' />
        </div>
      </div>
    </div>
  )
}

export default SubmitDocument;