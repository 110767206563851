import React from "react";
import Lottie from "lottie-react";
import TextBox from '../../../../components/mui/text/TextBox';
import { dateFormat } from "../../../../utils/dateFormat";
import NoData from '../../../../assets/lotties/NoData.json'

const FamilyDetails = ({ familyData, loading }) => {

    const fatherData = [
        { label: 'Name', value: `${familyData?.fatherDetail?.title} ${familyData?.fatherDetail?.firstName} ${familyData?.fatherDetail?.middleName ?? ""} ${familyData?.fatherDetail?.lastName}` },
        { label: 'Phone Number', value: familyData?.fatherDetail?.phNo },
        { label: 'Email ID', value: familyData?.fatherDetail?.emailId },
        { label: 'Education', value: familyData?.fatherDetail?.education },
        { label: 'Profession', value: familyData?.fatherDetail?.profession },
        { label: 'Income', value: familyData?.fatherDetail?.income },
    ]
    const motherData = [
        { label: 'Name', value: `${familyData?.motherDetail?.title} ${familyData?.motherDetail?.firstName} ${familyData?.motherDetail?.middleName ?? ""} ${familyData?.motherDetail?.lastName}` },
        { label: 'Phone Number', value: familyData?.motherDetail?.phNo },
        { label: 'Email ID', value: familyData?.motherDetail?.emailId },
        { label: 'Education', value: familyData?.motherDetail?.education },
        { label: 'Profession', value: familyData?.motherDetail?.profession },
        { label: 'Income', value: familyData?.motherDetail?.income },
    ]
    const partnerData = [
        { label: 'Name', value: `${familyData?.partnerDetail?.title} ${familyData?.partnerDetail?.firstName} ${familyData?.partnerDetail?.middleName ?? ""} ${familyData?.partnerDetail?.lastName}` },
        { label: 'Phone Number', value: familyData?.partnerDetail?.phNo },
        { label: 'Email ID', value: familyData?.partnerDetail?.emailId },
        { label: 'Education', value: familyData?.partnerDetail?.education },
        { label: 'Profession', value: familyData?.partnerDetail?.profession },
        { label: 'Income', value: familyData?.partnerDetail?.income }
    ]
    const emergencyPersonData = [
        { label: 'Name', value: `${familyData?.emergencyPersonDetail?.title} ${familyData?.emergencyPersonDetail?.firstName} ${familyData?.emergencyPersonDetail?.middleName ?? ""} ${familyData?.emergencyPersonDetail?.lastName}` },
        { label: 'Phone Number', value: familyData?.emergencyPersonDetail?.phNo },
        { label: 'Relation', value: familyData?.emergencyPersonDetail?.relation },
    ]

    return (
        <div className='flex flex-col w-full gap-8'>
            {(loading || !familyData?.fatherDetail?.isDeceased) &&
                <div className="flex flex-col gap-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Father Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {fatherData?.map((field, index) =>
                            <TextBox key={index} label={field.label} value={field.value} loading={loading} />
                        )}
                    </div>
                </div>
            }

            {(loading || !familyData?.motherDetail?.isDeceased) && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Mother Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {motherData?.map((field, index) => field?.value &&
                            <TextBox key={index} label={field.label} value={field.value} loading={loading} />
                        )}
                    </div>
                </div>
            </>}

            {(loading || familyData?.partnerDetail) && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Partner Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {partnerData?.map((field, index) => field?.value &&
                            <TextBox key={index} label={field.label} value={field.value} loading={loading} />
                        )}
                    </div>
                </div>
            </>}

            {familyData?.children && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Children Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1">
                        {familyData?.children?.map((child, i) => (
                            <React.Fragment key={i}>
                                <TextBox label="Name" value={child?.name} loading={loading} />
                                <TextBox label="Date of Birth" value={dateFormat(child?.dob)} loading={loading} />
                                <TextBox label="Education" value={child?.education} loading={loading} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </>}

            {familyData?.emergencyPersonDetail && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-x-4'>
                        <p className='lb-s whitespace-nowrap'>Emergency Person Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {emergencyPersonData?.map((field, index) => (
                            <TextBox key={index} label={field.label} value={field.value} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {(familyData?.fatherDetail?.isDeceased && familyData?.motherDetail?.isDeceased && !(!!familyData?.partnerDetail) && !(!!familyData?.children) && !(!!familyData?.emergencyPersonDetail)) &&
                <div className='flex flex-col justify-center items-center p-7 xs:p-12 h-full w-full'>
                    <Lottie className='h-60' animationData={NoData} loop={true} />
                    <div className='text-s text-lg'>No admission data found.</div>
                </div>
            }
        </div>
    )
}

export default FamilyDetails;