import React from "react";
import PortalPopup from '../../PortalPopup'
import { CrossButton } from "../../../mui/button/Button";

const UpdateChangesPopup = ({ onClose, data }) => {
  return (
    <PortalPopup overlayColor="rgba(0,0,0,0.5)" placement="Centered" >
      <div className="flex flex-col justify-between w-full h-full max-w-[950px] max-h-[700px] bg-white p-6 rounded-lg gap-5">
        <div className="flex justify-between">
          <p className="h4-p">Previous Changes</p>
          <CrossButton onClose={onClose} />
        </div>
        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro fugiat ducimus cum temporibus autem distinctio enim commodi cupiditate architecto, beatae quos non harum repellat officia. Voluptatum aliquid nobis, quisquam sapiente, blanditiis error, veniam praesentium a esse vel labore ex sit magni sunt commodi. Nostrum ipsam inventore praesentium deserunt id accusantium nemo. Placeat, sequi! Suscipit nihil harum optio, earum aut autem labore libero. Molestias, blanditiis ut unde similique saepe ex explicabo reiciendis totam consequuntur nihil provident dolores dolorum delectus, consectetur accusamus vitae sit sapiente! Sequi saepe itaque architecto ipsum nostrum, repudiandae minus, nemo maxime, laudantium autem consequatur quis eum velit fugit.</div>
      </div>
    </PortalPopup>
  )
}

export default UpdateChangesPopup;