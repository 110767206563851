import React from "react";
import TextBox from '../../../../components/mui/text/TextBox';

const EducationDetails = ({ educationData, loading }) => {

    const boardExamProperties = [
        { label: 'Board Name', name: 'boardName' },
        { label: 'Roll Number', name: 'rollNo' },
        { label: 'Exam Year', name: 'examYear' },
        { label: 'School Name', name: 'name' },
        { label: 'School City', name: 'city' },
        { label: 'School State', name: 'state' },
        { label: 'School Country', name: 'country' },
        { label: 'Total Marks', name: 'totalMarks' },
        { label: 'Obtained Marks', name: 'obtainedMarks' },
        { label: 'Grade', name: 'grade' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'CGPA', name: 'cgpa' }
    ];

    const graduateProperties = [
        { label: 'Degree', name: 'degree' },
        { label: 'Major', name: 'major' },
        { label: 'Degree Type', name: 'degreeType' },
        { label: 'Completion Year', name: 'passingYear' },
        { label: 'College Name', name: 'collegeName' },
        { label: 'College Country', name: 'country' },
        { label: 'College State', name: 'state' },
        { label: 'College City', name: 'city' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'Grade', name: 'grade' },
        { label: 'CGPA', name: 'cgpa' }
    ];

    const doctorateProperties = [
        { label: 'Degree', name: 'degree' },
        { label: 'Degree Type', name: 'degreeType' },
        { label: 'Dissertation Title', name: 'dissertationTitle' },
        { label: 'Supervisor', name: 'supervisor' },
        { label: 'Completion Year', name: 'completionYear' },
        { label: 'University Name', name: 'universityName' },
        { label: 'University Country', name: 'country' },
        { label: 'University State', name: 'state' },
        { label: 'University City', name: 'city' },
        { label: 'CGPA', name: 'cgpa' },
        { label: 'Percentage', name: 'percentage' },
        { label: 'Grade', name: 'grade' }
    ];

    const certificationProperties = [
        { label: 'Certification', name: 'certification' },
        { label: 'Certification Type', name: 'certificationType' },
        { label: 'Issuing Organization', name: 'issuingOrganization' },
        { label: 'Issue Date', name: 'issueDate' },
        { label: 'Expiry Date', name: 'expiryDate' },
        { label: 'Certificate ID', name: 'certificateID' },
        { label: 'Score', name: 'score' },
        { label: 'Certification URL', name: 'certificateUrl' },
        { label: 'License Number', name: 'license' }
    ];

    return (
        <div className='flex flex-col w-full space-y-8'>
            <div className="flex flex-col gap-y-5">
                <div className='flex items-center gap-4'>
                    <p className='lb-s whitespace-nowrap'>Elementary Details</p>
                    <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                    {boardExamProperties?.map((field, index) => ((!loading ? educationData?.elementaryDetail?.[field.name] : true) && <TextBox key={index} label={field.label} value={educationData?.elementaryDetail?.[field.name]} loading={loading} />
                    ))}
                </div>
            </div>

            {educationData?.intermediateDetail && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Intermediate Details</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {boardExamProperties?.map((field, index) => (educationData?.intermediateDetail?.[field.name] && <TextBox key={index} label={field.label} value={educationData?.intermediateDetail?.[field.name]} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {educationData?.diplomaDetails && educationData?.diplomaDetails?.map((diploma, i) => <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Diploma {i + 1}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => (diploma?.[field?.name] && <TextBox key={index} label={field.label} value={diploma?.[field?.name]} loading={loading} />
                        ))}
                    </div>
                </div>
            </>)}

            {educationData?.ugDetails && educationData?.ugDetails?.map((ug, i) => <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Under Graduate {i + 1}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => (ug?.[field?.name] && <TextBox key={index} label={field.label} value={ug?.[field?.name]} loading={loading} />
                        ))}
                    </div>
                </div>
            </>)}

            {educationData?.pgDetails && educationData?.pgDetails?.map((pg, i) => <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Post Graduate {i + 1}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {graduateProperties?.map((field, index) => (pg?.[field?.name] && <TextBox key={index} label={field.label} value={pg?.[field?.name]} loading={loading} />
                        ))}
                    </div>
                </div>
            </>)}

            {educationData?.doctorateDetails && educationData?.doctorateDetails?.map((doctorate, i) => <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Doctorate(P.h.D.) {i + 1}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {doctorateProperties?.map((field, index) => (doctorate?.[field?.name] && <TextBox key={index} label={field.label} value={doctorate?.[field?.name]} loading={loading} />
                        ))}
                    </div>
                </div>
            </>)}

            {educationData?.certificationDetails && educationData?.certificationDetails?.map((certificate, i) => <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className="flex flex-col gap-y-5">
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Certification {i + 1}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                        {certificationProperties?.map((field, index) => (certificate?.[field?.name] && <TextBox key={index} label={field.label} value={certificate?.[field?.name]} loading={loading} />
                        ))}
                    </div>

                </div>
            </>)}
        </div>
    )
}

export default EducationDetails;