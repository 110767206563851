import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SkeletonText = ({ loading, children, className, lines = 1, width, height, displayEmpty = true, ...props }) => {

    if (loading) return (
        <div className='w-full'>
            {Array.from({ length: lines }).map((_, index) => (
                <Skeleton className={className} key={index} animation='wave' width={width || '100%'} height={height && height} {...props} />
            ))}
        </div>
    );


    return (
        <p className={className}>
            {(children !== null && children !== undefined && children !== '') ? children : displayEmpty ? 'N/A' : ''}
        </p>
    );
};

export default SkeletonText;