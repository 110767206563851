import React from 'react'
import Lottie from 'lottie-react';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import SkeletonText from '../../../../components/mui/text/SkeltonText'
import Success from '../../../../assets/icons/Success.svg'
import Pending from '../../../../assets/icons/Pending.svg'
import NoData from '../../../../assets/lotties/NoData.json'

const ChangesInfo = ({ handlePreviewClick }) => {
    const changesData = undefined;
    // const changesData = [
    //     {
    //         status: 'pending',
    //         title: 'Change your personal information fbdfb fsdbf sdbfhjb dsbfj bfsd',
    //         date: '20th September 2021',
    //     },
    //     {
    //         status: 'success',
    //         title: 'Change your employment information',
    //         date: '20th January 2021',
    //     },
    //     {
    //         status: 'declined',
    //         title: 'Change your employment information',
    //         date: '20th January 2021',
    //     }
    // ]
    const iconMap = {
        success: Success,
        pending: Pending,
        declined: Pending,
    }

    return (
        <div className='flex flex-col pt-4 pb-5 px-5 bg-white drop-shadow rounded-lg gap-3 h-full'>
            <div className='flex justify-between items-center'>
                <h5 className='h5-p font-semibold'>Latest Changes</h5>
                <IconButton onClick={handlePreviewClick}>
                    <OpenInNewIcon sx={{ fontSize: 20 }} />
                </IconButton>
            </div>
            {changesData ? <div className='flex flex-col gap-2'>
                {
                    changesData?.slice(-3).map((change, index) => (
                        <div key={index} className='flex items-center space-x-3'>
                            <img className='h-10' src={iconMap[change.status]} alt="status" />
                            <div className='flex flex-col w-full'>
                                <SkeletonText><p className='text-p cursor-pointer line-clamp-1' onClick={handlePreviewClick}>{change.title}</p></SkeletonText>
                                <SkeletonText><p className='text-s text-[13px]'>{change.date}</p></SkeletonText>
                            </div>
                        </div>
                    ))}
            </div> : <div className='flex flex-col justify-center items-center h-full'>
                <Lottie className='h-28' animationData={NoData} loop={true} />
                <div className='text-s'>You have not updated anything yet.</div>
            </div>}
        </div>
    )
}

export default ChangesInfo;