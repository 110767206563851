import { IconButton, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BlackToolTip from '../mui/BlackToolTip';
import { Notifications, Settings } from '@mui/icons-material';
import ProfileMenu from './ProfileMenu';

const MiniNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const studentNavLinks = [
        { path: '/admin/student/applicants', label: "Applicants" },
        { path: '/admin/student/enrolleds', label: "Enrolled" },
        { path: '/admin/student/alumnis', label: "Alumni" },
    ]

    const employeeNavLinks = [
        { path: '/admin/employee/recruits', label: "Recruits" },
        { path: '/admin/employee/currents', label: "Current" },
        { path: '/admin/employee/formers', label: "Former" }
    ]

    const profileNavLinks = [
        { path: '/admin/profile?type=Personal', label: "Profile" },
    ]

    const currentPath = location.pathname;
    const navLinks = currentPath.startsWith("/admin/employee/") ? employeeNavLinks : currentPath.startsWith("/admin/student/") ? studentNavLinks : profileNavLinks;
    const index = navLinks.findIndex((key) => currentPath.includes(key.path));
    const handleTabClick = (path) => navigate(path);

    return (
        <div className='flex items-center px-3 sm:px-5 md:px-8 justify-center w-full gap-5'>
            <Tabs variant='scrollable' scrollButtons={false}
                className='flex items-center'
                value={index !== -1 ? index : 0} sx={{ "& .MuiTabs-indicator": { display: 'none' } }}
            >
                {navLinks.map((tab, index) => (
                    <Tab key={index} label={tab.label} onClick={() => handleTabClick(tab.path)}
                        className='transition-all duration-100 mx-6'
                        disableTouchRipple
                        sx={{
                            textTransform: 'none',
                            '&.Mui-selected': { color: '#5D45DB', fontWeight: 600 },
                            fontFamily: 'Work Sans, sans-serif',
                            color: '#9CA3AF',
                            fontSize: 16, letterSpacing: 1.2
                        }}
                    />
                ))}
            </Tabs>
        </div>
    );
}

export default MiniNavbar;