import React from "react";
import useFetchData from "../../../../hooks/useFetchData";
import useSnackbar from "../../../../hooks/useSnackbar";
import DocumentBox from "../../../../components/tiles/documents/DocumentBox";
import { useParams } from "react-router-dom";

const DocumentDetails = ({ documentData, loading }) => {
    const { id } = useParams();
    const { fetchData, error } = useFetchData();
    const { handleSnackbarOpen } = useSnackbar();

    const handleClick = async (key) => {
        const signedURL = await fetchData(id ? `/api/employee/admission/applicants/getViewURL/${id}?key=${key}` : `/api/admission/student/getViewURL?key=${key}`);
        if (error) handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        window.open(signedURL, '_blank');
    };

    return (
        <div className='flex flex-col w-full space-y-8'>
            <div className='flex flex-col w-full gap-5'>
                <div className='flex items-center gap-4'>
                    <p className='lb-s whitespace-nowrap'>Common Documents</p>
                    <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                    {(!loading ? documentData?.commonDocuments : Array.from({ length: 8 }))?.map((document, index) => (
                        <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                    ))}
                    {(!loading ? documentData?.optionalCommonDocuments : Array.from({ length: 2 }))?.map((document, index) => (
                        <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                    ))}
                </div>
            </div>

            <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />

            <div className='flex flex-col w-full gap-5'>
                <div className='flex items-center gap-4'>
                    <p className='lb-s whitespace-nowrap'>Academic Documents</p>
                    <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                    {(!loading ? documentData?.academicDocuments : Array.from({ length: 4 }))?.map((document, index) => (
                        <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                    ))}
                </div>
            </div>

            {documentData?.programDocuments?.length > 0 && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Program Documents</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {documentData?.programDocuments?.map((document, index) => (
                            <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {documentData?.gapDocuments?.length > 0 && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Gap Documents</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {documentData?.gapDocuments?.map((document, index) => (
                            <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {documentData?.categoryDocuments?.length > 0 && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Category Documents</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {documentData?.categoryDocuments?.map((document, index) => (
                            <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {documentData?.quotaDocuments?.length > 0 && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Quota Documents</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {documentData?.quotaDocuments?.map((document, index) => (
                            <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}

            {documentData?.roundDocuments?.length > 0 && <>
                <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>Round Documents</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {documentData?.roundDocuments?.map((document, index) => (
                            <DocumentBox key={index} label={document?.title} value={document?.description} status={document?.onlineStatus} handleClick={() => handleClick(document?.key)} loading={loading} />
                        ))}
                    </div>
                </div>
            </>}
        </div>
    )
}

export default DocumentDetails;