import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

const TabSmall = ({ tabs }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('type');

    const changeTab = (event, newValue) => {
        const value = tabs[newValue];
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', value);
            return Object.fromEntries(newParams.entries());
        });
    };

    return (
        <Tabs className='border-b-2 border-muiLight' variant='scrollable' scrollButtons={false} onChange={changeTab}
            value={tabs.findIndex(tab => tab === activeTab)}
            TabIndicatorProps={{ style: { backgroundColor: '#5D45DB' } }}
        >
            {tabs?.map((tab, index) => (
                <Tab
                    className='h5-s mx-3 b'
                    key={index}
                    label={tab}
                    value={tab.value}
                    disableRipple
                    sx={{
                        textTransform: 'none',
                        '&.Mui-selected': { color: '#5D45DB', fontWeight: 600 },
                        fontFamily: 'Work Sans, sans-serif', color: '#9CA3AF',
                    }}
                />
            ))}
        </Tabs>
    );
};

export default TabSmall;