import React from "react";
import { CloseButton, ConfirmButton } from "../../../../../components/mui/button/Button";
import PortalPopup from "../../../../../components/popups/PortalPopup";

const RejectConfirmationDialogue = ({onClose, onConfirm, message}) => {
    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.8)" placement="Centered"  >
            <div className="flex flex-col p-5 gap-5 xs:gap-10 w-full sm:w-fit bg-white rounded-lg">

                <div className="flex sm:w-[30rem] space-x-5">
                    <img className="col-span-2 w-12 h-12" alt="" loading="eager" src='/icons/tabs/Edit.svg' />
                    
                    <div className="flex flex-col">
                        <h2 className="h4-p">Reject Applicant</h2>
                       <p className="lb-s">Do you confirm that you are going to reject the admission form ?</p>
                    </div>
                </div>

                <div className="flex sm:w-[30rem]">
                  <span className="lb-p font-poppins">{message}</span>  
                </div>

                <div className="flex sm:flex-row justify-between gap-4">
                    <CloseButton onClick={onClose} text='Cancel' fullWidth />
                    <ConfirmButton onClick={() => onConfirm()} text="Confirm" fullWidth />
                </div>
            </div>
        </PortalPopup>
    )
}

export default RejectConfirmationDialogue;