import { Checkbox } from "@mui/material";

import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";
import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';

import { disableTextField } from "../../../../constants/mui";
import { bloodGroups, disabilities, genders, maritalStatus, indianReligions, domains, motherTongues } from "../../../../constants/personal";
import { nationalities } from "../../../../constants/geoConstants";
import renderFormField from "../../../../utils/renderFormField";
import { generateGeoOptions } from "../../../../utils/geoLocationOptions";
import { deleteKeys } from "../../../../utils/formFunctions";

import BottomTile from "./BottomTile";
import AddComponent from "../../../../components/AddComponent";
import { CustomAsyncSelect, CustomMultiCheckboxSelect, CustomTextField } from "../../../../components/mui/Input";

const PersonalDetails = ({ formData, verifiedValues, steps, formOperations, id, isLoading }) => {
    const { handleNestedChange, handleNestedSelectImmediateChange, handleSubmit, updateData, clearForm } = formOperations;

    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const { data: collegeData, isLoading: collegeDataLoading } = useGetData(id ? '/api/employee/hr/recruits/getColleges' : '/api/admission/employee/getColleges')
    const { data: departmentData, isLoading: departmentDataLoading, refreshData: refreshDepartmentData } = useGetData(id ? `/api/employee/hr/recruits/getDepartments/${id}` : '/api/admission/employee/getDepartments', null, null, !!formData?.collegeId);
    const { data: designationData, refreshData: refreshDesignationData } = useGetData(id ? `/api/employee/hr/recruits/getDesignations/${id}` : '/api/admission/employee/getDesignations', null, null, !!formData?.collegeId);
    const { data: categoriesData, refreshData: refreshCategoriesData } = useGetData(id ? `/api/employee/hr/recruits/getCategories/${id}` : '/api/admission/employee/getCategories', null, null, !!formData?.collegeId);
    const { putData: updateCollege, isLoading: updateCollegeLoading } = usePutData();
    const { putData: updateCategory, isLoading: updateCategoryLoading } = usePutData();

    const collegeOptions = collegeData?.map(college => ({ label: college?.name, value: college?._id }));
    const departmentOptions = departmentData?.map(department => ({ label: department?.name, value: department?._id })) || [];
    const designationOptions = designationData?.map(designation => ({ label: designation, value: designation })) || [];
    const categoryOptions = categoriesData?.map(category => ({ value: category, label: category })) || [];

    const { countryOptions, stateOptions: permanentStateOptions, cityOptions: permanentCityOptions } = generateGeoOptions(formData?.personalInfo?.permanentAddress?.country, formData?.personalInfo?.permanentAddress?.state);
    const { stateOptions: residentialStateOptions, cityOptions: residentialCityOptions } = generateGeoOptions(formData?.personalInfo?.residentialAddress?.country, formData?.personalInfo?.residentialAddress?.state);

    const genderOptions = genders.map(gender => ({ value: gender, label: gender }));
    const bloodOptions = bloodGroups.map(blood => ({ value: blood, label: blood }));
    const religionOptions = indianReligions.map(religion => ({ value: religion, label: religion }));
    const nationalityOptions = nationalities.map(nationality => ({ value: nationality, label: nationality }));
    const domainOptions = domains.map(domain => ({ value: domain, label: domain }));
    const motherTongueOptions = motherTongues.map(motherTongue => ({ value: motherTongue, label: motherTongue }));
    const disabilityOptions = disabilities.map(disability => ({ value: disability, label: disability }))

    const admissionFields = [
        { type: 'date', label: "Admission Date", value: formData?.joiningDate, name: 'formData.joiningDate' },
        { type: 'select', label: "Designation", value: formData?.designation, name: "formData.designation", options: designationOptions, noOptionsMessage: 'Please Select College first', loading: updateCollegeLoading },
    ]

    const personalDetailsFields = [
        { type: 'input', label: "Middle Name", value: formData?.middleName, name: 'formData.middleName', disabled: verifiedValues?.middleName?.status === 'Verified' ? true : false, required: false },
        { type: 'input', label: "Last Name", value: formData?.lastName, name: 'formData.lastName', disabled: verifiedValues?.lastName?.status === 'Verified' ? true : false },
        { type: 'select', label: "Gender", value: formData?.personalInfo?.gender, name: "formData.personalInfo.gender", options: genderOptions, disabled: verifiedValues?.personalInfo?.gender?.status === 'Verified' ? true : false },
        { type: 'date', label: "Date of Birth", value: formData?.personalInfo?.dob, name: "formData.personalInfo.dob", disabled: verifiedValues?.personalInfo?.dob?.status === 'Verified' ? true : false },
        { type: 'select', label: "Blood Group", value: formData?.personalInfo?.bloodGroup, name: "formData.personalInfo.bloodGroup", options: bloodOptions, disabled: verifiedValues?.personalInfo?.bloodGroup?.status === 'Verified' ? true : false },
        { type: 'input', label: "Phone Number", value: formData?.phNo, name: "formData.phNo", customType: "number", disabled: verifiedValues?.phNo?.status === 'Verified' ? true : false, InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Alternate Phone Number", value: formData?.alternatePhNo, name: "formData.alternatePhNo", customType: "number", disabled: !!verifiedValues, InputProps: { inputProps: { maxLength: 10 } }, required: false },
        { type: 'input', label: "Alternate Email ID", value: formData?.alternateEmailId, name: "formData.alternateEmailId", inputType: "email", disabled: !!verifiedValues, required: false },
        { type: 'space' },
        { type: 'input', size: 'full', label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address, name: "formData.personalInfo.residentialAddress.address", disabled: verifiedValues?.personalInfo?.residentialAddress?.address?.status === 'Verified' ? true : false, skeletonClassName: 'col-span-full' },
        { type: 'select', label: "Country", value: formData?.personalInfo?.residentialAddress?.country, name: "formData.personalInfo.residentialAddress.country", options: countryOptions, disabled: verifiedValues?.personalInfo?.residentialAddress?.country?.status === 'Verified' ? true : false },
        { type: 'select', label: "State", value: formData?.personalInfo?.residentialAddress?.state, name: "formData.personalInfo.residentialAddress.state", options: residentialStateOptions, noOptionsMessage: 'Select country first', disabled: verifiedValues?.personalInfo?.residentialAddress?.state?.status === 'Verified' ? true : false },
        { type: 'select', label: "City", value: formData?.personalInfo?.residentialAddress?.city, name: "formData.personalInfo.residentialAddress.city", options: residentialCityOptions, noOptionsMessage: 'Select state first', disabled: verifiedValues?.personalInfo?.residentialAddress?.city?.status === 'Verified' ? true : false },
        { type: 'input', label: "Town", value: formData?.personalInfo?.residentialAddress?.town, name: "formData.personalInfo.residentialAddress.town", disabled: verifiedValues?.personalInfo?.residentialAddress?.town?.status === 'Verified' ? true : false },
        { type: 'input', label: "Pincode", value: formData?.personalInfo?.residentialAddress?.pincode, name: "formData.personalInfo.residentialAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } }, disabled: verifiedValues?.personalInfo?.residentialAddress?.pincode?.status === 'Verified' ? true : false },
        { type: 'space' },
    ];

    const permanentAddressDetailsFields = [
        { type: 'input', size: 'full', label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address, name: "formData.personalInfo.permanentAddress.address", disabled: verifiedValues?.personalInfo?.permanentAddress?.address?.status === 'Verified' ? true : false, skeletonClassName: 'col-span-full' },
        { type: 'select', label: "Country", value: formData?.personalInfo?.permanentAddress?.country, name: "formData.personalInfo.permanentAddress.country", options: countryOptions, disabled: verifiedValues?.personalInfo?.permanentAddress?.country?.status === 'Verified' ? true : false },
        { type: 'select', label: "State", value: formData?.personalInfo?.permanentAddress?.state, name: "formData.personalInfo.permanentAddress.state", options: permanentStateOptions, noOptionsMessage: 'Select country first', disabled: verifiedValues?.personalInfo?.permanentAddress?.state?.status === 'Verified' ? true : false },
        { type: 'select', label: "City", value: formData?.personalInfo?.permanentAddress?.city, name: "formData.personalInfo.permanentAddress.city", options: permanentCityOptions, noOptionsMessage: 'Select state first', disabled: verifiedValues?.personalInfo?.permanentAddress?.city?.status === 'Verified' ? true : false },
        { type: 'input', label: "Town", value: formData?.personalInfo?.permanentAddress?.town, name: "formData.personalInfo.permanentAddress.town", disabled: verifiedValues?.personalInfo?.permanentAddress?.town?.status === 'Verified' ? true : false },
        { type: 'input', label: "Pincode", value: formData?.personalInfo?.permanentAddress?.pincode, name: "formData.personalInfo.permanentAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } }, disabled: verifiedValues?.personalInfo?.permanentAddress?.pincode?.status === 'Verified' ? true : false },
    ];

    const identityInfoFields = [
        { type: 'select', label: "Nationality", value: formData?.identityInfo?.nationality, name: "formData.identityInfo.nationality", options: nationalityOptions },
        { type: 'select', label: "Religion", value: formData?.identityInfo?.religion, name: "formData.identityInfo.religion", options: religionOptions },
        { type: 'input', label: "Caste", value: formData?.identityInfo?.caste, name: "formData.identityInfo.caste" },
        { type: 'input', label: "Sub Caste", value: formData?.identityInfo?.subCaste, name: "formData.identityInfo.subCaste", required: false },
    ];

    const financialAndLegalInfoFields = [
        { type: 'input', label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName, name: "formData.financialAndLegalInfo.bankName" },
        { type: 'input', label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo, name: "formData.financialAndLegalInfo.accountNo", customType: "number" },
        { type: 'input', label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc, name: "formData.financialAndLegalInfo.ifsc" },
        { type: 'input', label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo, name: "formData.financialAndLegalInfo.aadharCardNo", customType: "number", InputProps: { inputProps: { maxLength: 12 } } },
        { type: 'input', label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo, name: "formData.financialAndLegalInfo.panCardNo", InputProps: { inputProps: { maxLength: 10 } } },
    ];

    const additionalInfoFields = [
        { type: 'select', label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue, name: "formData.additionalInfo.motherTongue", options: motherTongueOptions },
        { type: 'select', label: "Disability", value: formData?.additionalInfo?.disability, name: "formData.additionalInfo.disability", options: disabilityOptions }
    ];

    const familyFields = [
        { type: 'input', label: "Middle Name", name: 'middleName', required: false },
        { type: 'input', label: "Last Name", name: 'lastName' },
        { type: 'input', label: "Phone Number", name: 'phNo', customType: 'number', InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Email ID", inputType: 'email', name: 'emailId', required: false },
        { type: 'input', label: "Income", name: "income", customType: "number", placeholder: 'Enter your annual Income' },
        { type: 'input', label: "Education", name: "education" },
        { type: 'input', label: "Profession", name: "profession" }
    ]

    const EmergencyPersonFields = [
        { type: 'input', label: "Middle Name", name: 'formData.familyInfo.emergencyPersonDetail.middleName', value: formData?.familyInfo?.emergencyPersonDetail?.middleName, required: false },
        { type: 'input', label: "Last Name", name: 'formData.familyInfo.emergencyPersonDetail.lastName', value: formData?.familyInfo?.emergencyPersonDetail?.lastName },
        { type: 'input', label: "Phone Number", name: 'formData.familyInfo.emergencyPersonDetail.phNo', value: formData?.familyInfo?.emergencyPersonDetail?.phNo, customType: 'number', InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Relation", name: 'formData.familyInfo.emergencyPersonDetail.relation', value: formData?.familyInfo?.emergencyPersonDetail?.relation }
    ]

    const childrenFields = [
        { type: 'input', label: "Name", name: "name" },
        { type: 'date', label: "Date of Birth", name: "dob" },
        { type: 'input', label: "Education", name: "education" }
    ];

    const onConfirmCollege = async (event) => {
        const { message, success } = await updateCollege(`/api/admission/employee/updateCollege?id=${event.target.value}`);
        if (message) return handleSnackbarOpen(message, 'warning')
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
        refreshDepartmentData();
        refreshDesignationData();
        refreshCategoriesData();
        handleNestedSelectImmediateChange(event);
        handleSnackbarOpen("Your College Name Successfully Updated", 'success');
        const updatedFormData = { ...formData }
        deleteKeys(updatedFormData, ['designation', 'departmentIds'])
        updateData((formData) => ({ ...updatedFormData }));
        handleSnackbarOpen("Your College Name Successfully Updated", 'success');
    }

    const handleCollegeChange = (event) => {
        openPopup('Select College', 'Do you want to change College Name? All of your uploaded College Documents may get deleted', 'edit',
            () => onConfirmCollege(event),
            () => handleNestedSelectImmediateChange({ target: { name: 'formData.collegeId', value: formData?.collegeId } }))
    };

    const onConfirmCategory = async (event) => {
        const { message, success } = await updateCategory(`/api/admission/employee/updateCategory?category=${event.target.value}`);
        if (message) return handleSnackbarOpen(message, 'warning')
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
        handleNestedSelectImmediateChange(event);
        handleSnackbarOpen("Your Category Successfully Updated", 'success');
    }

    const handleCategoryChange = (event) => {
        openPopup('Select Category', 'Do you want to change Category? Your uploaded category documents may get deleted', 'edit',
            () => onConfirmCategory(event),
            () => handleNestedSelectImmediateChange({ target: { name: 'formData.identityInfo.category', value: formData?.identityInfo?.category } }))
    };

    const handleChildrenCheckbox = () => {
        if (formData?.familyInfo?.children?.length > 0) {
            let updatedFamilyData = { ...formData.familyInfo };
            delete updatedFamilyData.children;
            handleNestedChange({ target: { name: 'formData.familyInfo', value: updatedFamilyData } });
        } else {
            handleNestedChange({ target: { name: 'formData.familyInfo.children', value: [{}] } });
        }
    };

    const handleMotherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.motherDetail', value: { isDeceased: !formData?.familyInfo?.motherDetail?.isDeceased } } })
    const handleFatherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.fatherDetail', value: { isDeceased: !formData?.familyInfo?.fatherDetail?.isDeceased } } })

    const handleAddressCheckBox = () => {
        if (addressCheckBox()) {
            const updatedFormData = { ...formData }
            deleteKeys(updatedFormData?.personalInfo, ['permanentAddress'])
            updateData((formData) => ({ ...updatedFormData }));
        } else {
            permanentAddressDetailsFields?.forEach(field => {
                handleNestedChange({ target: { name: field?.name, value: formData?.personalInfo?.residentialAddress?.[field?.name?.split('.').pop()] } })
            });
        }
    };

    const addressCheckBox = () => {
        const residentialAddress = formData?.personalInfo?.residentialAddress;
        const permanentAddress = formData?.personalInfo?.permanentAddress;

        if (!residentialAddress || !permanentAddress) return false;

        return Object.keys(residentialAddress).every(k => {
            const resAddress = residentialAddress[k];
            const permAddress = permanentAddress[k];
            return !!resAddress && !!permAddress && resAddress === permAddress;
        });
    };

    const addressCheckBoxDisabled = () => {
        return Object.keys(formData?.personalInfo?.residentialAddress ?? {}).every(k => {
            return formData?.personalInfo?.residentialAddress[k] === '';
        });
    }

    return (
        <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 lg:px-14 sm:px-8 py-10 w-full bg-tertiary border-2">
                <h5 className="h4-p col-span-full">Academic Info</h5>
                <div className="col-span-full grid sm:grid-cols-2 gap-5 lg:gap-x-14">
                    <CustomAsyncSelect
                        className="bg-white"
                        label="College"
                        options={collegeOptions}
                        value={formData?.collegeId}
                        onChange={handleCollegeChange}
                        name="formData.collegeId"
                        disabled={verifiedValues?.collegeName?.status === 'Verified' ? true : false}
                        loading={updateCollegeLoading}
                        skeletonLoading={isLoading || collegeDataLoading}
                    />

                    <CustomTextField
                        className="bg-white"
                        label="Email ID"
                        value={formData?.emailId}
                        sx={disableTextField}
                        name="formData.emailId"
                        disabled={verifiedValues?.emailId?.status === 'Verified' ? true : false}
                        skeletonLoading={isLoading || collegeDataLoading}
                    />
                </div>

                <CustomMultiCheckboxSelect
                    className='col-span-full bg-white'
                    loading={departmentDataLoading || updateCollegeLoading}
                    label='Department'
                    value={formData?.departmentIds ?? []}
                    name='formData.departmentIds'
                    onChange={handleNestedChange}
                    options={departmentOptions}
                    color='secondary'
                    required={false}
                    disabled={verifiedValues?.departmentIds?.status === 'Verified' ? true : false}
                    skeletonLoading={isLoading || collegeDataLoading}
                    skeletonClassName='col-span-full'
                    noOptionsMessage='Please Select College first'
                />

                {admissionFields.map((field, index) => renderFormField({ ...field, disabled: verifiedValues?.[field?.name?.split('.').pop()]?.status === 'Verified' ? true : false, onChange: handleNestedChange }, index, isLoading || collegeDataLoading))}

                <CustomAsyncSelect
                    className="bg-white"
                    label="Category"
                    options={categoryOptions}
                    value={formData?.identityInfo?.category}
                    onChange={handleCategoryChange}
                    name="formData.identityInfo.category"
                    required={false}
                    disabled={verifiedValues?.identityInfo?.category?.status === 'Verified' ? true : false}
                    loading={updateCategoryLoading || updateCollegeLoading}
                    skeletonLoading={isLoading || collegeDataLoading}
                    noOptionsMessage='Please Select College first'
                />

            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 lg:px-14 sm:px-8 py-10 w-full border-2">
                <h5 className="h4-p col-span-full">Personal Info</h5>
                <div className="flex w-full">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                        name='formData.title' value={formData?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        skeletonLoading={isLoading || collegeDataLoading}
                        skeletonClassName='rounded-r-none'
                    />

                    <CustomTextField
                        label="First Name" name='formData.firstName' value={formData?.firstName} onChange={handleNestedChange} disabled={verifiedValues?.firstName?.status === 'Verified' ? true : false} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading || collegeDataLoading}
                    />
                </div>

                {personalDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading || collegeDataLoading))}

                {isLoading || collegeDataLoading ?
                    <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                    : <div className="col-span-full flex items-center">
                        <Checkbox id="address-checkbox" checked={addressCheckBox()} color="secondary" disabled={!!verifiedValues || addressCheckBoxDisabled()} onChange={handleAddressCheckBox} />
                        <label htmlFor="address-checkbox" className="text-s cursor-pointer">Set Permanent Address same as Residential Address</label>
                    </div>}

                {!addressCheckBox() && permanentAddressDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading || collegeDataLoading))}
            </div>

            <div className="flex flex-col gap-10 px-5 lg:px-14 sm:px-8 py-10 w-full bg-tertiary border-2">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Identity Info</h5>
                    {identityInfoFields.map((field, index) => (renderFormField({ ...field, disabled: verifiedValues?.identityInfo?.[field?.name?.split('.').pop()]?.status === 'Verified' ? true : false, onChange: handleNestedChange }, index, isLoading || collegeDataLoading)))}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Financial & Legal Info</h5>
                    {financialAndLegalInfoFields.map((field, index) => (renderFormField({ ...field, disabled: verifiedValues?.financialAndLegalInfo?.[field?.name?.split('.').pop()]?.status === 'Verified' ? true : false, onChange: handleNestedChange }, index, isLoading || collegeDataLoading)))}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Additional Info</h5>
                    {additionalInfoFields.map((field, index) => (renderFormField({ ...field, disabled: verifiedValues?.additionalInfo?.[field?.name?.split('.').pop()]?.status === 'Verified' ? true : false, onChange: handleNestedChange }, index, isLoading || collegeDataLoading)))}
                    <CustomAsyncSelect
                        className='bg-white' label="Marital Status"
                        options={maritalStatus.map(option => ({ value: option, label: option }))} onChange={(e) => {
                            if (e.target.value !== 'Married') {
                                delete formData?.familyInfo?.partnerDetail;
                            }; handleNestedChange(e);
                        }}
                        disabled={verifiedValues?.additionalInfo?.maritalStatus?.status === 'Verified' ? true : false}
                        name='formData.additionalInfo.maritalStatus'
                        value={formData?.additionalInfo?.maritalStatus}
                        skeletonLoading={isLoading || collegeDataLoading}
                    />
                    <CustomMultiCheckboxSelect
                        className='col-span-full bg-white'
                        color='secondary'
                        label='Domain'
                        value={formData?.additionalInfo?.domain ?? []}
                        name='formData.additionalInfo.domain'
                        onChange={handleNestedChange}
                        options={domainOptions}
                        disabled={!!verifiedValues}
                        skeletonLoading={isLoading || collegeDataLoading}
                        skeletonClassName='col-span-full'
                    />
                </div>
            </div>

            <div className="flex flex-col gap-5 px-5 lg:px-14 sm:px-8 py-10 w-full border-2">
                {formData?.additionalInfo?.maritalStatus === 'Married' &&
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                        <h5 className="h4-p col-span-full">Partner Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                                name='formData.familyInfo.partnerDetail.title' value={formData?.familyInfo?.partnerDetail?.title}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='border-r-none' skeletonLoading={isLoading || collegeDataLoading}
                            />

                            <CustomTextField
                                label="First Name" name='formData.familyInfo.partnerDetail.firstName' value={formData?.familyInfo?.partnerDetail?.firstName} onChange={handleNestedChange} disabled={verifiedValues?.familyInfo?.partnerDetail?.firstName?.status === 'Verified' ? true : false}
                                fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading || collegeDataLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (renderFormField({
                            ...field,
                            value: formData?.familyInfo?.partnerDetail?.[field.name],
                            name: `formData.familyInfo.partnerDetail.${field?.name}`,
                            disabled: verifiedValues?.familyInfo?.partnerDetail?.[field?.name]?.status === 'Verified' ? true : false,
                            onChange: handleNestedChange
                        }, index, isLoading || collegeDataLoading)))}
                    </div>}

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {!formData?.familyInfo?.fatherDetail?.isDeceased && <><h5 className="h4-p col-span-full">Father Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                                name='formData.familyInfo.fatherDetail.title'
                                value={formData?.familyInfo?.fatherDetail?.title}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='rounded-r-none'
                                skeletonLoading={isLoading || collegeDataLoading}
                            />
                            <CustomTextField
                                label="Father First Name" name='formData.familyInfo.fatherDetail.firstName' value={formData?.familyInfo?.fatherDetail?.firstName} onChange={handleNestedChange}
                                fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={verifiedValues?.familyInfo?.fatherDetail?.firstName?.status === 'Verified' ? true : false}
                                skeletonLoading={isLoading || collegeDataLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (renderFormField({
                            ...field,
                            value: formData?.familyInfo?.fatherDetail?.[field?.name],
                            name: `formData.familyInfo.fatherDetail.${field?.name}`,
                            disabled: verifiedValues?.familyInfo?.fatherDetail?.[field?.name]?.status === 'Verified' ? true : false,
                            onChange: handleNestedChange
                        }, index, isLoading || collegeDataLoading)))}
                    </>}

                    {isLoading || collegeDataLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="father-checkbox" checked={formData?.familyInfo?.fatherDetail?.isDeceased} color="secondary" disabled={!!verifiedValues} onChange={handleFatherDeceased} />
                            <label htmlFor="father-checkbox" className="text-s cursor-pointer">Is your father deceased?</label>
                        </div>}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {!formData?.familyInfo?.motherDetail?.isDeceased && <>
                        <h5 className="h4-p col-span-full">Mother Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange}
                                name='formData.familyInfo.motherDetail.title' value={formData?.familyInfo?.motherDetail?.title} disabled={!!verifiedValues}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='rounded-r-none'
                                skeletonLoading={isLoading || collegeDataLoading}
                            />

                            <CustomTextField
                                label="Mother First Name" name='formData.familyInfo.motherDetail.firstName' value={formData?.familyInfo?.motherDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={verifiedValues?.familyInfo?.motherDetail?.firstName?.status === 'Verified' ? true : false} skeletonLoading={isLoading || collegeDataLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (
                            renderFormField({
                                ...field,
                                value: formData?.familyInfo?.motherDetail?.[field.name],
                                name: `formData.familyInfo.motherDetail.${field?.name}`,
                                required: ['phNo'].includes(field?.name) ? false : field?.required,
                                disabled: verifiedValues?.familyInfo?.motherDetail?.[field?.name]?.status === 'Verified' ? true : false,
                                onChange: handleNestedChange
                            }, index, isLoading || collegeDataLoading)
                        ))}
                    </>}
                    {isLoading || collegeDataLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="mother-checkbox" checked={formData?.familyInfo?.motherDetail?.isDeceased} color="secondary" disabled={!!verifiedValues} onChange={handleMotherDeceased} />
                            <label htmlFor="mother-checkbox" className="text-s cursor-pointer">Is your mother deceased?</label>
                        </div>}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {isLoading || collegeDataLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="children-checkbox" checked={!!formData?.familyInfo?.children?.length} className='bg-purple-800' color="secondary" onChange={handleChildrenCheckbox} disabled={!!verifiedValues} />
                            <label htmlFor="children-checkbox" className="text-s cursor-pointer">Do you have children?</label>
                        </div>}

                    {formData?.familyInfo?.children?.length > 0 && (
                        <AddComponent array={formData?.familyInfo?.children} name='formData.familyInfo.children' properties={childrenFields} label='Children' handleChange={handleNestedChange} loading={isLoading || collegeDataLoading} verifiedValues={!!verifiedValues && 'accepted'} />
                    )}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Emergency Person Details</h5>
                    <div className="flex w-full">
                        <CustomAsyncSelect
                            className='bg-white w-24' label="Title"
                            options={[
                                { value: 'Dr', label: 'Dr' },
                                { value: 'Mr', label: 'Mr' },
                                { value: 'Mrs', label: 'Mrs' },
                                { value: 'Ms', label: 'Ms' },
                            ]} onChange={handleNestedChange}
                            name='formData.familyInfo.emergencyPersonDetail.title' value={formData?.familyInfo?.emergencyPersonDetail?.title} disabled={!!verifiedValues}
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            skeletonClassName='rounded-r-none'
                            skeletonLoading={isLoading || collegeDataLoading}
                        />

                        <CustomTextField
                            label="First Name" name='formData.familyInfo.emergencyPersonDetail.firstName' value={formData?.familyInfo?.emergencyPersonDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={!!verifiedValues} skeletonLoading={isLoading || collegeDataLoading}
                        />
                    </div>

                    {EmergencyPersonFields.map((field, index) => (
                        renderFormField({ ...field, disabled: !!verifiedValues, onChange: handleNestedChange }, index, isLoading || collegeDataLoading)
                    ))}
                </div>
            </div>
            <BottomTile id={id} clearForm={clearForm} steps={steps} />
        </form>
    );
};

export default PersonalDetails;