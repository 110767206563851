export const degreeTypes = [
    'Regular', 'Online'
];

export const boards = [
    'Andhra Pradesh Board of Secondary Education',
    'Assam Higher Secondary Education Council',
    'Bihar School Examination Board',
    'Board of Intermediate Education, Andhra Pradesh',
    'Board of School Education Haryana',
    'Board of Secondary Education, Assam',
    'Board of Secondary Education, Odisha',
    'Board of Secondary Education, Rajasthan',
    'Central Board of Secondary Education',
    'Chhattisgarh Board of Secondary Education',
    'Council for the Indian School Certificate Examinations',
    'Council of Higher Secondary Education, Manipur',
    'Council of Higher Secondary Education, Odisha',
    'Department of Pre University Education, Karnataka',
    'Goa Board of Secondary and Higher Secondary Education',
    'Gujarat Secondary and Higher Secondary Education Board',
    'Himachal Pradesh Board of School Education',
    'Jammu and Kashmir State Board of School Education',
    'Jharkhand Academic Council',
    'Karnataka Secondary Education Examination Board',
    'Kerala Board of Public Examinations',
    'Madhya Pradesh Board of Secondary Education',
    'Maharashtra State Board of Secondary and Higher Secondary Education',
    'Manipur Board of Secondary Education',
    'Meghalaya Board of School Education',
    'Mizoram Board of School Education',
    'Nagaland Board of School Education',
    'National Institute of Open Schooling',
    'Punjab School Education Board',
    'Rajasthan Board of Secondary Education',
    'Tamil Nadu Board of Higher Secondary Education',
    'Tamil Nadu Board of Secondary Education',
    'Telangana Board of Secondary Education',
    'Telangana State Board of Intermediate Education',
    'Tripura Board of Secondary Education',
    'Uttar Pradesh Madhyamik Shiksha Parishad',
    'Uttarakhand Board of School Education',
    'West Bengal Board of Secondary Education',
    'West Bengal Council of Higher Secondary Education'
];

export const certification = [
    'Amazon Web Services Certified Solutions Architect',
    'Certified Ethical Hacker',
    'Certified Information Systems Security Professional',
    'Certified Public Accountant',
    'Certified ScrumMaster',
    'Cisco Certified Network Associate',
    'Google Cloud Professional Data Engineer',
    'Microsoft Certified: Azure Solutions Architect Expert',
    'Project Management Professional',
    'Six Sigma Green Belt'
];

export const doctorate = [
    'Doctor of Business Administration',
    'Doctor of Education in Educational Leadership',
    'Doctor of Engineering',
    'Doctor of Philosophy in Biology',
    'Doctor of Philosophy in Chemistry',
    'Doctor of Philosophy in Computer Science',
    'Doctor of Philosophy in Economics',
    'Doctor of Philosophy in Mathematics',
    'Doctor of Philosophy in Physics',
    'Doctor of Philosophy in Psychology'
];

export const postGraduation = [
    'Master of Architecture',
    'Master of Arts in English',
    'Master of Business Administration',
    'Master of Education',
    'Master of Fine Arts',
    'Master of Laws',
    'Master of Public Health',
    'Master of Science in Physics',
    'Master of Social Work',
    'Master of Technology in Computer Science'
];

export const underGraduation = [
    'Bachelor of Architecture',
    'Bachelor of Arts in History',
    'Bachelor of Business Administration',
    'Bachelor of Commerce',
    'Bachelor of Education',
    'Bachelor of Fine Arts',
    'Bachelor of Laws',
    'Bachelor of Physiotherapy', 
    'Bachelor of Medicine', 
    'Bachelor of Surgery',
    'Bachelor of Science in Chemistry',
    'Bachelor of Technology in Mechanical Engineering'
];

export const diplomas = [
    'Diploma in Animation and Multimedia',
    'Diploma in Business Management',
    'Diploma in Computer Applications',
    'Diploma in Digital Marketing',
    'Diploma in Engineering',
    'Diploma in Fashion Design',
    'Diploma in Financial Accounting',
    'Diploma in Fire Safety and Hazard Management',
    'Diploma in Graphic Design',
    'Diploma in Hotel Management',
    'Diploma in Interior Design',
    'Diploma in Journalism and Mass Communication',
    'Diploma in Medical Lab Technology',
    'Diploma in Nursing',
    'Diploma in Pharmacy',
    'Diploma in Radiology',
    'Diploma in Travel and Tourism'
];

export const diplomaMajors = [
    "Accounting",
    "Automotive Technology",
    "Aviation Maintenance Technology",
    "Business Administration",
    "Civil Engineering Technology",
    "Computer Science",
    "Culinary Arts",
    "Digital Media",
    "Early Childhood Education",
    "Electrical Engineering Technology",
    "Environmental Science",
    "Event Management",
    "Fashion Design",
    "Film and Video Production",
    "Graphic Design",
    "Health Information Management",
    "Hospitality Management",
    "Human Resources Management",
    "Information Technology",
    "Journalism",
    "Legal Studies",
    "Logistics and Supply Chain Management",
    "Marketing",
    "Mechanical Engineering Technology",
    "Nursing",
    "Paralegal Studies",
    "Pharmacy Technician",
    "Sports Management",
    "Tourism Management",
    "Web Development"
];

export const undergraduateMajors = [
    "Accounting",
    "Anthropology",
    "Art History",
    "Biology",
    "Business Administration",
    "Chemistry",
    "Civil Engineering",
    "Computer Science",
    "Economics",
    "Education",
    "Electrical Engineering",
    "English Literature",
    "Environmental Science",
    "Finance",
    "Graphic Design",
    "History",
    "International Relations",
    "Journalism",
    "Marketing",
    "Mathematics",
    "Mechanical Engineering",
    "Music",
    "Nursing",
    "Physics",
    "Political Science",
    "Psychology",
    "Public Health",
    "Sociology",
    "Theatre Arts"
];

export const postgraduateMajors = [
    "MBA",
    "MS in Marketing",
    "MS in Accounting",
    "MS in Finance",
    "MA in Economics",
    "MS in Computer Science",
    "MS in Information Technology",
    "MS in Data Science",
    "MS in Cybersecurity",
    "MS in Artificial Intelligence",
    "MS in Mechanical Engineering",
    "MS in Civil Engineering",
    "MS in Electrical Engineering",
    "MS in Biology",
    "MS in Chemistry",
    "MS in Physics",
    "MS in Mathematics",
    "MS in Environmental Science",
    "MA in Psychology",
    "MA in Sociology",
    "MS in Anthropology",
    "MA in International Relations",
    "MA in English Literature",
    "MA in History",
    "MFA in Graphic Design",
    "MA in Journalism",
    "Master of Music",
    "MFA in Theatre Arts",
    "Master of Public Health",
    "Master of Education"
];

export const admissionTypeOptions = ['Regular', 'Lateral Entry', 'Transfer']
