import React, { useState } from 'react'
import { useSetRecoilState } from "recoil";
import { useSearchParams, useNavigate } from 'react-router-dom'

import { loadingState } from "../../../../recoil/state";
import useGetData from '../../../../hooks/useGetData'
import useFormOperations from '../../../../hooks/useFormOperations'
import usePutData from '../../../../hooks/usePutData'
import useSnackbar from '../../../../hooks/useSnackbar';

import UpdateInfoImg from '../../../../assets/images/UpdateInfo.webp'

import ChangesInfo from './ChangesInfo'
import PersonalInfo from './informationTabs/PersonalInfo'
import EducationInfo from './informationTabs/EducationInfo'
import EmploymentInfo from './informationTabs/EmploymentInfo'
import IdentityInfo from './informationTabs/IdentityInfo'
import ContactInfo from './informationTabs/ContactInfo'
import FinanceInfo from './informationTabs/FinanceInfo'
import AddressInfo from './informationTabs/AddressInfo'
import FamilyInfo from './informationTabs/FamilyInfo'
import TabSmall from '../../../../components/mui/TabSmall'
import { CustomTextField } from '../../../../components/mui/Input'
import UpdateChangesPopup from '../../../../components/popups/localPopup/settings/UpdateChangesPopup'
import PreviewChangesPopup from '../../../../components/popups/localPopup/settings/PreviewChangesPopup'
import { ConfirmButton, CloseButton, PurpleButton } from '../../../../components/mui/button/Button'

export const UpdateInfo = () => {
    const { data: employeeData, isLoading } = useGetData('/api/employee/personal/getCurrent')
    const { putData } = usePutData();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [openChangesPopup, setOpenChangesPopup] = useState(false)
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)

    const activeTab = searchParams.get('type');

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/personal/updateCurrentDetails`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen('Information Updated Successfully!', 'success');
        navigate('/admin/settings')
    }

    const { formData, handleNestedChange, handleChange, handleSubmit, onDiscard, clearForm, setValue } = useFormOperations(employeeData, onSubmit, "", employeeData);

    const handleField = () => {
    }

    const handleDiscard = () => {
        clearForm();
        navigate('/admin/settings');
    }

    return (
        <div className='grid grid-cols-20 w-full gap-4 bg-white p-5 min-h-svh'>
            {/* <div className='col-span-full lg:col-span-14 hidden md:flex flex-row items-center text-left px-5 py-4 gap-4 lg:gap-6 bg-white drop-shadow rounded-lg h-full'>
                <img src={UpdateInfoImg} alt="missedImage" className='h-48' />
                <div className='flex flex-col justify-center space-y-1'>
                    <h5 className='h4-p text-xl'>Missed Something! Still not late!</h5>
                    <p className='lb-s'>These results are stepping stones to your academic Everest. Let them fuel your curiosity and guide you towards even greater brilliance!</p>
                </div>
            </div> */}

            <div className='col-span-full lg:col-span-6'>
                <ChangesInfo handlePreviewClick={() => setOpenChangesPopup(true)} />
            </div>

            <div className='col-span-full flex flex-col bg-white drop-shadow rounded-lg p-5 md:pb-8 md:px-8 gap-8'>
                <div className='flex justify-center lg:justify-start w-full'>
                    <TabSmall
                        tabs={['Personal', 'Education', 'Address', 'Identity', 'Family', "Finance", 'Communication', 'Employment']} />
                </div>
                <div className='flex flex-col w-full'>
                    {activeTab === "Personal" && <PersonalInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                    {activeTab === "Education" && <EducationInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                    {activeTab === "Employment" && <EmploymentInfo formData={formData} isLoading={isLoading} />}
                    {activeTab === "Address" && <AddressInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                    {activeTab === "Identity" && <IdentityInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                    {activeTab === "Communication" && <ContactInfo defaultContactInfo={employeeData} isLoading={isLoading} />}
                    {activeTab === "Family" && <FamilyInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                    {activeTab === "Finance" && <FinanceInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}
                </div>
            </div>
            {!(activeTab === "Communication" || activeTab === "Employment") && <div className='col-span-full flex flex-col bg-white drop-shadow rounded-lg p-5 md:p-8 gap-5 xs:gap-8'>
                <CustomTextField label='Reason' multiline rows={4} className='no-scrollbar' />
                <div className='flex flex-col-reverse xs:flex-row justify-end gap-3 xs:gap-5 w-full'>
                    <CloseButton size='large' text="Discard" onClick={handleDiscard} />
                    {/* <PurpleButton size='large' text='Preview' onClick={() => setOpenPreviewPopup(true)} /> */}
                    <ConfirmButton size='large' text='Submit' disabled={handleField()} onClick={handleSubmit} />
                    {/* <ConfirmButton text='Submit' disabled={handleField()} onClick={() => navigate('/admin/settings/submitDocument')} /> */}
                </div>
            </div>}

            {openChangesPopup && <UpdateChangesPopup onClose={() => setOpenChangesPopup(false)} />}
            {openPreviewPopup && <PreviewChangesPopup onClose={() => setOpenPreviewPopup(false)} />}

        </div>
    )
}

export default UpdateInfo;