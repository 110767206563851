import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { ArrowBack, ArrowForward, InfoOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { useRecoilState } from "recoil";

import { loadingState } from "../../../../recoil/state";

import useSteppers from '../../../../hooks/useSteppers';
import useSnackbar from '../../../../hooks/useSnackbar';
import usePutData from "../../../../hooks/usePutData";
import useGetData from '../../../../hooks/useGetData';
import useViewPopup from '../../../../hooks/useViewPopup';

import UploadDocumentTile from '../../../../components/upload/UploadDocumentTile'
import { ConfirmButton, PurpleButton } from '../../../../components/mui/button/Button'
import BlackToolTip from '../../../../components/mui/BlackToolTip';
import Pending from "../../../../assets/lotties/Pending.json";

const UploadDocuments = ({ id, formData, submitted, steps }) => {
  const { isBackwardDisabled, isForwardDisabled, handleBackward, handleForward } = useSteppers(steps);

  const { data: documentsList, success, isLoading: documentsListLoading, updateData } = useGetData(id ? `/api/employee/hr/recruits/getDocuments/${id}` : '/api/admission/employee/getDocuments');
  const { putData } = usePutData();

  const location = useLocation()
  const navigate = useNavigate();
  const { openPopup } = useViewPopup();
  const { handleSnackbarOpen } = useSnackbar();
  const [, setLoading] = useRecoilState(loadingState);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const updateDocumentStatus = (documentKey, newStatus) => {
    updateData(prevState => {
      const updatedDocuments = { ...prevState };
      Object.keys(updatedDocuments).forEach(category => {
        updatedDocuments[category] = updatedDocuments[category].map(doc =>
          doc.key === documentKey ? { ...doc, onlineStatus: newStatus } : doc
        );
      });
      return updatedDocuments;
    });
  };

  const allDocumentsSubmitted = () => {
    for (const category in documentsList) {
      if (documentsList.hasOwnProperty(category)) {
        for (const document of documentsList[category]) {
          if (document?.onlineStatus === "Pending" || document?.onlineStatus === "Rejected") return false;
        }
      }
    }
    return true;
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const { data, message, success } = await putData(id ? `/api/employee/hr/recruits/submitAdminChanges/${id}` : '/api/admission/employee/submitAdmission', id && formData);
    setLoading(false);
    if (message) return handleSnackbarOpen(message, 'warning');
    if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
    if (data?.reSubmitted) {
      handleSnackbarOpen('Your changes are successfully saved. We will update you soon.', 'success');
      return navigate('/admissions/employee/profile?type=Personal');
    }
    if (id) {
      handleSnackbarOpen('Recruit changes are successfully saved', 'success');
      return navigate(`/admin/employee/recruits/onlineVerify/${id}`)
    } else {
      openPopup('Form Submitted Successfully', `Your admission form has been submitted successfully and your Admission Id is ${data?.admissionId}. Save this Id for further reference, though we have already mailed you. Thank you for choosing our college.`, 'success', () => navigate('/admissions/employee/profile?type=Personal'))
    }
  }

  const renderLoadingTiles = (count = 5) =>
    Array.from({ length: count }, (_, index) => <UploadDocumentTile key={`loading-${index}`} loading />);

  const generateUploadUrl = (key) => id ? `/api/employee/hr/recruits/getUploadURL/${id}?key=${key}` : `/api/admission/employee/getUploadURL?key=${key}`;
  const generateViewUrl = (key) => id ? `/api/employee/hr/recruits/getViewURL/${id}?key=${key}` : `/api/admission/employee/getViewURL?key=${key}`;
  const generateDeleteUrl = (key) => id ? `/api/employee/hr/recruits/deleteUploadedObject/${id}?key=${key}` : `/api/admission/employee/deleteUploadedObject?key=${key}`;

  return (
    <div className='flex flex-col'>
      {!documentsListLoading && !success ?
        <div className='flex flex-col p-5 xs:p-10 text-center border-2 gap-5 h-fit'>
          <Lottie className='h-40' animationData={Pending} loop={true} />
          <p className='flex flex-col text-s font-normal'>
            <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
            <span> Please select your <span className='font-semibold text-slate-500'>Program name, Academic information, and Category</span>.
              Before proceeding to the Document Section. </span>
          </p>
        </div>

        :

        <div>
          <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 bg-tertiary border-2'>
            <h5 className="h4-p">General Proofs</h5>
            {documentsListLoading
              ? renderLoadingTiles() : !documentsList?.commonDocuments ?
                <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                  <Lottie className='h-40' animationData={Pending} loop={true} />
                  <p className='flex flex-col text-s font-normal'>
                    <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                    <span> Please select your <span className='font-semibold text-slate-500'>College</span>.
                      Before proceeding to the Document Section. </span>
                  </p>
                </div>
                : documentsList?.commonDocuments?.map((doc, index) => (
                  <UploadDocumentTile uploadURL={generateUploadUrl(doc?.key)} viewURL={generateViewUrl(doc?.key)} deleteURL={generateDeleteUrl(doc?.key)} index={index} doc={doc} updateStatus={updateDocumentStatus} />
                ))}
          </div>

          <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2 bg-transparent'>
            <div className='flex justify-between w-full pr-5'>
              <h5 className="h4-p">Education Proofs</h5>
              <BlackToolTip title='Update your academic details before coming to these section'>
                <InfoOutlined className='text-slate-500' />
              </BlackToolTip>
            </div>

            {documentsListLoading
              ? renderLoadingTiles() : !documentsList?.academicDocuments ?
                <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                  <Lottie className='h-40' animationData={Pending} loop={true} />
                  <p className='flex flex-col text-s font-normal'>
                    <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                    <span> Please save your <span className='font-semibold text-slate-500'>Education Info first</span>.
                      Before proceeding to the Document Section. </span>
                  </p>
                </div>
                : documentsList?.academicDocuments?.map((doc, index) => (
                  <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} key={index} doc={doc} updateStatus={updateDocumentStatus} />
                ))}
          </div>

          {documentsList?.categoryDocuments?.length !== 0 &&
            <div className='flex flex-col gap-5 w-full px-5 lg:px-14 sm:px-8 py-10 border-2 bg-zinc-100'>
              <h5 className="h4-p">Category Related Proofs</h5>
              {documentsListLoading
                ? renderLoadingTiles(2) : !documentsList?.categoryDocuments ?
                  <div className='flex flex-col p-5 text-center gap-5 h-fit'>
                    <Lottie className='h-40' animationData={Pending} loop={true} />
                    <p className='flex flex-col text-s font-normal'>
                      <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
                      <span> Please select your <span className='font-semibold text-slate-500'>Category</span>.
                        Before proceeding to the Document Section. </span>
                    </p>
                  </div>
                  : documentsList?.categoryDocuments?.map((doc, index) => (
                    <UploadDocumentTile uploadURL={generateUploadUrl(doc.key)} viewURL={generateViewUrl(doc.key)} deleteURL={generateDeleteUrl(doc?.key)} index={index} doc={doc} updateStatus={updateDocumentStatus} />
                  ))}
            </div>}

          <div className='flex flex-col gap-7 p-8 sm:p-14 bg-white border-b-2 border-x-2'>
            {!(id && location.pathname.startsWith('/admin')) && <div className="col-span-full flex items-center">
              <Checkbox id="confirm-checkbox" checked={isConfirmed} color="secondary" onChange={() => setIsConfirmed(!isConfirmed)} />
              <label htmlFor="confirm-checkbox" className="text-s cursor-pointer">I agree to all the terms and conditions of the college and submitting the form with my acknowledgement.</label>
            </div>}
            <div className='flex flex-col sm:flex-row justify-between gap-5'>
              {id && <div className='flex justify-between sm:justify-start w-full sm:w-fit gap-5'>
                <PurpleButton disabled={isBackwardDisabled} onClick={handleBackward} text={<ArrowBack sx={{ color: 'white' }} />} />
                <PurpleButton disabled={isForwardDisabled} onClick={handleForward} text={<ArrowForward sx={{ color: 'white' }} />} />
              </div>}

              {id && location.pathname.startsWith('/admin') ? <PurpleButton size='large' disabled={!(documentsList?.commonDocuments && documentsList?.academicDocuments && documentsList?.categoryDocuments)} type="button" text='Confirm Changes' onClick={handleFormSubmit} /> : <ConfirmButton size='large' disabled={!(documentsList?.commonDocuments && documentsList?.academicDocuments && documentsList?.categoryDocuments) || !isConfirmed || !allDocumentsSubmitted()} type="button" text={submitted ? 'Re-Submit Application' : 'Submit Application'} onClick={handleFormSubmit} />}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UploadDocuments;