import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import useSteppers from "../../hooks/useSteppers";


const Steppers = ({ steps, className = 'w-full' }) => {
    const { activeStep, activeStepIndex, changeStep } = useSteppers(steps);
    return (
        <Stepper className={className} nonLinear activeStep={activeStep} alternativeLabel
            sx={{ '& .MuiStepConnector-line': { borderColor: '#eaeaf0', borderWidth: 2, borderRadius: 1 } }}
        >
            {steps.map((label, index) => (
                <Step
                    key={label} active={label === activeStep} completed={index < activeStepIndex}
                    sx={{
                        "& .MuiStepLabel-root .Mui-completed": { color: "#4f38c9" },
                        "& .MuiStepLabel-root .Mui-active": { color: "#4f38c9" },
                    }}
                >
                    <StepButton style={{ color: '#4f38c9' }}
                        onClick={() => changeStep(index)}
                        disableRipple>
                        {label}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    );
}

export default Steppers;
