import React from "react";

const Hobbies = ({ title, data, loading }) => {
    return (
        <div className="flex flex-col space-y-3">
            <div className='flex items-center space-x-4'>
                <div className='lb-s whitespace-nowrap'>{title}</div>
                <hr className='w-full h-0.5 bg-slate-400' />
            </div>
            <div className='flex flex-wrap'>
                {loading ? Array.from({ length: 3}).map((_, index) => <div key={index} className='h-7 w-full mx-1 my-1 border-[2px] border-muiLight rounded-full bg-muiLight animate-pulse' />) : data?.length > 0 ? data?.map((item, index) =>
                    <div className='py-1 px-4 mx-1 my-1 border-[2px] border-slate-300 rounded-full text-s text-slate-500' key={index}>
                        {item}
                    </div>) : <span className="text-p">N/A</span>
                }
            </div>
        </div>
    )
}

export default Hobbies;