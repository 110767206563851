import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import { ArrowBack, EditOutlined, DeleteOutline } from "@mui/icons-material";

import { loadingState } from "../../../../../recoil/state";
import usePopup from "../../../../../hooks/usePopup";
import usePutData from "../../../../../hooks/usePutData"
import useSnackbar from "../../../../../hooks/useSnackbar";

import TextBox from "../../../../../components/mui/text/TextBox";
import { CustomCheckBox, CustomTextField } from "../../../../../components/mui/Input";
import { CloseButton, ConfirmButton, PurpleButton } from "../../../../../components/mui/button/Button";
import BlackToolTip from "../../../../../components/mui/BlackToolTip";
import { dateFormat } from "../../../../../utils/dateFormat";

const VerifyData = ({ id, documentOpen, formData, isLoading, formOperations, onlineStatus }) => {
    const { masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit } = formOperations;
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const handleBackward = () => navigate('/admin/student/applicants');
    const [remark, setRemark] = useState('');

    const generalInfoFields = [
        { label: "College Name", value: formData?.collegeName?.status, data: formData?.collegeName?.data, name: 'formData.collegeName.status' },
        { label: "Admission Year", value: formData?.admissionYear?.status, data: formData?.admissionYear?.data, name: 'formData.admissionYear.status' },
        { label: "Email ID", value: formData?.emailId?.status, data: formData?.emailId?.data, name: 'formData.emailId.status' },
        { label: "Program Name", value: formData?.programName?.status, data: formData?.programName?.data, name: 'formData.programName.status' },
        { label: "Round Type", value: formData?.roundType?.status, data: formData?.roundType?.data, name: 'formData.roundType.status' },
        { label: "Round ID", value: formData?.roundId?.status, data: formData?.roundId?.data, name: 'formData.roundId.status' },
        { label: "Admission Quota", value: formData?.admissionQuota?.status, data: formData?.admissionQuota?.data, name: 'formData.admissionQuota.status' }
    ];

    const personalInfoFields = [
        { label: "First Name", value: formData?.firstName?.status, data: formData?.firstName?.data, name: 'formData.firstName.status' },
        { label: "Middle Name", value: formData?.middleName?.status, data: formData?.middleName?.data, name: 'formData.middleName.status' },
        { label: "Last Name", value: formData?.lastName?.status, data: formData?.lastName?.data, name: 'formData.lastName.status' },
        { label: "Gender", value: formData?.personalInfo?.gender?.status, data: formData?.personalInfo?.gender?.data, name: 'formData.personalInfo.gender.status' },
        { label: "Date of Birth", value: formData?.personalInfo?.dob?.status, data: dateFormat(formData?.personalInfo?.dob?.data), name: 'formData.personalInfo.dob.status' },
        { label: "Blood Group", value: formData?.personalInfo?.bloodGroup?.status, data: formData?.personalInfo?.bloodGroup?.data, name: 'formData.personalInfo.bloodGroup.status' },
        { label: "Phone Number", value: formData?.phNo?.status, data: formData?.phNo?.data, name: 'formData.phNo.status' },
    ];

    const residentialAddressInfoFields = [
        { label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address?.status, data: formData?.personalInfo?.residentialAddress?.address?.data, name: 'formData.personalInfo.residentialAddress.address.status' },
        { label: "Town", value: formData?.personalInfo?.residentialAddress?.town?.status, data: formData?.personalInfo?.residentialAddress?.town?.data, name: 'formData.personalInfo.residentialAddress.town.status' },
        { label: "City", value: formData?.personalInfo?.residentialAddress?.city?.status, data: formData?.personalInfo?.residentialAddress?.city?.data, name: 'formData.personalInfo.residentialAddress.city.status' },
        { label: "State", value: formData?.personalInfo?.residentialAddress?.state?.status, data: formData?.personalInfo?.residentialAddress?.state?.data, name: 'formData.personalInfo.residentialAddress.state.status' },
        { label: "Country", value: formData?.personalInfo?.residentialAddress?.country?.status, data: formData?.personalInfo?.residentialAddress?.country?.data, name: 'formData.personalInfo.residentialAddress.country.status' },
        { label: "Pin Code", value: formData?.personalInfo?.residentialAddress?.pincode?.status, data: formData?.personalInfo?.residentialAddress?.pincode?.data, name: 'formData.personalInfo.residentialAddress.pincode.status' },
    ];

    const permanentAddressInfoFields = [
        { label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address?.status, data: formData?.personalInfo?.permanentAddress?.address?.data, name: 'formData.personalInfo.permanentAddress.address.status' },
        { label: "Town", value: formData?.personalInfo?.permanentAddress?.town?.status, data: formData?.personalInfo?.permanentAddress?.town?.data, name: 'formData.personalInfo.permanentAddress.town.status' },
        { label: "City", value: formData?.personalInfo?.permanentAddress?.city?.status, data: formData?.personalInfo?.permanentAddress?.city?.data, name: 'formData.personalInfo.permanentAddress.city.status' },
        { label: "State", value: formData?.personalInfo?.permanentAddress?.state?.status, data: formData?.personalInfo?.permanentAddress?.state?.data, name: 'formData.personalInfo.permanentAddress.state.status' },
        { label: "Country", value: formData?.personalInfo?.permanentAddress?.country?.status, data: formData?.personalInfo?.permanentAddress?.country?.data, name: 'formData.personalInfo.permanentAddress.country.status' },
        { label: "Pin Code", value: formData?.personalInfo?.permanentAddress?.pincode?.status, data: formData?.personalInfo?.permanentAddress?.pincode?.data, name: 'formData.personalInfo.permanentAddress.pincode.status' }
    ];

    const identityInfoFields = [
        { label: "Nationality", value: formData?.identityInfo?.nationality?.status, data: formData?.identityInfo?.nationality?.data, name: 'formData.identityInfo.nationality.status' },
        { label: "Domicile", value: formData?.identityInfo?.domicile?.status, data: formData?.identityInfo?.domicile?.data, name: 'formData.identityInfo.domicile.status' },
        { label: "Category", value: formData?.identityInfo?.category?.status, data: formData?.identityInfo?.category?.data, name: 'formData.identityInfo.category.status' },
        { label: "Religion", value: formData?.identityInfo?.religion?.status, data: formData?.identityInfo?.religion?.data, name: 'formData.identityInfo.religion.status' },
        { label: "Caste", value: formData?.identityInfo?.caste?.status, data: formData?.identityInfo?.caste?.data, name: 'formData.identityInfo.caste.status' },
        { label: "Sub Caste", value: formData?.identityInfo?.subCaste?.status, data: formData?.identityInfo?.subCaste?.data, name: 'formData.identityInfo.subCaste.status' },
    ];

    const financialAndLegalInfoFields = [
        { label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName?.status, data: formData?.financialAndLegalInfo?.bankName?.data, name: 'formData.financialAndLegalInfo.bankName.status' },
        { label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo?.status, data: formData?.financialAndLegalInfo?.accountNo?.data, name: 'formData.financialAndLegalInfo.accountNo.status' },
        { label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc?.status, data: formData?.financialAndLegalInfo?.ifsc?.data, name: 'formData.financialAndLegalInfo.ifsc.status' },
        { label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo?.status, data: formData?.financialAndLegalInfo?.aadharCardNo?.data, name: 'formData.financialAndLegalInfo.aadharCardNo.status' },
        { label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo?.status, data: formData?.financialAndLegalInfo?.panCardNo?.data, name: 'formData.financialAndLegalInfo.panCardNo.status' }
    ];

    const additionalInfoFields = [
        { label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue?.status, data: formData?.additionalInfo?.motherTongue?.data, name: 'formData.additionalInfo.motherTongue.status' },
        { label: "Disability", value: formData?.additionalInfo?.disability?.status, data: formData?.additionalInfo?.disability?.data, name: 'formData.additionalInfo.disability.status' },
        { label: "Marital Status", value: formData?.additionalInfo?.maritalStatus?.status, data: formData?.additionalInfo?.maritalStatus?.data, name: 'formData.additionalInfo.maritalStatus.status' },
    ];

    const fatherInfoFields = [
        { label: "Father Alive", value: formData?.familyInfo?.fatherDetail?.isDeceased?.status, data: formData?.familyInfo?.fatherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.fatherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.fatherDetail?.firstName?.status, data: formData?.familyInfo?.fatherDetail?.firstName?.data, name: 'formData.familyInfo.fatherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.fatherDetail?.middleName?.status, data: formData?.familyInfo?.fatherDetail?.middleName?.data, name: 'formData.familyInfo.fatherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.fatherDetail?.lastName?.status, data: formData?.familyInfo?.fatherDetail?.lastName?.data, name: 'formData.familyInfo.fatherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.fatherDetail?.phNo?.status, data: formData?.familyInfo?.fatherDetail?.phNo?.data, name: 'formData.familyInfo.fatherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.fatherDetail?.emailId?.status, data: formData?.familyInfo?.fatherDetail?.emailId?.data, name: 'formData.familyInfo.fatherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.fatherDetail?.income?.status, data: formData?.familyInfo?.fatherDetail?.income?.data, name: 'formData.familyInfo.fatherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.fatherDetail?.education?.status, data: formData?.familyInfo?.fatherDetail?.education?.data, name: 'formData.familyInfo.fatherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.fatherDetail?.profession?.status, data: formData?.familyInfo?.fatherDetail?.profession?.data, name: 'formData.familyInfo.fatherDetail.profession.status' }
    ];

    const motherInfoFields = [
        { label: "Mother Alive", value: formData?.familyInfo?.motherDetail?.isDeceased?.status, data: formData?.familyInfo?.motherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.motherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.motherDetail?.firstName?.status, data: formData?.familyInfo?.motherDetail?.firstName?.data, name: 'formData.familyInfo.motherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.motherDetail?.middleName?.status, data: formData?.familyInfo?.motherDetail?.middleName?.data, name: 'formData.familyInfo.motherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.motherDetail?.lastName?.status, data: formData?.familyInfo?.motherDetail?.lastName?.data, name: 'formData.familyInfo.motherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.motherDetail?.phNo?.status, data: formData?.familyInfo?.motherDetail?.phNo?.data, name: 'formData.familyInfo.motherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.motherDetail?.emailId?.status, data: formData?.familyInfo?.motherDetail?.emailId?.data, name: 'formData.familyInfo.motherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.motherDetail?.income?.status, data: formData?.familyInfo?.motherDetail?.income?.data, name: 'formData.familyInfo.motherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.motherDetail?.education?.status, data: formData?.familyInfo?.motherDetail?.education?.data, name: 'formData.familyInfo.motherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.motherDetail?.profession?.status, data: formData?.familyInfo?.motherDetail?.profession?.data, name: 'formData.familyInfo.motherDetail.profession.status' }
    ];

    const guardianInfoFields = [
        { label: "Guardian Exist", value: formData?.familyInfo?.guardianDetail?.isGuardianExist?.status, data: formData?.familyInfo?.guardianDetail?.isGuardianExist?.data ? 'Yes' : 'No', name: 'formData.familyInfo.guardianDetail.isGuardianExist.status' },
        { label: "First Name", value: formData?.familyInfo?.guardianDetail?.firstName?.status, data: formData?.familyInfo?.guardianDetail?.firstName?.data, name: 'formData.familyInfo.guardianDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.guardianDetail?.middleName?.status, data: formData?.familyInfo?.guardianDetail?.middleName?.data, name: 'formData.familyInfo.guardianDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.guardianDetail?.lastName?.status, data: formData?.familyInfo?.guardianDetail?.lastName?.data, name: 'formData.familyInfo.guardianDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.guardianDetail?.phNo?.status, data: formData?.familyInfo?.guardianDetail?.phNo?.data, name: 'formData.familyInfo.guardianDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.guardianDetail?.emailId?.status, data: formData?.familyInfo?.guardianDetail?.emailId?.data, name: 'formData.familyInfo.guardianDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.guardianDetail?.income?.status, data: formData?.familyInfo?.guardianDetail?.income?.data, name: 'formData.familyInfo.guardianDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.guardianDetail?.education?.status, data: formData?.familyInfo?.guardianDetail?.education?.data, name: 'formData.familyInfo.guardianDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.guardianDetail?.profession?.status, data: formData?.familyInfo?.guardianDetail?.profession?.data, name: 'formData.familyInfo.guardianDetail.profession.status' }
    ];

    const elementaryFields = [
        { label: "Board Name", value: formData?.exams?.elementaryDetail?.boardName?.status, data: formData?.exams?.elementaryDetail?.boardName?.data, name: 'formData.exams.elementaryDetail.boardName.status' },
        { label: "Roll No", value: formData?.exams?.elementaryDetail?.rollNo?.status, data: formData?.exams?.elementaryDetail?.rollNo?.data, name: 'formData.exams.elementaryDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.exams?.elementaryDetail?.examYear?.status, data: formData?.exams?.elementaryDetail?.examYear?.data, name: 'formData.exams.elementaryDetail.examYear.status' },
        { label: "School Name", value: formData?.exams?.elementaryDetail?.schoolName?.status, data: formData?.exams?.elementaryDetail?.schoolName?.data, name: 'formData.exams.elementaryDetail.schoolName.status' },
        { label: "School City", value: formData?.exams?.elementaryDetail?.city?.status, data: formData?.exams?.elementaryDetail?.city?.data, name: 'formData.exams.elementaryDetail.city.status' },
        { label: "School State", value: formData?.exams?.elementaryDetail?.state?.status, data: formData?.exams?.elementaryDetail?.state?.data, name: 'formData.exams.elementaryDetail.state.status' },
        { label: "School Country", value: formData?.exams?.elementaryDetail?.country?.status, data: formData?.exams?.elementaryDetail?.country?.data, name: 'formData.exams.elementaryDetail.country.status' },
        { label: "Obtained Marks", value: formData?.exams?.elementaryDetail?.obtainedMarks?.status, data: formData?.exams?.elementaryDetail?.obtainedMarks?.data, name: 'formData.exams.elementaryDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.exams?.elementaryDetail?.totalMarks?.status, data: formData?.exams?.elementaryDetail?.totalMarks?.data, name: 'formData.exams.elementaryDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.exams?.elementaryDetail?.percentage?.status, data: formData?.exams?.elementaryDetail?.percentage?.data, name: 'formData.exams.elementaryDetail.percentage.status' },
        { label: "Grade", value: formData?.exams?.elementaryDetail?.grade?.status, data: formData?.exams?.elementaryDetail?.grade?.data, name: 'formData.exams.elementaryDetail.grade.status' },
        { label: "CGPA", value: formData?.exams?.elementaryDetail?.cgpa?.status, data: formData?.exams?.elementaryDetail?.cgpa?.data, name: 'formData.exams.elementaryDetail.cgpa.status' },
    ];

    const lateralEntryFields = [
        { label: 'Degree', value: formData?.exams?.lateralEntryDetail?.degree?.status, data: formData?.exams?.lateralEntryDetail?.degree?.data, name: 'formData.exams.lateralEntryDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.lateralEntryDetail?.major?.status, data: formData?.exams?.lateralEntryDetail?.major?.data, name: 'formData.exams.lateralEntryDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.lateralEntryDetail?.degreeType?.status, data: formData?.exams?.lateralEntryDetail?.degreeType?.data, name: 'formData.exams.lateralEntryDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.lateralEntryDetail?.passingYear?.status, data: formData?.exams?.lateralEntryDetail?.passingYear?.data, name: 'formData.exams.lateralEntryDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.lateralEntryDetail?.collegeName?.status, data: formData?.exams?.lateralEntryDetail?.collegeName?.data, name: 'formData.exams.lateralEntryDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.lateralEntryDetail?.country?.status, data: formData?.exams?.lateralEntryDetail?.country?.data, name: 'formData.exams.lateralEntryDetail.country.status' },
        { label: 'College State', value: formData?.exams?.lateralEntryDetail?.state?.status, data: formData?.exams?.lateralEntryDetail?.state?.data, name: 'formData.exams.lateralEntryDetail.state.status' },
        { label: 'College City', value: formData?.exams?.lateralEntryDetail?.city?.status, data: formData?.exams?.lateralEntryDetail?.city?.data, name: 'formData.exams.lateralEntryDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.lateralEntryDetail?.percentage?.status, data: formData?.exams?.lateralEntryDetail?.percentage?.data, name: 'formData.exams.lateralEntryDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.lateralEntryDetail?.grade?.status, data: formData?.exams?.lateralEntryDetail?.grade?.data, name: 'formData.exams.lateralEntryDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.lateralEntryDetail?.cgpa?.status, data: formData?.exams?.lateralEntryDetail?.cgpa?.data, name: 'formData.exams.lateralEntryDetail.cgpa.status' }
    ];

    const intermediateFields = [
        { label: "Board Name", value: formData?.exams?.intermediateDetail?.boardName?.status, data: formData?.exams?.intermediateDetail?.boardName?.data, name: 'formData.exams.intermediateDetail.boardName.status' },
        { label: "Roll No", value: formData?.exams?.intermediateDetail?.rollNo?.status, data: formData?.exams?.intermediateDetail?.rollNo?.data, name: 'formData.exams.intermediateDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.exams?.intermediateDetail?.examYear?.status, data: formData?.exams?.intermediateDetail?.examYear?.data, name: 'formData.exams.intermediateDetail.examYear.status' },
        { label: "School Name", value: formData?.exams?.intermediateDetail?.schoolName?.status, data: formData?.exams?.intermediateDetail?.schoolName?.data, name: 'formData.exams.intermediateDetail.schoolName.status' },
        { label: "School City", value: formData?.exams?.intermediateDetail?.city?.status, data: formData?.exams?.intermediateDetail?.city?.data, name: 'formData.exams.intermediateDetail.city.status' },
        { label: "School State", value: formData?.exams?.intermediateDetail?.state?.status, data: formData?.exams?.intermediateDetail?.state?.data, name: 'formData.exams.intermediateDetail.state.status' },
        { label: "School Country", value: formData?.exams?.intermediateDetail?.country?.status, data: formData?.exams?.intermediateDetail?.country?.data, name: 'formData.exams.intermediateDetail.country.status' },
        { label: "Obtained Marks", value: formData?.exams?.intermediateDetail?.obtainedMarks?.status, data: formData?.exams?.intermediateDetail?.obtainedMarks?.data, name: 'formData.exams.intermediateDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.exams?.intermediateDetail?.totalMarks?.status, data: formData?.exams?.intermediateDetail?.totalMarks?.data, name: 'formData.exams.intermediateDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.exams?.intermediateDetail?.percentage?.status, data: formData?.exams?.intermediateDetail?.percentage?.data, name: 'formData.exams.intermediateDetail.percentage.status' },
        { label: "Grade", value: formData?.exams?.intermediateDetail?.grade?.status, data: formData?.exams?.intermediateDetail?.grade?.data, name: 'formData.exams.intermediateDetail.grade.status' },
        { label: "CGPA", value: formData?.exams?.intermediateDetail?.cgpa?.status, data: formData?.exams?.intermediateDetail?.cgpa?.data, name: 'formData.exams.intermediateDetail.cgpa.status' },
    ];

    const subjectFields = [
        { type: 'input', size: 1, name: 'subjectName', label: 'Subject Name' },
        { type: 'input', size: 1, name: 'obtainedMarks', label: 'Obtained Marks' },
        { type: 'input', size: 1, name: 'totalMarks', label: 'Total Marks' }
    ]

    const requiredExamsFields = Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.map((round, index) => formData?.exams?.requiredExamsDetail?.[round]?.map((exam, i) => [
        { label: 'Exam Year', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.examYear?.status, data: exam?.examYear?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.examYear.status` },
        { label: 'Roll No', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.rollNo?.status, data: exam?.rollNo?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.rollNo.status` },
        { label: 'Obtained Marks', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.obtainedMarks?.status, data: exam?.obtainedMarks?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.obtainedMarks.status` },
        { label: 'Total Marks', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.totalMarks?.status, data: exam?.totalMarks?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.totalMarks.status` },
        { label: 'Percentage', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.percentage?.status, data: exam?.percentage?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.percentage.status` },
        { label: 'Percentile', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.percentile?.status, data: exam?.percentile?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.percentile.status` },
        { label: 'National Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.nationalRank?.status, data: exam?.nationalRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.nationalRank.status` },
        { label: 'State Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.stateRank?.status, data: exam?.stateRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.stateRank.status` },
        { label: 'Caste Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.casteRank?.status, data: exam?.casteRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.casteRank.status` },
        { label: 'Grade', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.grade?.status, data: exam?.grade?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.grade.status` }
    ]));

    const optionalExamsFields = Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.map((round, index) => [
        { label: 'Exam Year', value: formData?.exams?.optionalExamsDetail?.[round]?.examYear?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.examYear?.data, name: `formData.exams.optionalExamsDetail.${round}.examYear.status` },
        { label: 'Roll No', value: formData?.exams?.optionalExamsDetail?.[round]?.rollNo?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.rollNo?.data, name: `formData.exams.optionalExamsDetail.${round}.rollNo.status` },
        { label: 'Obtained Marks', value: formData?.exams?.optionalExamsDetail?.[round]?.obtainedMarks?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.obtainedMarks?.data, name: `formData.exams.optionalExamsDetail.${round}.obtainedMarks.status` },
        { label: 'Total Marks', value: formData?.exams?.optionalExamsDetail?.[round]?.totalMarks?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.totalMarks?.data, name: `formData.exams.optionalExamsDetail.${round}.totalMarks.status` },
        { label: 'Percentage', value: formData?.exams?.optionalExamsDetail?.[round]?.percentage?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.percentage?.data, name: `formData.exams.optionalExamsDetail.${round}.percentage.status` },
        { label: 'Percentile', value: formData?.exams?.optionalExamsDetail?.[round]?.percentile?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.percentile?.data, name: `formData.exams.optionalExamsDetail.${round}.percentile.status` },
        { label: 'National Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.nationalRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.nationalRank?.data, name: `formData.exams.optionalExamsDetail.${round}.nationalRank.status` },
        { label: 'State Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.stateRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.stateRank?.data, name: `formData.exams.optionalExamsDetail.${round}.stateRank.status` },
        { label: 'Caste Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.casteRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.casteRank?.data, name: `formData.exams.optionalExamsDetail.${round}.casteRank.status` },
        { label: 'Grade', value: formData?.exams?.optionalExamsDetail?.[round]?.grade?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.grade?.data, name: `formData.exams.optionalExamsDetail.${round}.grade.status` }
    ]);

    const ugFields = [
        { label: 'Degree', value: formData?.exams?.ugDetail?.degree?.status, data: formData?.exams?.ugDetail?.degree?.data, name: 'formData.exams.ugDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.ugDetail?.major?.status, data: formData?.exams?.ugDetail?.major?.data, name: 'formData.exams.ugDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.ugDetail?.degreeType?.status, data: formData?.exams?.ugDetail?.degreeType?.data, name: 'formData.exams.ugDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.ugDetail?.passingYear?.status, data: formData?.exams?.ugDetail?.passingYear?.data, name: 'formData.exams.ugDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.ugDetail?.collegeName?.status, data: formData?.exams?.ugDetail?.collegeName?.data, name: 'formData.exams.ugDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.ugDetail?.country?.status, data: formData?.exams?.ugDetail?.country?.data, name: 'formData.exams.ugDetail.country.status' },
        { label: 'College State', value: formData?.exams?.ugDetail?.state?.status, data: formData?.exams?.ugDetail?.state?.data, name: 'formData.exams.ugDetail.state.status' },
        { label: 'College City', value: formData?.exams?.ugDetail?.city?.status, data: formData?.exams?.ugDetail?.city?.data, name: 'formData.exams.ugDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.ugDetail?.percentage?.status, data: formData?.exams?.ugDetail?.percentage?.data, name: 'formData.exams.ugDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.ugDetail?.grade?.status, data: formData?.exams?.ugDetail?.grade?.data, name: 'formData.exams.ugDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.ugDetail?.cgpa?.status, data: formData?.exams?.ugDetail?.cgpa?.data, name: 'formData.exams.ugDetail.cgpa.status' }
    ];

    const pgFields = [
        { label: 'Degree', value: formData?.exams?.pgDetail?.degree?.status, data: formData?.exams?.pgDetail?.degree?.data, name: 'formData.exams.pgDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.pgDetail?.major?.status, data: formData?.exams?.pgDetail?.major?.data, name: 'formData.exams.pgDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.pgDetail?.degreeType?.status, data: formData?.exams?.pgDetail?.degreeType?.data, name: 'formData.exams.pgDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.pgDetail?.passingYear?.status, data: formData?.exams?.pgDetail?.passingYear?.data, name: 'formData.exams.pgDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.pgDetail?.collegeName?.status, data: formData?.exams?.pgDetail?.collegeName?.data, name: 'formData.exams.pgDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.pgDetail?.country?.status, data: formData?.exams?.pgDetail?.country?.data, name: 'formData.exams.pgDetail.country.status' },
        { label: 'College State', value: formData?.exams?.pgDetail?.state?.status, data: formData?.exams?.pgDetail?.state?.data, name: 'formData.exams.pgDetail.state.status' },
        { label: 'College City', value: formData?.exams?.pgDetail?.city?.status, data: formData?.exams?.pgDetail?.city?.data, name: 'formData.exams.pgDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.pgDetail?.percentage?.status, data: formData?.exams?.pgDetail?.percentage?.data, name: 'formData.exams.pgDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.pgDetail?.grade?.status, data: formData?.exams?.pgDetail?.grade?.data, name: 'formData.exams.pgDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.pgDetail?.cgpa?.status, data: formData?.exams?.pgDetail?.cgpa?.data, name: 'formData.exams.pgDetail.cgpa.status' }
    ];

    const handleEditDetails = () => navigate(`/admin/student/applicants/editDetails/${id}?step=Personal+Details`);

    const onConfirmDelete = async () => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/applicants/deleteApplicant/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen(`Applicant deleted successfully!`, 'success');
        navigate('/admin/student/applicants');
    }

    const handleDelete = () => openPopup('Delete Applicant', "Are you sure you want to delete the applicant?This action can't be reverted back!", 'delete', onConfirmDelete);

    const checkBoxOptions = {
        'Verified': true,
        'Rejected': false,
        'Pending': undefined,
        'true': 'Verified',
        'false': 'Rejected',
        'undefined': 'Pending'
    };

    return (
        <div className={`flex flex-col h-[calc(100svh-13rem)] tab:h-[calc(100svh-10rem)] lg:h-[calc(100svh-5rem)] transition-transform delay-500 duration-500 col-span-full ${documentOpen ? "lg:col-span-8" : "lg:col-span-15"}`}>
            <div className="sticky top-0 flex justify-between gap-5 w-full p-3 sm:px-5 md:px-8 drop-shadow-sm border-b h-[68px]">
                <div className="flex items-center gap-2 xs:gap-5">
                    <IconButton size="small" className="w-fit" aria-label="back" onClick={handleBackward}>
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    <h4 className="h5-p xs:h3-p">Review Changes</h4>
                </div>

                <div className="flex gap-3">
                    <BlackToolTip title={onlineStatus !== 'Pending' ? `${onlineStatus} Application can't be edited!` : 'Edit Student Application'}>
                        <p>
                            <IconButton aria-label="Edit Details" disableElevation
                                className='rounded-sm' onClick={handleEditDetails} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }} disabled={onlineStatus !== 'Pending'}>
                                <EditOutlined className="text-amber-500" fontSize="medium" />
                            </IconButton>
                        </p>
                    </BlackToolTip>

                    <BlackToolTip title='Discard Student Application Permanently'>
                        <p>
                            <IconButton aria-label="Discard Application" disableElevation
                                className='rounded-sm' onClick={handleDelete} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }}>
                                <DeleteOutline className="text-signal" fontSize="medium" />
                            </IconButton>
                        </p>
                    </BlackToolTip>
                </div>
            </div>

            <form onSubmit={(e) => handleSubmit(e, formData, remark)} className="flex flex-col gap-8 xs:gap-12 p-5 xs:p-8 overflow-y-scroll no-scrollbar">
                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full w-fit" onChange={(e) => handleMasterCheckBox(e, 'General Details')} value={masterCheckBoxValue(generalInfoFields, checkBoxOptions)} name={generalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">General Details</h4>
                    </CustomCheckBox>

                    {generalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>
                    )}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Personal Details')} value={masterCheckBoxValue(personalInfoFields, checkBoxOptions)} name={personalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Personal Details</h4>
                    </CustomCheckBox>
                    {personalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Residential Address Details')} value={masterCheckBoxValue(residentialAddressInfoFields, checkBoxOptions)} name={residentialAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Residential Address Details</h4>
                    </CustomCheckBox>
                    {residentialAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Permanent Address Details')} value={masterCheckBoxValue(permanentAddressInfoFields, checkBoxOptions)} name={permanentAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Permanent Address Details</h4>
                    </CustomCheckBox>
                    {permanentAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Identity Details')} value={masterCheckBoxValue(identityInfoFields, checkBoxOptions)} name={identityInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Identity Details</h4>
                    </CustomCheckBox>
                    {identityInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Financial Details')} value={masterCheckBoxValue(financialAndLegalInfoFields, checkBoxOptions)} name={financialAndLegalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Financial Details</h4>
                    </CustomCheckBox>
                    {financialAndLegalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                </div>

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Additional Details')} value={masterCheckBoxValue(additionalInfoFields, checkBoxOptions)} name={additionalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Additional Details</h4>
                    </CustomCheckBox>
                    {additionalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                </div>

                {formData?.familyInfo?.fatherDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Father Details')} value={masterCheckBoxValue(fatherInfoFields, checkBoxOptions)} name={fatherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Father Details</h4>
                        </CustomCheckBox>
                        {fatherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                    </div>}

                {formData?.familyInfo?.motherDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Mother Details')} value={masterCheckBoxValue(motherInfoFields, checkBoxOptions)} name={motherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Mother Details</h4>
                        </CustomCheckBox>
                        {motherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
                    </div>}

                {formData?.familyInfo?.guardianDetail &&
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Guardian Details')} value={masterCheckBoxValue(guardianInfoFields, checkBoxOptions)} name={guardianInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Guardian Details</h4>
                        </CustomCheckBox>
                        {guardianInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>
                        )}
                    </div>
                }

                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Elementary Details')} value={masterCheckBoxValue(elementaryFields, checkBoxOptions)} name={elementaryFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">Elementary Details</h4>
                    </CustomCheckBox>
                    {elementaryFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>
                    )}
                </div>

                {formData?.exams?.elementaryDetail?.subjects?.map((subject, index) => {
                    const fields = subjectFields?.map(field => ({
                        value: formData?.exams?.elementaryDetail?.subjects?.[index]?.[field.name]?.status,
                        name: `formData.exams.elementaryDetail.subjects.${[index]}.${field?.name}.status`,
                        label: field?.label, data: subject[field?.name]?.data, loading: isLoading
                    }));

                    const masterCheckboxValue = { value: masterCheckBoxValue(fields, checkBoxOptions), name: fields };

                    return (
                        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Elementary Detail ${index + 1}`)} value={masterCheckboxValue.value} name={masterCheckboxValue.name} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <h4 className="col-span-full h4-p text-grey"> Elementary - {subject?.subjectName?.data} Details</h4>
                            </CustomCheckBox>

                            {fields?.map((field, fieldIndex) => ((isLoading || field?.data !== undefined) &&
                                <div key={`${index}-${field?.name}`}>
                                    <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                        <TextBox label={field?.label} value={field?.data} loading={field?.loading} />
                                    </CustomCheckBox>
                                </div>
                            ))}
                        </div>
                    );
                })}

                {formData?.exams?.lateralEntryDetail && (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'LateralEntry Details')} value={masterCheckBoxValue(lateralEntryFields, checkBoxOptions)} name={lateralEntryFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Lateral Entry Details</h4>
                        </CustomCheckBox>
                        {lateralEntryFields?.map((field, index) => ((isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>
                        ))}
                    </div>
                )}

                {formData?.exams?.intermediateDetail && (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Intermediate Details')} value={masterCheckBoxValue(intermediateFields, checkBoxOptions)} name={intermediateFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Intermediate Details</h4>
                        </CustomCheckBox>
                        {intermediateFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                            </CustomCheckBox>
                        )}
                    </div>
                )}

                {formData?.exams?.intermediateDetail?.subjects?.map((subject, index) => {
                    const fields = subjectFields?.map(field => ({
                        value: formData?.exams?.intermediateDetail?.subjects?.[index]?.[field.name]?.status,
                        name: `formData.exams.intermediateDetail.subjects.${[index]}.${field.name}.status`,
                        label: field.label, data: subject[field?.name]?.data, loading: isLoading
                    }));

                    const masterCheckboxValue = { value: masterCheckBoxValue(fields, checkBoxOptions), name: fields };

                    return (
                        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Elementary Detail ${index + 1}`)} value={masterCheckboxValue.value} name={masterCheckboxValue.name} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <h4 className="col-span-full h4-p text-grey">Intermediate - {subject?.subjectName?.data} Details</h4>
                            </CustomCheckBox>

                            {fields?.map((field, fieldIndex) => (isLoading || field?.data !== undefined) &&
                                <div key={`${index}-${field.name}`}>
                                    <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                        <TextBox label={field.label} value={field.data} loading={field.loading} />
                                    </CustomCheckBox>
                                </div>
                            )}
                        </div>
                    );
                })}

                {formData?.exams?.requiredExamsDetail && requiredExamsFields?.map((round, index) => (
                    round?.map((exam, examIndex) =>
                        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `${Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.[index]} Details`)} value={masterCheckBoxValue(exam, checkBoxOptions)} name={exam} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <h4 className="col-span-full h4-p text-grey">{Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.[index]} Exam {round?.length > 0 ? examIndex + 1 : ''} Details</h4>
                            </CustomCheckBox>
                            {exam?.map((field, i) => (isLoading || field?.data !== undefined) &&
                                <div key={`${index}-${i}`}>
                                    <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                        <TextBox label={field.label} value={field.data} loading={isLoading} />
                                    </CustomCheckBox>
                                </div>
                            )}
                        </div>
                    )))}

                {optionalExamsFields?.map((fields, index) => (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `${Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.[index]} Details`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">{Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.[index]} Details</h4>
                        </CustomCheckBox>
                        {fields?.map((field, i) => (isLoading || field?.data !== undefined) &&
                            <div key={`${index}-${i}`}>
                                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                ))}

                {formData?.exams?.ugDetail && (
                    <div className={`grid gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Under Graduation Details')} value={masterCheckBoxValue(ugFields, checkBoxOptions)} name={ugFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Under Graduation Details</h4>
                        </CustomCheckBox>
                        {ugFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                            </CustomCheckBox>
                        )}
                    </div>
                )}

                {formData?.exams?.pgDetail && (
                    <div className={`grid gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Post Graduation Details')} value={masterCheckBoxValue(pgFields, checkBoxOptions)} name={pgFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Post Graduation Details</h4>
                        </CustomCheckBox>
                        {pgFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                            <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                            </CustomCheckBox>
                        )}
                    </div>
                )}

                <div className="flex flex-col xs:mt-4 gap-3 xs:gap-5">
                    <CustomTextField multiline rows={4} label='Add Remark' placeholder='Your Remark will be displayed to respective applicant for justification' value={remark} onChange={(e) => setRemark(e.target.value)} required={false} />

                    <div className="flex flex-col xs:flex-row gap-3 xs:gap-5 w-full">
                        <CloseButton fullWidth type='button' text='Review Later' onClick={handleBackward} />
                        {onlineStatus === 'Pending' ?
                            <ConfirmButton fullWidth type='submit' text={"Verify Online Verification"} /> :
                            onlineStatus === 'Rejected' ?
                                <ConfirmButton fullWidth type='submit' text="ReVerify Online Verification" /> :
                                onlineStatus === 'Verified' ?
                                    <PurpleButton fullWidth type='submit' text="Revert Online Verification" /> : <PurpleButton fullWidth disabled={true} text={isLoading ? 'Loading' : 'Status Not Found'} />}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default VerifyData;