import React from "react";
import { useNavigate } from "react-router-dom"

import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';

import DocumentDeclined from '../../../../assets/icons/admission/DocumentDeclined.svg';

import HomeTile from "../../../../components/tiles/AdmissionTile";
import CustomTable from "../../../../components/mui/Table";

const BlockedApplicants = () => {
    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/applicants/stats/getDeletedStats');
    const { data: defaultData, isLoading } = useGetData('/api/employee/admission/applicants/getDeletedApplicants');

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/applicants/blocked/profile/" + value + '?type=Personal'));

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 140, type: 'text' },
        { id: 'deletedTimeStamp', label: 'Blocked On', type: 'date' },
        { id: 'deletedAuthorityId', label: 'Blocked By', type: 'text' }
    ];

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-svh'>
            <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                <HomeTile icon={DocumentDeclined} color='#ffdfdf' heading='Blocked Applicants' count={statsData?.deletedCount} isLoading={statsLoading} />
                <div className="xl:col-span-3 flex justify-center items-center text-center h-full w-full rounded-md bg-white drop-shadow text-base text-slate-400">
                    Please Contact Byteswrite Support to unblock Applicants
                </div>
            </div>

            <CustomTable
                heading="All Blocked Applicants"
                text="Blocked Applicants data"
                isLoading={isLoading}
                columns={columns} rows={defaultData}
                sortParameters={['name', 'programKey', 'admissionYear', 'deletedTimeStamp', 'deletedAuthorityId']}
                handleRowClick={handleRow}
                activeOperations
            />
        </div>
    )
}

export default BlockedApplicants;