import { useRef } from 'react';
import { IconButton, Button, Dialog } from '@mui/material';
import { AddCircleOutline, Delete, FileUpload } from '@mui/icons-material';

import useSnackbar from '../../hooks/useSnackbar';

import PdfIcon from '../../assets/icons/documents/PdfIcon'
import { CrossButton, PurpleButton } from '../mui/button/Button';

const UploadPopup = ({ file, setterFile, allowedTypes = ['pdf', 'png', 'jpeg', 'jpg'], onSubmit, onClose }) => {
    const { handleSnackbarOpen } = useSnackbar();

    const fileInputRef = useRef(null);
    const fileExtensions = allowedTypes?.join(' | ').toUpperCase();

    const removeFile = () => setterFile(null);
    const handleUploadButton = () => fileInputRef.current.click();
    const handleDragOver = (event) => event.preventDefault();

    const handleFile = (file) => {
        const fileType = file?.name?.split('.')?.pop()?.toLowerCase();
        const isValidType = allowedTypes.includes(fileType);
        console.log(isValidType)
        if (!isValidType) return handleSnackbarOpen(`Invalid file format. Only ${fileExtensions} are allowed`, "error")

        const maxSize = 800 * 1024; // 800 kb
        const isBigFile = file.size > maxSize;

        if (isBigFile) return handleSnackbarOpen('File size exceeds 800KB. Maximum file size allowed is 800KB.', "error");
        setterFile(file);
    };

    const addNewFile = (event) => {
        event.preventDefault();
        const newFile = event.target.files[0];
        if (!newFile) return;
        handleFile(newFile);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 1) return handleSnackbarOpen(`Multiple files not allowed`, "error")
        handleFile(files[0]);
    };

    return (
        <Dialog open={true} onClose={onClose} className='w-full h-full'>
            <div className='flex flex-col p-5 gap-3 w-full h-full rounded-sm bg-white'>
                <div className='flex justify-between'>
                    <div />
                    <h5 className='h4-p text-center'>Upload File</h5>
                    <CrossButton onClose={onClose} />
                </div>

                <div onDragOver={handleDragOver} onDrop={handleDrop} className='flex flex-col p-3 items-center justify-evenly w-full bg-slate-100 rounded-md border-dashed border-2'>
                    <p className='text-s text-center max-w-72 xs:max-w-96'>Safe & sound. Future-proof encryption shields your data. </p>
                    <div className='pb-4 xs:p-10 text-center flex flex-col space-y-3 items-center justify-center'>
                        <h5 className='h4-p'>Drag & Drop files here</h5>
                        <span className='text-s'>OR</span>
                        <Button disableElevation color="secondary" variant='contained' style={{ backgroundColor: "#f97316" }} startIcon={<AddCircleOutline />}
                            onClick={handleUploadButton} disableTouchRipple>
                            Add Files
                            <input ref={fileInputRef} onChange={addNewFile} type="file" className='hidden' accept={allowedTypes.map(type => `.${type}`).join(',')} />
                        </Button>
                    </div>

                    <div className='flex items-center justify-center flex-col'>
                        <p className='text-s font-bold'>Supported Files</p>
                        <p className='text-p font-semibold mb-2'>{fileExtensions}</p>
                        <p className='text-center text-s text-xs'>Max Upload size of each file &lt; <span className='text-purple'>800KB</span></p>
                    </div>
                </div>

                <div className='flex w-full justify-between items-start gap-3 border rounded-sm px-3 py-5 min-h-20'>
                    {file?.name &&
                        <>
                            <div className='flex items-center gap-2 truncate w-full'>
                                {file?.type === 'application/pdf' ? <PdfIcon className="flex flex-shrink-0 fill-red-500 h-6 w-6 xs:h-10 xs:w-10" />
                                    : file?.type ? <img className='w-10 h-10 rounded-sm bg-slate-50'
                                        src={file ? URL?.createObjectURL(file) : 'fallback-image-url.jpg'}
                                        alt={file?.name}
                                    /> : null
                                }

                                <div className='flex flex-col gap-0.5 w-full'>
                                    <p className='text-p font-semibold'>{file?.name}</p>
                                    <span className='text-s text-xs'>{(file?.size / (1024 * 1024)).toFixed(2)} mb</span>
                                </div>
                            </div>

                            <IconButton size='small' onClick={removeFile} >
                                <Delete fontSize='small' className='text-red-500' />
                            </IconButton>
                        </>
                    }
                </div>

                <PurpleButton
                    onClick={onSubmit}
                    endIcon={<FileUpload />}
                    // loading={isLoading}
                    // loadingPosition="end"
                    fullWidth
                    disabled={!file}
                    text='Upload File'
                />

            </div >
        </Dialog>
    )
}

export default UploadPopup;