import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom"
import { CloudDownload, PendingOutlined, CancelOutlined, ChangeCircleOutlined, VerifiedOutlined, ScreenRotationAlt, PersonOff, PersonAdd } from '@mui/icons-material';

import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DocumentVerified from '../../../../assets/icons/admission/DocumentVerified.svg';
import DocumentPending from '../../../../assets/icons/admission/DocumentPending.svg';
import DocumentRejected from '../../../../assets/icons/admission/DocumentRejected.svg';
import DocumentDeclined from '../../../../assets/icons/admission/DocumentDeclined.svg';
import PromoteIcon from "../../../../assets/icons/PromoteIcon";

import { dateFormat } from "../../../../utils/dateFormat";
import CustomTable from "../../../../components/mui/Table";
import BlackToolTip from "../../../../components/mui/BlackToolTip";
import HomeTile from "../../../../components/tiles/AdmissionTile";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";
import PromotePopup from "../../../../components/popups/localPopup/promotion/PromotePopup";

const RecruitsVerification = () => {
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/recruits/stats/getStats');
    const { data: defaultData, isLoading, updateData } = useGetData('/api/employee/hr/recruits/getRecruits');

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();

    const { putData } = usePutData();
    const setLoading = useSetRecoilState(loadingState);

    const employeeData = defaultData?.map(e => ({ name: `${e.firstName} ${e.lastName}`, ...e }));
    const [promotePopup, setPromotePopup] = useState({ isOpen: false, _id: '' });

    const customOperations = () => {
        return <div className="flex gap-5">
            <SquareIconButton title='Add Applicant' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<PersonAdd sx={{ color: "#512C9D", fontSize: 24 }} />} />
            <SquareIconButton title='Blocked Applicants' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<PersonOff sx={{ color: "red", fontSize: 24 }} />} onClick={() => navigate('/admin/employee/recruits/blocked')} />
            <SquareIconButton title='Download Excel' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<CloudDownload sx={{ color: "green", fontSize: 24 }} />} />
        </div>
    }

    const handleRow = (event, value) => {
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/employee/recruits/profile/" + value + '?type=Personal'));
    }

    const handleOnlineVerification = (event, value) => {
        openPopup('Review Details', 'Are you sure you want to view further details of Online Verification?', 'view', () => navigate("/admin/employee/recruits/onlineVerify/" + value));
    }

    const handleOfflineVerification = (event, value) =>
        openPopup('Verify Documents Offline', 'Are you sure you want to view further details of Offline Verification?', 'view', () => navigate("/admin/employee/recruits/offlineVerify/" + value));

    const handlePromote = (event, id) => {
        const user = defaultData?.find(user => user._id === id) || {};
        if (user?.promoted) return;
        if (!(user?.onlineVerificationStatus === 'Verified' || user?.offlineVerificationStatus === 'Verified'))
            return handleSnackbarOpen('Verification required: User must be verified either online or offline.', 'warning');
        setPromotePopup({ isOpen: true, _id: id });
    };

    const onPromote = async (id, remark) => {
        setPromotePopup({ isOpen: false, _id: '' });
        const user = defaultData?.find(user => user._id === id) || {};

        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/recruits/updateAdmissionTransfer/${id}`, { statusMessage: remark });
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        if (user?.onlineVerificationStatus === 'Verified' && user?.offlineVerificationStatus === 'Verified') updateData(prevState => { return prevState.filter(user => user._id !== id) });
        handleSnackbarOpen(`Recruit successfully promoted!`, 'success');
    }

    const tileData = [
        { icon: DocumentVerified, color: "#D1FADF", heading: "Online Verified", count: statsData?.online?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Online Pending", count: statsData?.online?.Pending },
        { icon: DocumentRejected, color: "#D6D0F7", heading: "Online Rejected", count: statsData?.online?.Rejected },
        { icon: DocumentDeclined, color: "#ffdfdf", heading: "Online Declined", count: statsData?.online?.Declined },
        { icon: DocumentVerified, color: "#D1FADF", heading: "Offline Verified", count: statsData?.offline?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Offline Pending", count: statsData?.offline?.Pending },
        { icon: DocumentRejected, color: "#D6D0F7", heading: "Offline Rejected", count: statsData?.offline?.Rejected },
        { icon: DocumentDeclined, color: "#ffdfdf", heading: "Offline Declined", count: statsData?.offline?.Declined }
    ]

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'departments', label: 'Department', minWidth: 180, type: 'array' },
        { id: 'joiningDate', label: 'Joining Date', minWidth: 140, type: 'date' },
        {
            id: 'onlineVerificationStatus', label: 'Online Verification', minWidth: 100, type: 'iconButton',
            icon: {
                Verified: <VerifiedOutlined className='text-green' />,
                Pending: <PendingOutlined className='text-amber-500' />,
                Rejected: <ChangeCircleOutlined className="text-purple" />,
                Declined: <CancelOutlined className="text-signal" />
            },
            align: 'center', onClick: handleOnlineVerification,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {(row?.reSubmitted && row?.onlineVerificationStatus === 'Pending') ?
                        <BlackToolTip title="Re-submitted">
                            <ScreenRotationAlt className="text-purple" />
                        </BlackToolTip>
                        : row?.onlineVerificationStatus === 'Pending' ?
                            <PendingOutlined className="text-amber-500" />
                            : <>
                                {row?.onlineVerificationStatus === 'Verified' ?
                                    <VerifiedOutlined className="text-green" />
                                    : <CancelOutlined className="text-signal" />
                                }
                                <div className="text-xs">{dateFormat(row.onlineVerificationTimeStamp)}</div>
                                <div className="text-s text-xs text-signal">{row.onlineVerificationAuthority}</div>
                            </>
                    }
                </div>
            )
        },
        {
            id: 'offlineVerificationStatus', label: 'Offline Verification', minWidth: 100, type: 'iconButton',
            icon: {
                Verified: <VerifiedOutlined className='text-green' />,
                Pending: <PendingOutlined className='text-amber-500' />,
                Rejected: <ChangeCircleOutlined className="text-purple" />,
                Declined: <CancelOutlined className="text-signal" />
            },
            align: 'center', onClick: handleOfflineVerification,
            render: (row) => {
                return (
                    <div className="flex flex-col items-center justify-center gap-2">
                        {row?.offlineVerificationStatus === 'Verified' ? <>
                            <VerifiedOutlined className="text-green" />
                            <div className="text-xs">{dateFormat(row.offlineVerificationTimeStamp)}</div>
                            <div className="text-s text-xs text-signal">{row.offlineVerificationAuthority}</div>
                        </> : <PendingOutlined className="text-amber-500" />
                        }
                    </div>
                )
            }
        },
        {
            id: 'promoted', label: 'Transfer to Current', type: 'iconButton',
            align: 'center', onClick: handlePromote,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {row?.promoted ? <>
                        <VerifiedOutlined className="text-green" />
                        <div className="text-xs">{dateFormat(row?.promotedTimeStamp)}</div>
                        <div className="text-s text-xs text-signal">{row?.promotedAuthority}</div>
                    </> : <PromoteIcon className="h-6 w-6 fill-purple-700" />}
                </div>
            )
        }
    ];

    const sortOptions = [
        { label: "Total", value: "none" },
        { label: "Verified", value: "Verified" },
        { label: "Pending", value: "Pending" },
        { label: "Rejected", value: "Rejected" },
        { label: "Declined", value: "Declined" }
    ]

    return (
        <div className="flex flex-col gap-8 bg-white p-5 min-h-svh">
            <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {tileData.map((tile, index) => (
                    <HomeTile key={index} icon={tile.icon} color={tile.color}
                        heading={tile.heading} count={tile.count} isLoading={statsLoading} />
                ))}
            </div>

            <CustomTable
                heading="All Recruits" text="Recruits data"
                isLoading={isLoading}
                rows={employeeData} columns={columns}
                sortParameters={['name', 'department', 'joiningDate', 'onlineVerificationStatus', 'offlineVerificationStatus']}
                const sortOptions={sortOptions}
                handleRowClick={handleRow}
                activeOperations extraOperations={customOperations}
            />

            {promotePopup?.isOpen && <PromotePopup id={promotePopup._id} onClose={() => setPromotePopup({ isOpen: false, _id: '' })} heading='Promote Recruit' subtext='Are you sure you want to promote the Recruit?' onConfirm={onPromote} />}
        </div>
    )
}

export default RecruitsVerification;