export function dateFormat(dateString, defaultValue='N/A') {
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };
    return dateString ? new Date(dateString).toLocaleString('en-US', options) : defaultValue;
}

export const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export function financialYear() {
    const financialYear = new Date().getMonth() >= 3 ? `${new Date().getFullYear()}-${String(new Date().getFullYear() + 1).slice(2)}` : `${new Date().getFullYear() - 2}-${String(new Date().getFullYear() - 1).slice(2)}`;
}