import { useState } from "react";
import { Dialog } from "@mui/material";
import { CustomTextField } from "../../mui/Input";
import { CloseButton, ConfirmButton } from "../../mui/button/Button";

const AddApplicantPopup = ({ heading, subtext, onConfirm, onClose }) => {
    const [emailId, setEmailId] = useState('');

    return (
        <Dialog open={true} onClose={onClose}>
            <div className="flex flex-col p-6 gap-8 w-full sm:w-fit bg-white rounded-lg">
                <div className="flex sm:w-[30rem] gap-5">
                    <img className="col-span-2 w-12 h-12" alt="" loading="eager" src='/icons/tabs/Edit.svg' />
                    <div >
                        <h2 className="h4-p">{heading}</h2>
                        <p className="text-s">{subtext}</p>
                    </div>
                </div>

                <div className="flex flex-col gap-5 w-full">
                    <CustomTextField fullWidth value={emailId} onChange={e => setEmailId(e.target.value)} placeholder='abc@example.com' label='Email ID' />
                    <div className="flex flex-col sm:flex-row items-center justify-between gap-5">
                        <CloseButton fullWidth size="large" text="Cancel" onClick={onClose} />
                        <ConfirmButton fullWidth size="large" text="Confirm" onClick={() => onConfirm(emailId)} />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AddApplicantPopup;